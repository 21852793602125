import React from "react";
import PropTypes from "prop-types";
import { Button, Box, Divider, Grid, Typography } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import {
  AttachFile as AttachFileIcon,
  LocationOn as LocationIcon,
  Event as EventIcon
} from "@material-ui/icons";
import styled from "styled-components";
import DOMPurify from "dompurify";
import moment from "moment";
import { communicationType } from "../../../types";
import Dialog from "../../../waybee-ui/Dialog";
import RoundedAvatar from "../../../waybee-ui/RoundedAvatar";
import WuiButton from "../../../waybee-ui/Button";
import CommunicationService from "../../../services/CommunicationService";

const HeaderTitle = styled.p`
  margin: 0;
  font-size: 24px;
  color: #00303b;
  font-weight: 500;
`;

const HeaderDate = styled.p`
  margin: 0;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.5);
`;

const CommunicationDialogHeader = ({ title, date, photo }) => {
  return (
    <Box p={3} display="flex" alignItems="stretch">
      <RoundedAvatar
        size={55}
        imageSrc={photo}
        imageAlt="Logo Colégio"
        icon="user"
      />
      <Box
        pl={2.5}
        display="inline-flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <HeaderTitle>{title}</HeaderTitle>
        <HeaderDate>{moment(date).format("DD/MM/YYYY HH:mm")}</HeaderDate>
      </Box>
    </Box>
  );
};

CommunicationDialogHeader.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  photo: PropTypes.string.isRequired
};

const AttachmentButton = styled(Button)`
  padding: 30px;
  text-transform: none;
`;

const AttachName = styled.span`
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CommunicationDialogAttachments = ({ attachments }) => {
  return (
    <>
      <Divider />
      <Grid container>
        {attachments.map(attachment => (
          <Grid key={attachment.id} item sm={3}>
            <AttachmentButton
              fullWidth
              color="primary"
              href={attachment.path}
              target="_blank"
            >
              <AttachFileIcon />
              <AttachName>{attachment.fileName}</AttachName>
            </AttachmentButton>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

CommunicationDialogAttachments.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.object).isRequired
};

const EventInfoText = styled(Typography)`
  font-size: 14px;
  display: flex;
  align-items: center;
  margin: 10px 0;
  p {
    margin: 0 10px;
  }
`;

const EventDate = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100%;
  border-right: 1px solid #ccc;
`;

const EventDay = styled(Typography)`
  font-size: 20px;
  font-weight: bold;
`;

const EventMonth = styled(Typography)`
  font-size: 18px;
`;

const EventInfo = ({ date, location }) => {
  return (
    <>
      <Divider />
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <EventDate>
            <EventDay color="primary">{moment(date).format("DD")}</EventDay>
            <EventMonth color="primary">
              {moment(date)
                .format("MMM")
                .toUpperCase()}
            </EventMonth>
          </EventDate>
        </Grid>
        <Grid item xs={10}>
          <EventInfoText color="primary">
            <LocationIcon />
            <p>{location}</p>
          </EventInfoText>
          <EventInfoText color="primary">
            <EventIcon />
            <p>{moment(date).format("HH:mm")}</p>
          </EventInfoText>
        </Grid>
      </Grid>
    </>
  );
};

EventInfo.propTypes = {
  date: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired
};

const EventButtonText = styled.p`
  margin-left: 10px;
`;

const EventButton = styled(Button)`
  &.default {
    color: rgba(0, 0, 0, 0.5);
  }
`;

const EventActions = ({ onClick, interest, presence }) => {
  return (
    <>
      <Divider />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <EventButton
            fullWidth
            onClick={() => onClick("interest")}
            color={interest ? "primary" : undefined}
            className={interest ? "HAHAHAHAHAHA" : "default"}
          >
            <Icon>{interest ? "star" : "star_outline"}</Icon>
            <EventButtonText>Tenho Interesse</EventButtonText>
          </EventButton>
        </Grid>
        <Grid item xs={6}>
          <EventButton
            fullWidth
            onClick={() => onClick("presence")}
            color={presence ? "primary" : undefined}
            className={presence ? "HAHAHAHAHAHA" : "default"}
          >
            <Icon>{presence ? "check_circle" : "check_circle_outline"}</Icon>
            <EventButtonText>Confirmar Presença</EventButtonText>
          </EventButton>
        </Grid>
      </Grid>
    </>
  );
};

EventActions.propTypes = {
  onClick: PropTypes.func.isRequired,
  interest: PropTypes.bool.isRequired,
  presence: PropTypes.bool.isRequired
};

const CommunicationCover = styled.img`
  max-height: 300px;
  width: 100%;
`;

const CommunicationDialog = ({
  open,
  onClose,
  communication,
  updateCommunication
}) => {
  const onClickLinkButton = async () => {
    if (communication.interaction && !communication.interaction.clickedAt) {
      await CommunicationService.updateInteractions(
        { hasClicked: true },
        communication.id
      );
    }
  };

  const onClickEventAction = async type => {
    if (!communication.interaction) return;
    let data = {};
    if (type === "interest") {
      const hadInterest = !communication.interaction.hadInterestAt;
      data = { hadInterest };
      // eslint-disable-next-line no-param-reassign
      communication.interaction.hadInterestAt = hadInterest
        ? new Date().toISOString
        : "";
    }
    if (type === "presence") {
      const confirmedPresence = !communication.interaction.confirmedPresenceAt;
      data = { confirmedPresence };
      // eslint-disable-next-line no-param-reassign
      communication.interaction.confirmedPresenceAt = confirmedPresence
        ? new Date().toISOString
        : "";
    }
    await CommunicationService.updateInteractions(data, communication.id);
    updateCommunication(communication);
  };

  return (
    <Dialog
      scroll="body"
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      closeButton
    >
      <CommunicationDialogHeader
        date={communication.date}
        photo={communication.photo}
        title={communication.title}
      />

      {communication.cover && <CommunicationCover src={communication.cover} />}

      <Box p={3}>
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(communication.bodyMessage)
          }}
        />
      </Box>
      {communication.button.link && communication.button.text && (
        <Box m={1} textAlign="center">
          <WuiButton
            onClick={onClickLinkButton}
            href={communication.button.link}
            color="primary"
            target="_blank"
            variant="contained"
          >
            {communication.button.text}
          </WuiButton>
        </Box>
      )}
      {communication.event.date && (
        <EventInfo
          date={communication.event.date}
          location={communication.event.location}
        />
      )}
      {communication.attachments && communication.attachments.length > 0 && (
        <CommunicationDialogAttachments
          attachments={communication.attachments}
        />
      )}

      {communication.event.date && (
        <EventActions
          onClick={onClickEventAction}
          presence={communication.interaction.confirmedPresenceAt}
          interest={communication.interaction.hadInterestAt}
        />
      )}
    </Dialog>
  );
};

CommunicationDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  communication: communicationType.isRequired,
  updateCommunication: PropTypes.func.isRequired
};

CommunicationDialog.defaultProps = {
  open: false,
  onClose: () => {}
};

export default CommunicationDialog;
