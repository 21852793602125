import React from "react";
import styled from "styled-components";
import { Grid } from "@material-ui/core";

const CellStyle = styled(Grid)`
  display: flex;
  align-items: center;
  padding: 7px 15px;
`;

const Cell = props => {
  return <CellStyle item {...props} />;
};

export default Cell;
