export const USER_LOGIN = "USER_LOGIN";
export const LOAD_COMMUNICATIONS = "LOAD_COMMUNICATIONS";
export const LOAD_COMMUNICATIONS_COUNT = "LOAD_COMMUNICATIONS_COUNT";
export const SET_COMMUNICATIONS_PAGE = "SET_COMMUNICATIONS_PAGE";
export const SET_COMMUNICATIONS_UNREAD = "SET_COMMUNICATIONS_UNREAD";
export const LOAD_NOTIFICATIONS = "LOAD_NOTIFICATIONS";
export const LOAD_NOTIFICATIONS_COUNT = "LOAD_NOTIFICATIONS_COUNT";
export const SET_NOTIFICATIONS_PAGE = "SET_NOTIFICATIONS_PAGE";
export const SET_NOTIFICATIONS_UNREAD = "SET_NOTIFICATIONS_UNREAD";
export const LOAD_THEME = "LOAD_THEME";
