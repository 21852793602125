import { api } from "../utils/api";

const getReportData = async (idTurmaDisc, period) => {
  const res = await api.get("ClassDiaryV1/individual-report", {
    params: { idTurmaDisc, period }
  });
  return res.data;
};

const getConcepts = async () => {
  const res = await api.get("ClassDiaryV1/individual-report/concepts");

  const concepts = res.data;
  concepts.push({ label: "Sem conceito", color: "#FFFFFF" });
  return concepts;
};

const getPeriods = async idTurmaDisc => {
  const res = await api.get("ClassDiaryV1/individual-report/periods", {
    params: { idTurmaDisc }
  });
  return res.data;
};

const save = async data => {
  const res = await api.post("ClassDiaryV1/individual-report", data);
  return res.data;
};

export default {
  getReportData,
  getConcepts,
  getPeriods,
  save
};
