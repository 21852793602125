import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Cell from "./Cell";

const TextCell = styled(Cell).withConfig({
  shouldForwardProp: prop => !["justifycontent"].includes(prop)
})`
  justify-content: ${({ justifycontent }) => justifycontent};
  color: #727272;
  padding: 6px 15px;
  font-size: 17px;
  svg {
    font-size: 30px;
    margin-right: 5px;
  }
`;

const Text = props => {
  const { children, icon: Icon, align } = props;
  const justify = (() => {
    switch (align) {
      case "left":
        return "flex-start";
      case "center":
        return "flex-center";
      case "right":
        return "flex-end";
      default:
        return "flex-start";
    }
  })();
  return (
    <TextCell {...props} justifycontent={justify}>
      {Icon && <Icon />}
      {children}
    </TextCell>
  );
};

Text.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.element, // Mateirla Ui icon
  align: PropTypes.oneOf(["left", "center", "right"])
};

Text.defaultProps = {
  icon: null,
  align: "left"
};

export default Text;
