import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import "moment/min/moment-with-locales";
import "moment/locale/pt-br";
import { Box, Container } from "@material-ui/core";
import {
  ErrorOutline as ErrorOutlineIcon,
  Description as DescriptionIcon,
  MenuBook as MenuBookIcon,
  DateRange as DateRangeIcon,
  TableChartOutlined as TableIcon
} from "@material-ui/icons";
import SquareButton from "../../../waybee-ui/SquareButton";

const GroupActions = props => {
  const redirectTo = route => {
    const { history } = props;
    const { pathname } = history.location;
    history.push(`${pathname}${route}`);
  };

  return (
    <Box mt={2}>
      <Container maxWidth="xg">
        <Box display="flex" justifyContent="center" flexWrap="wrap">
          <Box px={2} pt={2}>
            <SquareButton
              icon={ErrorOutlineIcon}
              color="primary"
              onClick={() => redirectTo("/occurrences")}
            >
              Ocorrências e faltas
            </SquareButton>
          </Box>
          {/* <Box px={2} pt={2}> */}
          {/*  <SquareButton */}
          {/*    icon={DescriptionIcon} */}
          {/*    color="primary" */}
          {/*    onClick={() => redirectTo("/grades")} */}
          {/*  > */}
          {/*    Notas */}
          {/*  </SquareButton> */}
          {/* </Box> */}

          <Box px={2} pt={2}>
            <SquareButton
              icon={DescriptionIcon}
              color="primary"
              onClick={() => redirectTo("/gradesV2")}
            >
              Notas
            </SquareButton>
          </Box>

          <Box px={2} pt={2}>
            <SquareButton
              icon={TableIcon}
              color="primary"
              onClick={() => redirectTo("/individualReport")}
            >
              Relatório Individual
            </SquareButton>
          </Box>

          <Box px={2} pt={2}>
            <SquareButton
              icon={MenuBookIcon}
              color="primary"
              onClick={() => redirectTo("/notes")}
            >
              Anotações
            </SquareButton>
          </Box>
          <Box px={2} pt={2}>
            <SquareButton
              icon={DateRangeIcon}
              color="primary"
              onClick={() => redirectTo("/schedule")}
            >
              Horários da turma
            </SquareButton>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

GroupActions.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired // Injected by withRouter
};

export default withRouter(GroupActions);
