import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  ExpansionPanel as MuiExpansionPanel,
  ExpansionPanelSummary as MuiExpansionPanelSummary
} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import Row from "./Row";

const ExpansionPanel = styled(MuiExpansionPanel)`
  box-shadow: none;

  &.Mui-expanded {
    margin: 0;
    border-bottom: solid 2px #f2f2f2;
  }

  &::before {
    height: 0 !important;
  }
`;

const ExpansionPanelSummary = styled(MuiExpansionPanelSummary)`
  padding: 0;
  .MuiExpansionPanelSummary-content {
    margin: 0;
  }
`;

class ExpansionRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false
    };
  }

  handleToggle() {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  }

  render() {
    const { id, photo, onClick, margin, children } = this.props;
    const { expanded } = this.state;

    let Icon = <KeyboardArrowDown />;
    if (expanded) {
      Icon = <KeyboardArrowUp />;
    }

    const expansionRowDetails = children.find(
      e => e.type.name === "ExpansionRowDetails"
    );
    const expansionRowActions = children.find(
      e => e.type.name === "ExpansionRowActions"
    );
    const tableGridChildrens = children.filter(
      e =>
        e.type.name !== "ExpansionRowDetails" &&
        e.type.name !== "ExpansionRowActions"
    );

    return (
      <ExpansionPanel square expanded={expanded}>
        <ExpansionPanelSummary onClick={() => this.handleToggle()}>
          <Row
            actionIcon={Icon}
            id={id}
            photo={photo}
            button
            onClick={onClick}
            margin={margin}
          >
            {tableGridChildrens}
          </Row>
        </ExpansionPanelSummary>
        {expansionRowDetails}
        {expansionRowActions}
      </ExpansionPanel>
    );
  }
}

ExpansionRow.propTypes = {
  id: PropTypes.number,
  photo: PropTypes.string,
  onClick: PropTypes.func,
  margin: PropTypes.oneOfType([
    PropTypes.oneOf(["none", "dense", "normal"]),
    PropTypes.bool
  ]),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired
};

ExpansionRow.defaultProps = {
  id: null,
  photo: "",
  onClick: () => {},
  margin: false
};

export default ExpansionRow;
