import React from "react";
import { Box, Typography } from "@material-ui/core";

const MoreInfo = () => {
  return (
    <Box my={2} mx={3}>
      <Typography variant="body1">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum
        ullamcorper aliquet urna. Donec vitae dolor nulla. Proin sit amet
        porttitor felis. Praesent suscipit posuere nunc eget laoreet. Aenean
        eget vehicula nulla. Pellentesque sodales non magna quis finibus. Sed
        posuere risus at leo porta, sit amet ornare odio iaculis.
      </Typography>
    </Box>
  );
};

export default MoreInfo;
