import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core/";
import MuiIcon from "@material-ui/core/Icon";
import styled from "styled-components";

const Icon = styled(MuiIcon)`
  min-width: 29px;
  font-size: 1.2em;
  margin: -0.2em 21px -0.2em 0;
`;

const getTypeByLevel = level => {
  switch (level) {
    case 1:
      return "h1";
    case 2:
      return "h2";
    case 3:
      return "h3";
    case 4:
      return "h4";
    case 5:
      return "h5";
    case 6:
    default:
      return "h6";
  }
};

const Heading = ({ children, level, icon, gutterBottom }) => {
  return (
    <Typography variant={getTypeByLevel(level)} gutterBottom={gutterBottom}>
      {icon && <Icon>{icon}</Icon>}
      {children}
    </Typography>
  );
};

Heading.propTypes = {
  level: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  icon: PropTypes.string,
  gutterBottom: PropTypes.bool
};

Heading.defaultProps = {
  icon: null,
  gutterBottom: false,
  level: 6
};

export default Heading;
