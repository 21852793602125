import { LOAD_THEME } from "../actions/actionTypes";
import { updateTheme, defaultTheme } from "../../assets/theme/StyledTheme";

const INITIAL_STATE = defaultTheme;

export const themeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_THEME: {
      const theme = {
        ...state,
        ...action.payload,
        color: {
          ...state.color,
          ...action.payload.color
        },
        images: {
          ...state.images,
          ...action.payload.images
        }
      };
      console.log("redux", theme);
      updateTheme(theme);
      return theme;
    }
    default:
      return state;
  }
};
