export default [
  { path: "/", title: "Home" },
  { path: "/login", title: "" },
  { path: "/communication/:id", title: "Comunicações" },
  { path: "/communication", title: "Comunicações" },
  { path: "/notifications", title: "Notificações" },
  { path: "/classes", title: "Turmas" },
  { path: "/classes/:id", title: "Turma" },
  { path: "/classes/:id/occurrences", title: "Turma > Ocorrências" },
  { path: "/classes/:id/grades", title: "Turma > Notas" },
  { path: "/classes/:id/gradesV2", title: "Turma > Notas V2" },
  {
    path: "/classes/:id/individualReport",
    title: "Turma > Relatório Individual"
  },
  { path: "/classes/:id/schedule", title: "Turma > Horários" },
  { path: "/classes/:id/notes", title: "Turma > Anotações" },
  { path: "/schedule", title: "Horários" },
  { path: "/teachers", title: "Professores" },
  { path: "/teachers/:id", title: "Professores > Mais Informaçeõs" },
  { path: "/support", title: "Suporte" },
  {
    path: "/student/:studentId",
    title: "Perfil do Aluno"
  },
  {
    path: "/student/:studentId/notes",
    title: "Perfil do Aluno > Observações> "
  },
  {
    path: "/student/:studentId/statistics",
    title: "Perfil do Aluno > Desempenho"
  },
  {
    path: "/student/:studentId/schedule",
    title: "Perfil do Aluno > Horários"
  },
  {
    path: "/student/:studentId/occurrences",
    title: "Perfil do Aluno > Ocorrências"
  },
  {
    path: "/student/:studentId/contact",
    title: "Perfil do Aluno > Contato"
  }
];
