import React from "react";
import PropTypes from "prop-types";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  Typography
} from "@material-ui/core";
import Dialog from "../../../waybee-ui/Dialog/Dialog";
import Heading from "../../../waybee-ui/Heading/Heading";
import Button from "../../../waybee-ui/Button";

const OccurrenceDialog = ({ open, handleClose, occurrence }) => {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth closeButton>
      <DialogTitle>Ocorrência</DialogTitle>
      <DialogContent>
        <Heading>{`Ocorrência do dia ${occurrence.date}`}</Heading>
        <Grid container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Tipo"
                value={occurrence.reason}
                fullWidth
                disabled
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Professor"
                value={occurrence.teacher}
                fullWidth
                disabled
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Disciplina"
                value={occurrence.courseName}
                fullWidth
                disabled
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1" color="textSecondary" gutterBottom>
                Observações:
              </Typography>
              <Typography variant="body1">{occurrence.description}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleClose}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

OccurrenceDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  occurrence: PropTypes.shape({
    courseName: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    reason: PropTypes.string.isRequired,
    teacher: PropTypes.string.isRequired
  }).isRequired,
  open: PropTypes.bool.isRequired
};

export default OccurrenceDialog;
