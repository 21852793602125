import PropTypes from "prop-types";
import React, { Component } from "react";
import { Grid, Typography, Box, CircularProgress } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import HeaderInfo from "../../waybee-ui/HeaderInfo/HeaderInfo";
import Group from "../../waybee-ui/Group";
import Divider from "../../waybee-ui/Divider";
import BackButton from "../../components/BackButton";
import StudentService from "../../services/StudentService";

const LoadContainer = styled.div`
  text-align: center;
  margin: 8px;
`;

class StudentContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      student: null,
      loading: false
    };
  }

  componentDidMount() {
    this.getContactInfo();
  }

  getContactInfo = async () => {
    const { match } = this.props;
    const { studentId } = match.params;

    this.setState({ loading: true });

    try {
      const student = await StudentService.getStudentById(studentId);
      this.setState({ student });
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { student, loading } = this.state;
    const { match } = this.props;
    const { studentId } = match.params;

    if (!student) return null;

    return (
      <Grid container spacing={2}>
        <BackButton to={`/student/${studentId}`} />
        <Grid item xs={12}>
          <Group>
            {loading ? (
              <LoadContainer>
                <CircularProgress />
              </LoadContainer>
            ) : (
              <>
                <Box mb={2}>
                  <HeaderInfo
                    name={student.name}
                    info={`R.A: ${student.ra}`}
                    photo={student.photo}
                  />
                </Box>

                {student.contacts.map(contact => (
                  <>
                    <Divider />
                    <Box my={3} mx={2}>
                      <Box pb={1}>
                        <Typography variant="h3" gutterBottom>
                          {contact.name}
                        </Typography>
                      </Box>
                      {contact.tel1 && (
                        <Typography variant="h4" gutterBottom>
                          Telefone 1: 
                          {' '}
                          {contact.tel1}
                        </Typography>
                      )}
                      {contact.tel2 && (
                        <Typography variant="h4" gutterBottom>
                          Telefone 2: 
                          {' '}
                          {contact.tel2}
                        </Typography>
                      )}
                      {contact.email && (
                        <Typography variant="h4">
                          E-mail: 
                          {' '}
                          {contact.email}
                        </Typography>
                      )}
                    </Box>
                  </>
                ))}
              </>
            )}
          </Group>
        </Grid>
      </Grid>
    );
  }
}

StudentContact.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.array
  }).isRequired
};
export default withRouter(StudentContact);
