import PropTypes from "prop-types";
import React, { Component } from "react";
import { Grid, Box, TextField, CircularProgress } from "@material-ui/core";
import { DebounceInput } from "react-debounce-input";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import _ from "lodash";
import { withAlert } from "react-alert";
import ActionButtons from "./components/ActionButtons";
import Divider from "../../waybee-ui/Divider";
import Heading from "../../waybee-ui/Heading/Heading";
import Group from "../../waybee-ui/Group";
import BackButton from "../../components/BackButton";
import TableGrid from "../../waybee-ui/TableGrid";
import TeacherService from "../../services/TeacherService";

const LoadContainer = styled.div`
  text-align: center;
  margin: 8px;
`;

class ClassDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        name: ""
      },
      students: [],
      loading: false,
      filteredStudents: []
    };
  }

  componentDidMount() {
    this.getStudents();
  }

  getStudents = async () => {
    this.setState({ loading: true });
    try {
      const { match } = this.props;
      const students = await TeacherService.getStudentsForClass(
        match.params.id
      );
      this.setState({ students, filteredStudents: students });
    } catch (e) {
      const { alert } = this.props;
      alert.show(`Erro ao buscar os alunos`, { title: "Erro" });
    } finally {
      this.setState({ loading: false });
    }
  };

  setFilter(field, value) {
    const { filter, students } = this.state;
    this.setState({
      filter: {
        ...filter,
        [field]: value
      }
    });
    this.setState({ loading: true });
    const filteredStudents = _.filter(students, student =>
      _.includes(student.name.toLowerCase(), value.toLowerCase())
    );
    this.setState({ filteredStudents, loading: false });
  }

  redirectTo(route) {
    const { history } = this.props;
    history.push(route);
  }

  render() {
    const { filter, filteredStudents, loading } = this.state;
    return (
      <div>
        <Grid container spacing={2}>
          <BackButton to="/classes" />
          <Grid item xs={12}>
            <Group>
              <Heading level={1} icon="play_arrow" gutterBottom>
                Ações na turma
              </Heading>
              <Divider />
              <ActionButtons classId="" />
            </Group>
          </Grid>

          <Grid item xs={12}>
            <Group>
              <Box>
                <Heading level={1} icon="group_work" gutterBottom>
                  Alunos da Turma
                </Heading>
                <Box pb={4} pt={2}>
                  <form noValidate autoComplete="off">
                    <Grid container spacing={8}>
                      <Grid item md={12}>
                        <DebounceInput
                          minLength={3}
                          debounceTimeout={400}
                          element={TextField}
                          value={filter.name}
                          onChange={e => this.setFilter("name", e.target.value)}
                          label="Buscar por Nome"
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </form>
                </Box>
              </Box>
              <Box>
                {loading ? (
                  <LoadContainer>
                    <CircularProgress />
                  </LoadContainer>
                ) : (
                  <TableGrid>
                    {filteredStudents.map(student => (
                      <TableGrid.Row
                        key={student.ra}
                        id={student.id}
                        photo={student.photo}
                        actionIcon
                        button
                        icon="person"
                        onClick={() =>
                          this.redirectTo(`/student/${student.ra}`)}
                      >
                        <TableGrid.Title xs={7}>{student.name}</TableGrid.Title>
                        <TableGrid.Text xs={5}>
                          {`R.A.: ${student.ra}`}
                        </TableGrid.Text>
                      </TableGrid.Row>
                    ))}
                  </TableGrid>
                )}
              </Box>
            </Group>
          </Grid>
        </Grid>
      </div>
    );
  }
}

ClassDetails.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.any,
    push: PropTypes.func
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.any
    })
  }).isRequired,
  alert: PropTypes.shape({
    show: PropTypes.func
  }).isRequired
};

export default withAlert()(withRouter(ClassDetails));
