import React from "react";
import styled from "styled-components";
import WuiButton from "../../Button";
import Cell from "./Cell";

const ButtonCell = styled(Cell)`
  padding: 0 15px;
`;

const Button = props => {
  return (
    <ButtonCell>
      <WuiButton {...props} />
    </ButtonCell>
  );
};

export default Button;
