import { api } from "../utils/api";

const getNotifications = async page => {
  const res = await api.get(`Notifications`, {
    params: { page }
  });
  const { data } = res;

  const notifications = data.map(notification => ({
    id: notification.id,
    type: notification.model,
    modelId: notification.modelId,
    title: notification.message,
    createdAt: notification.created_at,
    opened: notification.subscriber.opened,
    subscriber: notification.subscriber
  }));
  return notifications;
};

const getNotificationCount = async () => {
  return api.get(`Notifications/count`);
};

const markAsRead = async id => {
  return api.put(`Notifications/${id}/read`);
};

export default {
  getNotifications,
  getNotificationCount,
  markAsRead
};
