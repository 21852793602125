import moment from "moment";
import userStore from "../utils/UserStore";
import { api } from "../utils/api";

// const getSupports = (skip = 0, searchText = "", initialDate, endDate) => {
// const getSupports = () => {
//   return new Promise(resolve => {
//     setTimeout(() => resolve(mocks.supports), 1500);
//   });
// };

const getSupports = (skip = 0, searchText = "", initialDate, endDate) => {
  const campusId = userStore.getCurrentCampus();
  const filter = {
    where: {
      and: [
        { campusId },
        {
          or: [
            { description: { ilike: `%${searchText}%` } },
            !searchText ? { description: null } : {}
          ]
        },
        {
          createdAt: {
            gt: moment(initialDate).isValid()
              ? new Date(initialDate.setHours(0, 0, 0))
              : new Date("1900-01-01")
          }
        },
        {
          createdAt: {
            lt: moment(endDate).isValid()
              ? new Date(endDate.setHours(23, 59, 59))
              : new Date()
          }
        }
      ]
    },
    include: [
      {
        relation: "SupportReason",
        scope: {
          include: {
            relation: "SupportType",
            scope: {
              fields: ["id", "name"]
            }
          }
        }
      },
      {
        relation: "UserCreatedBy",
        scope: {
          fields: ["name"]
        }
      },
      {
        relation: "UpdatedByUser",
        scope: {
          fields: ["name"]
        }
      }
    ],
    limit: 10,
    order: "createdAt DESC",
    skip,
    scope: {
      skip
    }
  };

  return api
    .get("Supports", { params: { filter } })
    .then(response => response.data);
};

const getSupportReasons = () => {
  const campusId = userStore.getCurrentCampus();
  return api
    .get("Supports/SupportReasons", { params: { campusId } })
    .then(response => response.data);
};

const getSupportsCount = () => {
  const campusId = userStore.getCurrentCampus();
  const where = { campusId };
  return api
    .get("Supports/count", { params: { where } })
    .then(response => response.data.count);
};

const createSupport = data => {
  const campusId = userStore.getCurrentCampus();
  const supportData = {};
  supportData.campusId = campusId;
  supportData.supportReasonId = data.reasonId;
  supportData.location = data.location;
  supportData.description = data.description;
  return api.post("Supports", supportData).catch(e => {
    if (e.response.data && e.response.data.error) {
      const errorData = e.response.data.error;
      throw Error(errorData.message);
    }
    throw Error("Houve um erro ao enviar suporte");
  });
};

const cancelSupport = supportId => {
  const canceledStatusId = 5;
  return api.put(`Supports/${supportId}/status`, canceledStatusId).catch(e => {
    if (e.response.data && e.response.data.error) {
      const errorData = e.response.data.error;
      throw Error(errorData.message);
    }
    throw Error("Houve um erro ao cancelar suporte");
  });
};

const changeSupportStatus = async (supportId, statusId) => {
  return api.put(`Supports/${supportId}/status`, statusId);
};

export default {
  getSupports,
  getSupportsCount,
  getSupportReasons,
  cancelSupport,
  createSupport,
  changeSupportStatus
};
