import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";
import Button from "../waybee-ui/Button";

const BackButton = ({ to, onClick }) => (
  <Grid item sm={12}>
    <Button
      color="default"
      startIcon={<ArrowBackIcon />}
      size="large"
      component={Link}
      to={to}
      onClick={onClick}
    >
      Voltar
    </Button>
  </Grid>
);

BackButton.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func
};

BackButton.defaultProps = {
  to: "",
  onClick: () => {}
};

export default BackButton;
