import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Collapse as MuiCollapse,
  Icon,
  withTheme
} from "@material-ui/core";
import styled from "styled-components";
import WuiGroup from "../Group";

const Group = styled(WuiGroup)`
  padding: 20px;
  cursor: pointer;
`;

const Collapse = styled(MuiCollapse)`
  background-color: white;
  border-bottom: solid 2px #f2f2f2;
`;

const GroupTitle = styled.p`
  font-family: Roboto;
  font-size: 19px;
  margin: 0;
  color: #7f7f7f;
  line-height: normal;
`;

const CollapseSubGroup = ({ groupProps, children, title, className }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className={className}>
      <Group {...groupProps} onClick={() => setOpen(!open)}>
        <Box display="flex" justifyContent="space-between">
          <GroupTitle>{title}</GroupTitle>
          <Icon>{open ? "expand_less" : "expand_more"}</Icon>
        </Box>
      </Group>
      <Collapse in={open}>
        <Box backgroundColor="white">{children}</Box>
      </Collapse>
    </div>
  );
};

CollapseSubGroup.defaultProps = {
  groupProps: undefined,
  className: ""
};

CollapseSubGroup.propTypes = {
  groupProps: PropTypes.shape({
    ...Group.propTypes
  }),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object.isRequired // Injected by withTheme()
};

export default withTheme(CollapseSubGroup);
