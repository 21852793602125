import PropTypes from "prop-types";
import React, { Component } from "react";
import { ExpansionPanelActions } from "@material-ui/core";

// Usando um state Component pois foi preciso obter o nome desse componente no ExpansionRow
// eslint-disable-next-line react/prefer-stateless-function
class ExpansionRowActions extends Component {
  render() {
    const { children } = this.props;
    return <ExpansionPanelActions>{children}</ExpansionPanelActions>;
  }
}

ExpansionRowActions.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired
};

export default ExpansionRowActions;
