import React from "react";
import PropTypes from "prop-types";
import { DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import Dialog from "../Dialog/Dialog";
import Heading from "../Heading/Heading";
import Button from "../Button";

const Alert = ({ options, message, close }) => {
  return (
    <Dialog open onClose={close} fullWidth>
      <DialogTitle>{options.title}</DialogTitle>
      <DialogContent>
        <Heading>{message}</Heading>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={close}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

Alert.propTypes = {
  close: PropTypes.func.isRequired,
  message: PropTypes.string,
  options: PropTypes.shape({
    title: PropTypes.string
  })
};

Alert.defaultProps = {
  message: "",
  options: {}
};

export default Alert;
