// import PropTypes from "prop-types";
import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import Calendar from "../../waybee-ui/Calendar/Calendar";
// eslint-disable-next-line import/no-named-as-default
import TeacherService from "../../services/TeacherService";
import WuiClassesList from "../Classes/components/ClassesList";
// import WuiCommunicationList from "../CommunicationDashboard/components/CommunicationList";
import Widget from "../../waybee-ui/Widget";
import Banner from "./components/Banner";
import UserStore from "../../utils/UserStore";

const ClassesList = styled(WuiClassesList)`
  margin: 0;
  width: 100%;
`;
//
// const CommunicationList = styled(WuiCommunicationList)`
//   margin: 0;
//   width: 100%;
// `;

const Container = styled.div`
  max-height: 950px;
`;

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      calendarOptions: null
    };
  }

  componentDidMount() {
    this.setOptions();
  }

  setOptions() {
    const options = {
      views: {
        timeGridDay: {
          slotLabelFormat: { hour: "numeric", minute: "2-digit" },
          columnHeaderFormat: {
            weekday: "short",
            day: "numeric",
            month: "long"
          }
        }
      },
      allDaySlot: false,
      events: async (info, successCallback, failureCallback) => {
        try {
          const mock = await TeacherService.getSchedule(
            info.startStr,
            info.endStr
          );
          successCallback(mock);
        } catch (e) {
          failureCallback(e);
        }
      },
      defaultView: "timeGridDay",
      height: 830
    };

    this.setState({ calendarOptions: options });
  }

  render() {
    // const { history } = this.props;
    const { calendarOptions } = this.state;

    const userName = (UserStore.getUserName() || "").split(" ")[0];

    return (
      <Grid container spacing={2} component={Container}>
        <Grid item sm={3}>
          <div>
            {calendarOptions && <Calendar options={calendarOptions} today />}
          </div>
        </Grid>
        <Grid item container sm={9} spacing={2}>
          <Grid item sm={12}>
            <Banner userName={userName} />
          </Grid>
          <Grid item sm={6}>
            <Widget title="Próximas Turmas">
              <ClassesList size="compact" />
            </Widget>
          </Grid>
          {/* <Grid item sm={6}> */}
          {/*  <Widget title="Comunicações Recentes"> */}
          {/*    <CommunicationList */}
          {/*      size="compact" */}
          {/*      onClick={communication => { */}
          {/*        history.push(`./communication/${communication.id}`); */}
          {/*      }} */}
          {/*    /> */}
          {/*  </Widget> */}
          {/* </Grid> */}
        </Grid>
      </Grid>
    );
  }
}

Home.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  // history: PropTypes.object.isRequired // Injected by withRouter
};

export default withRouter(Home);
