import PropTypes from "prop-types";
import React, { Component } from "react";
import { withAlert } from "react-alert";
import { Grid, Box, TextField, CircularProgress } from "@material-ui/core";
import { AddCircleOutline as AddCircleOutlineIcon } from "@material-ui/icons";
import { DebounceInput } from "react-debounce-input";
import { withRouter } from "react-router-dom";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ptBrLocale from "date-fns/locale/pt-BR";
import moment from "moment";
import TableGrid from "../../waybee-ui/TableGrid";
import Group from "../../waybee-ui/Group";
import Heading from "../../waybee-ui/Heading";
import BackButton from "../../components/BackButton";
import BlockButton from "../../waybee-ui/BlockButton";
import ConfirmAlert from "../../waybee-ui/ConfirmAlert";
import Button from "../../waybee-ui/Button";
import NoteDialog from "./components/NoteDialog";

import NoteService from "../../services/NoteService";

class ClassNotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notes: [],
      filter: {
        name: "",
        date: null
      },
      openNoteDialog: false,
      openConfirmDelete: false,
      selectedNote: null
    };
  }

  async componentDidMount() {
    const notes = await NoteService.getNotes();
    this.setState({ notes });
  }

  getNotes = async () => {
    return NoteService.getNotes();
  };

  setFilter(field, value) {
    const { filter } = this.state;
    this.setState({
      filter: {
        ...filter,
        [field]: value
      }
    });
  }

  handleOpenConfirmDelete(note) {
    this.setState({ openConfirmDelete: true, selectedNote: note });
  }

  handleCreate(note) {
    const { alert } = this.props;

    try {
      alert.show("Anotação realizada com sucesso!", {
        title: "Nova Anotação"
      });
      NoteService.createNote(note);
    } catch (e) {
      alert.show("Ocorreu um erro durante a realização da anotação.", {
        title: "Erro ao Nova Anotação"
      });
    }
  }

  handleUpdate(note) {
    const { alert } = this.props;

    try {
      alert.show("Anotação editada com sucesso!", {
        title: "Editar Anotação"
      });
      NoteService.updateNote(note);
    } catch (e) {
      alert.show("Ocorreu um erro durante a edição da anotação.", {
        title: "Erro ao Editar Anotação"
      });
    }
  }

  handleDelete() {
    const { alert } = this.props;
    const { selectedNote } = this.state;

    try {
      alert.show("Anotação excluída com sucesso!", {
        title: "Excluir Anotação"
      });
      NoteService.deleteNote(selectedNote);
    } catch (e) {
      alert.show("Ocorreu um erro durante a exclusão da anotação.", {
        title: "Erro ao Excluir Anotação"
      });
    }
    this.handleCloseConfirmDelete();
  }

  handleCloseNoteDialog() {
    this.setState({ openNoteDialog: false, selectedNote: null });
  }

  handleOpenNoteDialog(selectedNote = null) {
    this.setState({ openNoteDialog: true, selectedNote });
  }

  handleCloseConfirmDelete() {
    this.setState({ openConfirmDelete: false, selectedNote: null });
  }

  render() {
    const {
      filter,
      notes,
      openNoteDialog,
      selectedNote,
      openConfirmDelete
    } = this.state;
    const { history } = this.props;

    if (!notes) {
      return (
        <Box p={6} textAlign="center">
          <CircularProgress />
        </Box>
      );
    }

    return (
      <Grid container spacing={2}>
        <BackButton onClick={history.goBack} />
        <Grid item xs={12}>
          <Group p={0}>
            <BlockButton
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => this.handleOpenNoteDialog()}
            >
              Adicionar Anotação à Aula
            </BlockButton>
          </Group>
        </Grid>
        <Grid item xs={12}>
          <Group>
            <Box>
              <Heading level={1} icon="history" gutterBottom>
                {`Histórico de Anotações (${notes.length})`}
              </Heading>
              <Box mt={1} mb={2}>
                <form noValidate autoComplete="off">
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={ptBrLocale}
                  >
                    <Grid container spacing={8}>
                      <Grid item xs={6}>
                        <DebounceInput
                          minLength={3}
                          debounceTimeout={400}
                          element={TextField}
                          value={filter.name}
                          onChange={e => this.setFilter("name", e.target.value)}
                          label="Buscar Aluno"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="dd/MM/yyyy"
                          id="date-picker-inline"
                          label="Data"
                          value={filter.date}
                          onChange={date => {
                            this.setFilter("date", date);
                          }}
                          fullWidth
                          invalidDateMessage="Data inválida"
                        />
                      </Grid>
                    </Grid>
                  </MuiPickersUtilsProvider>
                </form>
              </Box>
              <Box>
                <TableGrid>
                  {notes.map(note => (
                    <TableGrid.ExpansionRow margin key={note.id}>
                      <TableGrid.Title xs={5}>{note.title}</TableGrid.Title>
                      <TableGrid.Text xs={4}>
                        {`${moment(note.date).format("DD/MM/YYYY")} às ${moment(
                          note.date
                        ).format("hh:mm")}`}
                      </TableGrid.Text>
                      <TableGrid.Text xs={3}>{note.course.name}</TableGrid.Text>
                      <TableGrid.ExpansionRowDetails>
                        {note.description}
                      </TableGrid.ExpansionRowDetails>
                      <TableGrid.ExpansionRowActions>
                        <Button
                          onClick={() => this.handleOpenConfirmDelete(note)}
                          size="small"
                          color="error"
                        >
                          Excluir Anotação
                        </Button>
                        <Button
                          onClick={() => this.handleOpenNoteDialog(note)}
                          size="small"
                          color="secondary"
                        >
                          Editar Anotação
                        </Button>
                      </TableGrid.ExpansionRowActions>
                    </TableGrid.ExpansionRow>
                  ))}
                </TableGrid>
              </Box>
            </Box>
          </Group>
        </Grid>
        <NoteDialog
          open={openNoteDialog}
          handleClose={() => this.handleCloseNoteDialog()}
          selectedNote={selectedNote}
          handleCreate={note => this.handleCreate(note)}
          handleUpdate={note => this.handleUpdate(note)}
        />
        <ConfirmAlert
          open={openConfirmDelete}
          confirmButtonText="Excluir"
          title="Excluir anotação"
          text="Deseja excluir a anotação?"
          onConfirm={() => this.handleDelete()}
          onClose={() => this.handleCloseConfirmDelete()}
        />
      </Grid>
    );
  }
}

ClassNotes.propTypes = {
  alert: PropTypes.shape({
    show: PropTypes.func
  }).isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.any
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.any
    })
  }).isRequired
};

export default withAlert()(withRouter(ClassNotes));
