import { api } from "../utils/api";

const getTheme = async () => {
  const res = await api.get("Settings/theme");
  return res.data;
};

export default {
  getTheme
};
