import {
  LOAD_COMMUNICATIONS,
  LOAD_COMMUNICATIONS_COUNT,
  SET_COMMUNICATIONS_PAGE,
  SET_COMMUNICATIONS_UNREAD,
  LOAD_NOTIFICATIONS,
  LOAD_NOTIFICATIONS_COUNT,
  SET_NOTIFICATIONS_PAGE,
  SET_NOTIFICATIONS_UNREAD,
  LOAD_THEME
} from "./actionTypes";

const createAction = type => {
  return value => ({
    type,
    payload: value
  });
};

export const loadCommunications = createAction(LOAD_COMMUNICATIONS);
export const loadCommunicationsCount = createAction(LOAD_COMMUNICATIONS_COUNT);
export const setCommunicationsPage = createAction(SET_COMMUNICATIONS_PAGE);
export const setCommunicationsUnread = createAction(SET_COMMUNICATIONS_UNREAD);
export const loadNotifications = createAction(LOAD_NOTIFICATIONS);
export const loadNotificationsCount = createAction(LOAD_NOTIFICATIONS_COUNT);
export const setNotificationsPage = createAction(SET_NOTIFICATIONS_PAGE);
export const setNotificationsUnread = createAction(SET_NOTIFICATIONS_UNREAD);
export const loadTheme = createAction(LOAD_THEME);
