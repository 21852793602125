import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { FormControlLabel, Checkbox as MuiCheckBox } from "@material-ui/core";
import Cell from "./Cell";

const CheckboxCell = styled(Cell)`
  padding: 0 15px;
  span.MuiFormControlLabel-label {
    margin-left: 10px;
    margin-right: 10px;
    font-size: 18px;
  }
`;

const Checkbox = props => {
  const { label } = props;
  return (
    <CheckboxCell {...props}>
      <FormControlLabel control={<MuiCheckBox {...props} />} label={label} />
    </CheckboxCell>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string
};

Checkbox.defaultProps = {
  label: ""
};

export default Checkbox;
