import PropTypes from "prop-types";
import React, { Component } from "react";
import { Grid, Box, CircularProgress } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import HeaderInfo from "../../waybee-ui/HeaderInfo/HeaderInfo";
import Group from "../../waybee-ui/Group";
import BackButton from "../../components/BackButton";
import TableGrid from "../../waybee-ui/TableGrid";
import OccurrenceDialog from "./components/OccurrenceDialog";

import StudentService from "../../services/StudentService";

class StudentOccurrences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
      student: null,
      selectedOccurrence: null
    };
  }

  async componentDidMount() {
    this.getStudent();
    this.getOccurrences();
  }

  getStudent = async () => {
    const {
      match: {
        params: { studentId }
      }
    } = this.props;
    const student = await StudentService.getStudentById(studentId);
    this.setState({ student });
  };

  getOccurrences = async () => {
    const {
      match: {
        params: { studentId }
      }
    } = this.props;
    const occurrences = await StudentService.getOccurrences(studentId);
    this.setState({ occurrences });
  };

  handleDialogClose() {
    this.setState({ dialogOpen: false, selectedOccurrence: null });
  }

  handleSelectOccurrence(occurrence) {
    this.setState({ dialogOpen: true, selectedOccurrence: occurrence });
  }

  render() {
    const { dialogOpen, selectedOccurrence, student, occurrences } = this.state;

    if (!student || !occurrences) {
      return (
        <Box p={6} textAlign="center">
          <CircularProgress />
        </Box>
      );
    }

    return (
      <Grid container spacing={2}>
        <BackButton to={`/student/${student.id}`} />
        <Grid item xs={12}>
          <Group>
            <Box mb={2}>
              <HeaderInfo
                name={student.name}
                info={`R.A: ${student.ra}`}
                photo={student.photo}
              />
            </Box>
            <Box>
              <TableGrid>
                {occurrences.map(occur => (
                  <TableGrid.Row
                    actionIcon
                    button
                    onClick={() => this.handleSelectOccurrence(occur)}
                  >
                    <TableGrid.Title xs={5}>{occur.reason}</TableGrid.Title>
                    <TableGrid.Text xs={7}>{occur.date}</TableGrid.Text>
                  </TableGrid.Row>
                ))}
              </TableGrid>
            </Box>
          </Group>
        </Grid>
        {selectedOccurrence && (
          <OccurrenceDialog
            open={dialogOpen}
            handleClose={() => this.handleDialogClose()}
            occurrence={selectedOccurrence}
          />
        )}
      </Grid>
    );
  }
}

StudentOccurrences.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.array
  }).isRequired
};
export default withRouter(StudentOccurrences);
