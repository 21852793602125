import React, { Component } from "react";
// services
import { Box, CircularProgress, Grid } from "@material-ui/core";
import { AddCircleOutline } from "@material-ui/icons";
import SupportService from "../../services/SupportService";
import Group from "../../waybee-ui/Group";
import BlockButton from "../../waybee-ui/BlockButton";
import Heading from "../../waybee-ui/Heading/Heading";

import SupportList from "./components/SupportList";
import ModalSupport from "./components/modalSupport";

class Support extends Component {
  constructor() {
    super();
    this.state = {
      supports: [],
      filteredSupports: [],
      loading: false,
      hasAllSupports: false,
      supportCount: 0,
      filters: {
        searchText: "",
        initialDate: null,
        finalDate: null
      },
      supportSelected: undefined,
      modalOpen: false
    };
  }

  componentDidMount() {
    const { supports } = this.state;

    if (!supports.length) {
      this.getSupports().then(newSupports =>
        this.setState({ supports: newSupports })
      );
    }
    this.setSupportCount();
    window.onscroll = () => {
      const container = document.querySelector("html");
      if (container.scrollTop + window.innerHeight >= container.offsetHeight) {
        this.getMoreSupports();
      }
    };
  }

  setFilter(field, value) {
    const { filters } = this.state;
    filters[field] = value;
    this.setState(
      { filters, filteredSupports: [], hasAllSupports: false },
      () => {
        if (this.hasAnyFilter()) {
          this.getSupports().then(newSupports =>
            this.setState(() => ({ filteredSupports: newSupports }))
          );
        }
      }
    );
  }

  getSupports = (countToSkip = 0) => {
    const { filters } = this.state;
    this.setState({ loading: true });
    return SupportService.getSupports(
      countToSkip,
      filters.searchText,
      filters.initialDate,
      filters.finalDate
    ).finally(() => this.setState({ loading: false }));
  };

  getMoreSupports() {
    const { supports, filteredSupports, hasAllSupports, loading } = this.state;
    if (hasAllSupports) return;
    if (loading) {
      setTimeout(() => {
        this.getMoreSupports();
      }, 500);
      return;
    }
    this.getSupports(
      this.hasAnyFilter() ? filteredSupports.length : supports.length
    ).then(newSupports => {
      this.setState(prevState => {
        const typeData = this.hasAnyFilter() ? "filteredSupports" : "supports";
        return {
          hasAllSupports: newSupports.length === 0,
          [typeData]: prevState[typeData].concat(newSupports)
        };
      });
    });
  }

  setSupportCount() {
    SupportService.getSupportsCount().then(count =>
      this.setState({ supportCount: count })
    );
  }

  resetFilters() {
    this.setState({
      filters: {
        searchText: "",
        initialDate: null,
        finalDate: null
      }
    });
  }

  refreshList() {
    this.resetFilters();
    this.setState({ supports: [], hasAllSupports: false });
    this.getSupports().then(newSupports =>
      this.setState({ supports: newSupports })
    );
    this.setSupportCount();
  }

  updateSupportStatus(supportId, newStatusId) {
    const { supports, filteredSupports } = this.state;
    supports.find(e => e.id === supportId);

    const updatedSupports = supports.map(support => {
      if (support.id === supportId) {
        const newSupport = support;
        newSupport.statusId = newStatusId;
        return newSupport;
      }
      return support;
    });

    const updatedFilteredSupports = filteredSupports.map(support => {
      if (support.id === supportId) {
        const newSupport = support;
        newSupport.statusId = newStatusId;
        return newSupport;
      }
      return support;
    });

    this.setState({
      supports: updatedSupports,
      filteredSupports: updatedFilteredSupports
    });
  }

  hasAnyFilter() {
    const { filters } = this.state;
    const { searchText, initialDate, finalDate } = filters;
    return !!(searchText.trim() || initialDate || finalDate);
  }

  selectSupport(support) {
    this.setState({
      supportSelected: support,
      modalOpen: true
    });
  }

  handleCloseModal() {
    this.setState({ supportSelected: undefined, modalOpen: false });
  }

  openCreateForm() {
    this.setState({ modalOpen: true });
  }

  render() {
    const {
      supports,
      filteredSupports,
      loading,
      filters,
      supportCount,
      supportSelected,
      modalOpen
    } = this.state;
    // return () => <diov />;
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Group p={0}>
            <BlockButton
              startIcon={<AddCircleOutline />}
              onClick={() => this.openCreateForm()}
            >
              Novo Suporte
            </BlockButton>
          </Group>
        </Grid>
        <Grid item xs={12}>
          <Group>
            <Box>
              <Heading level={1} icon="history" gutterBottom>
                {`Histórico de Suportes (${supportCount})`}
              </Heading>

              <SupportList
                supports={this.hasAnyFilter() ? filteredSupports : supports}
                filters={filters}
                setFilter={(field, value) => this.setFilter(field, value)}
                onSelectSupport={(...props) => this.selectSupport(...props)}
              />
              {loading && (
                <Box p={6} textAlign="center">
                  <CircularProgress />
                </Box>
              )}
            </Box>
          </Group>
        </Grid>
        <ModalSupport
          support={supportSelected}
          open={modalOpen}
          onClose={() => this.handleCloseModal()}
          refreshList={() => this.refreshList()}
          updateSupportStatus={(...props) => this.updateSupportStatus(...props)}
        />
      </Grid>
    );
  }
}

export default Support;
