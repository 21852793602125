import React from "react";
import PropTypes from "prop-types";
import { Box, Icon, Grid } from "@material-ui/core";
import styled from "styled-components";
import RoundedAvatar from "../RoundedAvatar";

const Container = styled(Box)`
  height: 151px;
  * {
    z-index: 1;
  }
`;

const GridInfo = styled(Grid)`
  height: 100%;
  padding-top: 15px;
`;

const ProfileName = styled.h2`
  font-family: Roboto;
  font-size: 30px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.secondary.dark};
  margin: 0 0 30px;
`;

const ProfileTitle = styled.p`
  opacity: 0.5;
  font-family: Roboto;
  font-size: 21px;
  color: #000000;
  margin: 0 0 13.5px;
`;
const ProfileTag = styled.p`
  opacity: 0.5;
  font-family: Roboto;
  font-size: 18px;
  color: #000000;
  display: flex;
  align-items: center;
  margin: 0;
`;

const HeaderProfile = ({ photo, name, info, tag, tagIcon }) => {
  return (
    <Container display="flex">
      <RoundedAvatar size={150} imageSrc={photo} icon="person" />
      <Box marginLeft="40px">
        <GridInfo container direction="column" justify="center">
          <Grid item>
            <ProfileName>{name}</ProfileName>
          </Grid>
          <Grid item>
            <ProfileTitle>{info}</ProfileTitle>
          </Grid>
          <Grid item>
            {tag && (
              <ProfileTag>
                {tagIcon && <Icon>{tagIcon}</Icon>}
                {tag}
              </ProfileTag>
            )}
          </Grid>
        </GridInfo>
      </Box>
    </Container>
  );
};

HeaderProfile.defaultProps = {
  tag: "",
  tagIcon: ""
};

HeaderProfile.propTypes = {
  name: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
  tag: PropTypes.string,
  tagIcon: PropTypes.string,
  photo: PropTypes.string.isRequired
};

export default HeaderProfile;
