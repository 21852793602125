import styled from "styled-components";
import MuiDivider from "@material-ui/core/Divider";

const Divider = styled(MuiDivider)`
  height: 2px;
  margin: 0 -30px;
`;

Divider.propTypes = {
  ...MuiDivider.propTypes
};

export default Divider;
