import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Icon as MuiIcon,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import _ from "lodash";
import Chip from "@material-ui/core/Chip";

const Icon = styled(MuiIcon)`
  margin-right: 8px;
`;

const MultiOccurrencesForm = ({ types, onChange }) => {
  const { register, setValue, getValues, watch } = useForm({
    defaultValues: { occurrences: [] }
  });

  const handleChange = (field, val) => {
    if (field) setValue(field, val);

    onChange(getValues());
  };

  const onChangeOccurrences = e => {
    const val = e.target.value;
    const oldVal = getValues("occurrences");

    const removed = _.difference(oldVal, val);
    if (removed.length && removed[0].reasons.length) {
      setValue(`reason-${removed[0].id}`, null);
      setValue(`description-${removed[0].id}`, null);
    }
    handleChange("occurrences", val);
  };

  return (
    <Grid
      container
      spacing={2}
      justifyContent="space-around"
      alignItems="center"
    >
      <Grid item xs={5}>
        <FormControl fullWidth>
          <InputLabel>Lançar Ocorrência</InputLabel>
          <Select
            multiple
            {...register("occurrences")}
            value={watch("occurrences") || []}
            onChange={onChangeOccurrences}
            disabled={watch("absence")}
            input={<Input />}
            renderValue={selected => (
              <div>
                {selected.map(selectedValue => (
                  <Chip
                    onDelete={() => {
                      const value = types.filter(
                        e => e.id !== selectedValue.id
                      );
                      onChangeOccurrences(getValues("occurrences"), value);
                    }}
                    key={selectedValue.id}
                    label={selectedValue.name}
                    icon={selectedValue.icon}
                    onMouseDown={e => e.stopPropagation()}
                  />
                ))}
              </div>
            )}
          >
            {types.map(occur => (
              <MenuItem key={occur.id} value={occur}>
                <Icon>{occur.icon}</Icon>
                {occur.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2} />
      <Grid item xs={2}>
        <FormControlLabel
          control={(
            <Checkbox
              {...register("absence")}
              onChange={e => {
                setValue("occurrences", []);
                handleChange("absence", e.target.checked);
              }}
              color="primary"
            />
          )}
          label="Faltou"
        />
      </Grid>
      {watch("occurrences")
        .filter(occur => occur.reasons.length)
        .map(occur => (
          <>
            <Grid item xs={12}>
              <Box mt={3}>
                <Typography variant="body1" color="textSecondary">
                  {`Detalhes sobre ${occur.name}`}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={5}>
              <TextField
                select
                label="Motivo"
                {...register(`reason-${occur.id}`)}
                // value={
                //   watch(`reason-${occur.id}`) || ""
                // }
                onChange={e =>
                  handleChange(`reason-${occur.id}`, e.target.value)}
                fullWidth
              >
                {occur.reasons.map(reason => (
                  <MenuItem key={reason.id} value={reason.id}>
                    {reason.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={5}>
              {watch(`reason-${occur.id}`) && (
                <TextField
                  {...register(`description-${occur.id}`)}
                  placeholder="Observações (opcional)"
                  onChange={e =>
                    handleChange(`description-${occur.id}`, e.target.value)}
                  fullWidth
                  // value={watch(`description-${occur.id}`) || ""}
                  margin="normal"
                />
              )}
            </Grid>
          </>
        ))}
    </Grid>
  );
};

MultiOccurrencesForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  types: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      icon: PropTypes.string,
      name: PropTypes.string
    })
  ).isRequired
};

export default MultiOccurrencesForm;
