// TODO Reducer de teste, remover ou ajustar após fase de implementação do projeto
import { USER_LOGIN } from "../actions/actionTypes";

const INITIAL_STATE = {
  name: "Usuário",
  email: "a@a.com"
};

export const userReducer = (state = INITIAL_STATE, action) => {
  switch (action) {
    case USER_LOGIN:
      return {
        ...state,
        ...action.value
      };
    default:
      return state;
  }
};
