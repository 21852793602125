import React, {Component} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {Bookmark as BookmarkIcon} from "@material-ui/icons";
import {withRouter} from "react-router-dom";
import {Box, CircularProgress} from "@material-ui/core";
import _ from "lodash";
import {withAlert} from "react-alert";
import TableGrid from "../../../waybee-ui/TableGrid";
import TeacherService from "../../../services/TeacherService";

const LoadContainer = styled.div`
  text-align: center;
  margin: 8px;
`;

class ClassesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: [],
      loading: false,
      filteredClasses: []
    };
  }

  componentDidMount() {
    this.getClasses();
  }

  componentDidUpdate(prevProps) {
    const {filter} = this.props;
    if (prevProps.filter !== filter) {
      this.filterClasses(filter);
    }
  }

  filterClasses = filter => {
    this.setState({loading: true});
    const {classes} = this.state;
    const filteredClasses = _.filter(classes, grade =>
      _.includes(grade.name.toLowerCase(), filter.name.toLowerCase())
    );
    this.setState({filteredClasses, loading: false});
  };

  getClasses = async () => {
    this.setState({loading: true});
    try {
      // const { filter } = this.props;
      const classes = await TeacherService.getClasses();
      this.setState({classes, filteredClasses: classes});
    } catch (e) {
      const { alert } = this.props;
      alert.show(`Erro ao buscar as turmas`, { title: "Erro" });
      throw e;
    } finally {
      this.setState({loading: false});
    }
  };

  onSelectItem = gradeId => {
    const {history} = this.props;
    history.push(`../classes/${gradeId}`);
  };

  render() {
    const {filteredClasses, loading} = this.state;
    const {className, size} = this.props;

    if (loading)
      return (
        <LoadContainer>
          <CircularProgress />
        </LoadContainer>
      );

    return (
      <TableGrid className={className}>
        {filteredClasses.map(grade => {
          return (
            <TableGrid.Row
              key={`${grade.key}`}
              actionIcon
              button
              onClick={() => this.onSelectItem(grade.id)}
            >
              <TableGrid.Title
                {...(size === "compact"
                  ? {
                    xs: 12
                  }
                  : {
                    xs: 12,
                    sm: 4
                  })}
              >
                <Box {...(size === "compact" ? {ml: 1, mt: 1} : {})}>
                  {grade.name}
                </Box>
              </TableGrid.Title>
              <TableGrid.Text
                {...(size === "compact"
                  ? {
                    xs: 12
                  }
                  : {
                    xs: 12,
                    sm: 4
                  })}
              >
                <Box {...(size === "compact" ? {m: 1} : {})}>
                  {grade.period}
                </Box>
              </TableGrid.Text>
              {grade.studentCount && (
                <TableGrid.Text
                  {...(size === "compact"
                    ? {
                      xs: 12
                    }
                    : {
                      xs: 12,
                      sm: 4
                    })}
                >
                  <Box
                    alignItems="center"
                    display="flex"
                    {...(size === "compact"
                      ? {
                        ml: 0.5,
                        mb: 1
                      }
                      : {})}
                  >
                    <BookmarkIcon />
                    {`${grade.studentCount} Alunos`}
                  </Box>
                </TableGrid.Text>
              )}
            </TableGrid.Row>
          );
        })}
      </TableGrid>
    );
  }
}

ClassesList.defaultProps = {
  filter: null,
  className: "",
  size: "normal"
};

ClassesList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired, // Injected by withRouter
  filter: PropTypes.shape({
    name: PropTypes.string,
    period: PropTypes.string,
    teacher: PropTypes.number
  }),
  className: PropTypes.string,
  size: PropTypes.oneOf(["compact", "normal"]),
  alert: PropTypes.shape({
    show: PropTypes.func
  }).isRequired
};

export default withAlert()(withRouter(ClassesList));
