import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import RoundedAvatar from "../RoundedAvatar";

const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  height: 90px;
  background-color: #00303b;
  padding: 15px 20px;
  margin-bottom: 12px;
  display: flex;
  font-family: inherit;
`;

const TextContaner = styled.div`
  margin-left: 14px;
  width: 100%;
  margin: -10px -10px -14px 14px;
  overflow: hidden;
  display: flex;
  align-items: center;
`;

const Text = styled(Typography)`
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  display: block;
  line-clamp: 4;
  box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
`;

const SidebarHeader = ({ userPhoto, userName }) => (
  <Container>
    <RoundedAvatar
      size={58}
      icon="person"
      imageSrc={userPhoto}
      imageAlt="Foto de perfil do usuário"
    />
    <TextContaner>
      <Text variant="body1">{userName}</Text>
    </TextContaner>
  </Container>
);

SidebarHeader.propTypes = {
  userPhoto: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired
};

export default SidebarHeader;
