import React, { Component } from "react";
import PropTypes from "prop-types";
import MomentUtils from "@date-io/moment";
import Grid from "@material-ui/core/Grid";
import { Calendar, MuiPickersUtilsProvider } from "@material-ui/pickers";
import styled from "styled-components";
import "moment/locale/pt-br";

const Styled = styled.div`
  border-bottom: 2px solid #979797;
  border-color: rgba(151, 151, 151, 0.5);
  overflow: scroll;

  .month-calendar-container {
    margin: 0 55px;
  }
  .mult-calendar {
    padding-bottom: 30px;
  }
  .MuiPickersCalendarHeader-switchHeader {
    margin-top: 20px;
    button {
      display: none;
    }

    .MuiPickersCalendarHeader-transitionContainer {
      height: 45px;
      p {
        font-family: "Roboto", sans-serif;
        font-size: 24px;
        color: ${({ theme }) => theme.color.primary.main};
        text-align: left;
      }
    }
  }
  .MuiPickersCalendar-week {
    justify-content: space-between;
  }
  button.MuiPickersDay-day {
    width: 40px;
    height: 40px;
    box-shadow: none;
    p {
      font-size: 17.4px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.5);
      font-family: "Roboto", sans-serif;
    }
    &.MuiPickersDay-daySelected {
      background-color: transparent;
      border: 2px solid ${({ theme }) => theme.color.primary.main};
    }
    &.MuiPickersDay-current p {
      color: ${({ theme }) => theme.color.primary.dark};
    }
  }
  .MuiPickersCalendarHeader-daysHeader {
    justify-content: space-around;
    span {
      font-family: "Roboto", sans-serif;
      font-size: 17.4px;
      font-weight: 500;
      color: ${({ theme }) => theme.color.secondary.dark};
    }
  }
`;

class MultipleMonthCalendar extends Component {
  static removeSelected(className) {
    const container = document.querySelector(className);
    if (container) {
      const notSelectedButton = container.querySelector(
        ".MuiPickersDay-daySelected"
      );
      if (notSelectedButton) {
        notSelectedButton.classList.remove("MuiPickersDay-daySelected");
      }
    }
  }

  constructor(props) {
    super(props);
    const { selectedMonth } = this.props;
    this.moment = new MomentUtils().moment;
    this.state = {
      leftDate: selectedMonth,
      rightDate: this.moment(selectedMonth)
        .add(1, "M")
        .set("date", 1)
    };
  }

  componentDidMount() {
    MultipleMonthCalendar.removeSelected(".calendar-right");
    const weekDayHeaders = Array.from(
      document.querySelectorAll(".MuiPickersCalendarHeader-daysHeader")
    );
    weekDayHeaders.forEach(head => {
      const weekDays = Array.from(head.childNodes);
      weekDays.forEach(day => {
        day.textContent = day.textContent.substring(0, 1); // eslint-disable-line no-param-reassign
      });
    });
  }

  // eslint-disable-next-line camelcase, react/sort-comp
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { selectedMonth } = this.props;
    if (nextProps.selectedMonth !== selectedMonth) {
      this.setState(
        {
          leftDate: this.moment(nextProps.selectedMonth).set("date", 1),
          rightDate: this.moment(nextProps.selectedMonth)
            .add(1, "M")
            .set("date", 1)
        },
        () => MultipleMonthCalendar.removeSelected(".calendar-right")
      );
    }
  }

  onSelectDay(selectedDay, target) {
    const { onSelectDate } = this.props;
    this.setState(
      target === 0 ? { leftDate: selectedDay } : { rightDate: selectedDay },
      () => {
        MultipleMonthCalendar.removeSelected(
          target === 0 ? ".calendar-right" : ".calendar-left"
        );
      }
    );
    onSelectDate(selectedDay);
  }

  render() {
    const { leftDate, rightDate } = this.state;
    return (
      <Styled>
        <MuiPickersUtilsProvider utils={MomentUtils} locale="pt-br">
          <Grid container component="div" className="mult-calendar">
            <Grid item xs={6} component="div" className="calendar-left">
              <div className="month-calendar-container">
                <Calendar
                  date={leftDate}
                  onChange={date => this.onSelectDay(date, 0)}
                  allowKeyboardControl={false}
                />
              </div>
            </Grid>
            <Grid item xs={6} component="div" className="calendar-right">
              <div className="month-calendar-container">
                <Calendar
                  date={rightDate}
                  onChange={date => this.onSelectDay(date, 1)}
                  allowKeyboardControl={false}
                />
              </div>
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </Styled>
    );
  }
}

MultipleMonthCalendar.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  selectedMonth: PropTypes.object.isRequired, // Date obj
  onSelectDate: PropTypes.func.isRequired
};

export default MultipleMonthCalendar;
