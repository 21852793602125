import { shape, number, string, object, arrayOf, bool } from "prop-types";

export const communicationType = shape({
  id: number,
  subscriberId: number,
  userId: number,
  title: string,
  date: string,
  type: number,
  photo: string,
  bodyMessage: string,
  cover: string,
  button: shape({
    text: string,
    link: string
  }),
  event: shape({
    date: string,
    location: string
  }),
  attachments: arrayOf(object),
  interaction: shape({
    clickedAt: string,
    openedAt: string,
    hadInterestAt: string,
    confirmedPresenceAt: string
  })
});

export const communicationStateType = shape({
  communications: arrayOf(communicationType),
  count: number,
  page: number,
  unread: number
});

export const notificationStateType = shape({
  notifications: arrayOf(
    shape({
      id: number,
      type: string,
      modelId: number,
      title: string,
      createdAt: string,
      opened: bool
    })
  ),
  count: number,
  page: number,
  unread: number
});
