import React, { Component } from "react";
import {
  Box,
  Grid,
  // FormControl,
  // InputLabel,
  // MenuItem,
  // Select,
  TextField
} from "@material-ui/core";
import styled from "styled-components";
import { DebounceInput } from "react-debounce-input";
import WuiClassesList from "./components/ClassesList";
import Group from "../../waybee-ui/Group";
import Heading from "../../waybee-ui/Heading";

const ClassesList = styled(WuiClassesList)`
  table {
    width: calc(100% + 60px);
    margin: 0 -30px -20px;
  }
`;

class Classes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        name: "",
        period: ""
      }
      // periods: []
    };
  }

  componentDidMount() {
    // this.getPeriods();
  }

  // getPeriods = async () => {
  //   const mockPeriods = [
  //     { id: 1, name: "Manhã" },
  //     { id: 2, name: "Tarde" },
  //     { id: 3, name: "Noite" }
  //   ];
  //   this.setState({ periods: mockPeriods });
  // };

  setFilter(field, value) {
    const { filter } = this.state;
    this.setState({
      filter: {
        ...filter,
        [field]: value
      }
    });
  }

  render() {
    const { filter /* periods */ } = this.state;
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Group>
            <Box>
              <Heading level={1} icon="group_work" gutterBottom>
                Lista de Turmas
                <Box pb={4} pt={2}>
                  <form noValidate autoComplete="off">
                    <Grid container spacing={8}>
                      <Grid item md={6}>
                        <DebounceInput
                          minLength={3}
                          debounceTimeout={300}
                          element={TextField}
                          value={filter.name}
                          onChange={e => this.setFilter("name", e.target.value)}
                          label="Buscar por Nome"
                          fullWidth
                        />
                      </Grid>
                      {/* <Grid item md={6}> */}
                      {/*  <FormControl fullWidth> */}
                      {/*    <InputLabel id="filter-course-label"> */}
                      {/*      Período */}
                      {/*    </InputLabel> */}
                      {/*    <Select */}
                      {/*      fullWidth */}
                      {/*      labelId="filter-course-label" */}
                      {/*      value={filter.course} */}
                      {/*      onChange={e => */}
                      {/*        this.setFilter("course", e.target.value)} */}
                      {/*    > */}
                      {/*      {periods.map(period => ( */}
                      {/*        <MenuItem key={period.id} value={period.id}> */}
                      {/*          {period.name} */}
                      {/*        </MenuItem> */}
                      {/*      ))} */}
                      {/*    </Select> */}
                      {/*  </FormControl> */}
                      {/* </Grid> */}
                    </Grid>
                  </form>
                </Box>
              </Heading>
              <ClassesList filter={filter} />
            </Box>
          </Group>
        </Grid>
      </Grid>
    );
  }
}

export default Classes;
