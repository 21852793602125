import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  Box,
  Table as MuiTable,
  TableBody,
  withTheme
} from "@material-ui/core";
import TableListItem from "../../../waybee-ui/TableList/TableListItem";

const Table = styled(MuiTable)`
  width: calc(100% + 60px);
  max-height: calc(100vh - 430px);
  margin: 0 -30px -20px;
  border: solid #f2f2f2;
  border-width: 2px 0 2px 0;
`;

const TeacherList = ({ teachers, onSelectItem, theme }) => {
  return (
    <Box>
      <Table aria-label="simple table">
        <TableBody>
          {teachers.map(teacher => {
            const listItem = {
              ...teacher,
              title: teacher.name,
              description: teacher.email,
              info: teacher.course
            };

            return (
              <TableListItem
                key={teacher.id}
                statusText="Mais Informações"
                statusIcon="add_circle_outline"
                statusColor={theme.palette.primary.main}
                image={teacher.photo}
                infoIcon="bookmark"
                item={listItem}
                onSelectitem={onSelectItem}
              />
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};

TeacherList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  teachers: PropTypes.array.isRequired,
  onSelectItem: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object.isRequired // Get from withTheme()
};

export default withTheme(TeacherList);
