import { api } from "../utils/api";
import UserStore from "../utils/UserStore";

const getMockTeacher = id => {
  return {
    id,
    photo: "https://www.thispersondoesnotexist.com/image",
    name: "Nome do Professor",
    email: "email@colegio.com",
    course: "Disciplina"
  };
};

const teacherData = [];
teacherData.push(getMockTeacher(1));
teacherData.push(getMockTeacher(2));
teacherData.push(getMockTeacher(3));
teacherData.push(getMockTeacher(4));

const getTeachers = async () => {
  return { data: teacherData };
};

const getCourses = async () => {
  return {
    data: [
      { id: 1, name: "Disciplina 1" },
      { id: 2, name: "Disciplina 2" },
      { id: 3, name: "Disciplina 3" }
    ]
  };
};

const getSchedule = async (start, end, userId) => {
  const res = await api.get(
    `Academics/teacher/schedule${userId ? `/${userId}` : ""}`,
    { params: { start, end } }
  );
  return res.data;
};

const getClasses = async () => {
  const res = await api.get("ClassDiaryV1/class", {
    params: {
      externalData: UserStore.getUserExternalInfo()
    }
  });
  return res.data;
};

const getStudentsForClass = async id => {
  const res = await api.get(`ClassDiaryV1/class/${id}/students`);
  return res.data;
};

const getScheduleForOccurrence = async id => {
  const res = await api.get(`ClassDiaryV1/class/${id}/occurrences/schedule`, {
    params: {
      externalData: UserStore.getUserExternalInfo()
    }
  });
  return res.data;
};

const getOccurrenceTypes = async () => {
  const res = await api.get(`ClassDiaryV1/occurrences/types`);
  return res.data;
};

const getOccurrence = async (idTurmaDisc, occurrenceDate, scheduleId) => {
  const res = await api.get(`ClassDiaryV1/occurrences`, {
    params: {
      idTurmaDisc,
      occurrenceDate,
      scheduleId
    }
  });
  return res.data;
};

const saveOccurrence = async occurrenceData => {
  const res = await api.post("ClassDiaryV1/occurrences", {
    externalData: UserStore.getUserExternalInfo(),
    occurrenceData
  });
  return res.data;
};

export default {
  // //////////////////////////
  getTeachers,
  getCourses,
  getSchedule,
  getClasses,
  getStudentsForClass,
  getScheduleForOccurrence,
  getOccurrenceTypes,
  getOccurrence,
  saveOccurrence
};
