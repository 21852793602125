import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Placeholder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: white;
`;

const AvatarPlaceholder = ({ className, children }) => {
  const getInitials = name => {
    if (!name) return undefined;
    const splitName = (index, start, end) =>
      name
        .split(" ")
        [index].substring(start, end)
        .toUpperCase();
    const fullName = name.split(" ");
    const firstNameLetter = splitName(0, 0, 1);
    let secondLetter = "";

    if (fullName.length < 2) {
      return firstNameLetter;
    }

    if (fullName[1].length <= 2) {
      // Gets next name first letter (Eg: Francisco de Assis = FA, ignores "de")
      secondLetter = splitName(2, 1, 0);
    } else {
      // gets second name first letter
      secondLetter = splitName(1, 1, 0);
    }

    return firstNameLetter + secondLetter;
  };

  return (
    <div>
      <Placeholder className={className}>{getInitials(children)}</Placeholder>
    </div>
  );
};

AvatarPlaceholder.defaultProps = {
  className: "",
  children: null
};

AvatarPlaceholder.propTypes = {
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any
};

export default AvatarPlaceholder;
