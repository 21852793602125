import React from "react";
import Calendar from "../../waybee-ui/Calendar/Calendar";
import TeacherService from "../../services/TeacherService";

export default function Schedule() {
  const options = {
    views: {
      timeGridWeek: {
        titleFormat: { month: "long", day: "numeric" },
        slotLabelFormat: { hour: "numeric", minute: "2-digit" },
        columnHeaderFormat: { weekday: "short", day: "numeric" }
      },
      timeGridDay: {
        titleFormat: { month: "long", day: "numeric", weekday: "short" }
      }
    },
    allDaySlot: false,
    events: async (info, successCallback, failureCallback) => {
      try {
        const data = await TeacherService.getSchedule(
          info.startStr,
          info.endStr
        );
        successCallback(data);
      } catch (e) {
        failureCallback(e);
      }
    },
    defaultView: "timeGridWeek"
  };

  return (
    <div>
      <Calendar options={options} />
    </div>
  );
}
