import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { useTheme } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import Cell from "./Cell";

const StatusCell = styled(Cell)`
  svg {
    color: ${props => props.color};
    font-size: 30px;
  }
  p {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    color: ${props => props.color};
  }
`;

const Status = props => {
  const { icon, type, children } = props;
  const theme = useTheme();
  const color = theme.palette[type].main;
  return (
    <StatusCell color={color}>
      <Grid
        container
        direction="row-reverse"
        alignItems="center"
        justify="right"
        spacing={1}
      >
        <Grid item>
          <Typography variant="body1">{children}</Typography>
        </Grid>
        <Grid item>{icon}</Grid>
      </Grid>
    </StatusCell>
  );
};

Status.propTypes = {
  children: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  type: PropTypes.string.isRequired
};

export default Status;
