import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  jssPreset,
  MuiThemeProvider,
  StylesProvider
} from "@material-ui/core/styles";
import styled, {
  ThemeProvider as StyledThemeProvider
} from "styled-components";
import { CssBaseline } from "@material-ui/core";
import { Provider as AlertProvider } from "react-alert";
import { BrowserRouter } from "react-router-dom";
import { create } from "jss";
import { bindActionCreators } from "redux";

import CircularProgress from "@material-ui/core/CircularProgress";
import AlertTemplate from "./waybee-ui/Alert/Alert";
import BugsnagService from "./services/BugsnagService";
import CreateMuiTheme from "./assets/theme/MuiTheme";
import AppService from "./services/AppService";
import { loadTheme } from "./redux/actions";

const Loading = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-mui-style")
});

class AppProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: null
    };
  }

  componentDidMount() {
    this.getTheme();
  }

  getTheme = async () => {
    const { loadTheme: setTheme, theme } = this.props;
    try {
      const res = await AppService.getTheme();
      setTheme(res);
    } catch (e) {
      console.error(e);
    }
    this.setState({ theme });
  };

  render() {
    const { children } = this.props;
    const { theme } = this.state;

    let MuiTheme;
    if (theme) MuiTheme = CreateMuiTheme(theme);

    return (
      <>
        {theme ? (
          <BugsnagService.ErrorBoundary>
            <StylesProvider jss={jss}>
              <StyledThemeProvider theme={theme}>
                <MuiThemeProvider theme={MuiTheme}>
                  <CssBaseline>
                    <AlertProvider template={AlertTemplate}>
                      <BrowserRouter>{children}</BrowserRouter>
                    </AlertProvider>
                  </CssBaseline>
                </MuiThemeProvider>
              </StyledThemeProvider>
            </StylesProvider>
          </BugsnagService.ErrorBoundary>
        ) : (
          <Loading>
            <CircularProgress />
          </Loading>
        )}
      </>
    );
  }
}

AppProvider.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object.isRequired, // injected by mapStateToProps
  children: PropTypes.node.isRequired,
  loadTheme: PropTypes.func.isRequired // injected by mapDispatchToProps
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ loadTheme }, dispatch);

const mapStateToProps = state => ({
  theme: state.theme
});

export default connect(mapStateToProps, mapDispatchToProps)(AppProvider);
