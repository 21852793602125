import PropTypes from "prop-types";
import React, { Component } from "react";
import { Grid, Box, CircularProgress } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { withAlert } from "react-alert";
import Calendar from "../../waybee-ui/Calendar/Calendar";
import HeaderInfo from "../../waybee-ui/HeaderInfo/HeaderInfo";
import Group from "../../waybee-ui/Group";
import Divider from "../../waybee-ui/Divider";
import BackButton from "../../components/BackButton";
import StudentService from "../../services/StudentService";

const HeaderGroup = styled(Group)`
  margin-top: 20px;
  padding-bottom: 0;
`;

const LoadContainer = styled.div`
  width: 100%;
  text-align: center;
  margin: 8px;
`;

class StudentSchedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      student: {},
      loading: false
    };
  }

  componentDidMount() {
    this.getStudent();
  }

  getStudent = async () => {
    const {
      match: {
        params: { studentId }
      },
      alert
    } = this.props;
    this.setState({ loading: true });

    try {
      const student = await StudentService.getStudentById(studentId);
      this.setState({ student });
    } catch (e) {
      console.error(e);
      alert.show(`Erro ao buscar os dados alunos`, { title: "Erro" });
    } finally {
      this.setState({ loading: false });
    }
  };

  getOptions = () => {
    const {
      match: {
        params: { studentId }
      },
      alert
    } = this.props;

    return {
      views: {
        timeGridWeek: {
          titleFormat: { month: "long", day: "numeric" },
          slotLabelFormat: { hour: "numeric", minute: "2-digit" },
          columnHeaderFormat: { weekday: "short", day: "numeric" }
        },
        timeGridDay: {
          titleFormat: { month: "long", day: "numeric", weekday: "short" }
        }
      },
      allDaySlot: false,
      events: async (info, successCallback, failureCallback) => {
        try {
          const schedule = await StudentService.getSchedule(
            info.startStr,
            info.endStr,
            studentId
          );
          successCallback(schedule);
        } catch (e) {
          alert.show(`Erro ao buscar os horários`, { title: "Erro" });
          failureCallback(e);
        }
      },
      defaultView: "timeGridWeek"
    };
  };

  render() {
    const {
      match: {
        params: { studentId }
      }
    } = this.props;
    const { student, loading } = this.state;

    return (
      <Grid container>
        <BackButton to={`/student/${studentId}`} />
        {loading ? (
          <LoadContainer>
            <CircularProgress />
          </LoadContainer>
        ) : (
          <>
            <Grid item xs={12}>
              <HeaderGroup>
                <Box mb={2}>
                  <HeaderInfo
                    name={student.name}
                    info={`R.A: ${student.ra}`}
                    photo={student.photo}
                  />
                </Box>
                <Divider />
              </HeaderGroup>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Calendar options={this.getOptions()} />
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    );
  }
}

StudentSchedule.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.array
  }).isRequired,
  alert: PropTypes.shape({
    show: PropTypes.func
  }).isRequired
};
export default withAlert()(withRouter(StudentSchedule));
