import React, { Component } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { withAlert } from "react-alert";
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-community/index.css";
import { AddCircleOutline, HelpOutlined as HelpIcon } from "@material-ui/icons";
import BackButton from "../../components/BackButton";
import Group from "../../waybee-ui/Group";
import ClassService from "../../services/ClassService";
import translate from "../../components/DataGrid/translate.json";
import HoverPopover from "../../components/HoverPopover";
import BlockButton from "../../waybee-ui/BlockButton/BlockButton";
import HeaderDetails from "./components/HeaderDetails";
import TestDialog from "./components/TestDialog";
import SimpleDialog from "../../waybee-ui/Dialog/SimpleDialog";
import Dialog from "../../waybee-ui/Dialog";
import DialogTitle from "../../waybee-ui/Dialog/DialogTitle";
import Heading from "../../waybee-ui/Heading";
import DialogContent from "../../waybee-ui/Dialog/DialogContent";
import DialogActions from "../../waybee-ui/Dialog/DialogActions";

const LoadContainer = styled.div`
  width: 100%;
  text-align: center;
  padding: 32px 8px;
`;

const CellText = styled.p`
  color: rgba(0, 0, 0, 0.5);
  font-size: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
  ${({ editable }) =>
    !!editable &&
    css`
      margin: 0;
      border: 1px solid rgba(0, 0, 0, 0.25);
      padding: 5px;
      border-radius: 5px;
      min-height: 30px;
    `}
`;
const RollNum = styled.span`
  color: ${({ theme }) => theme.color.primary.main};
  margin-right: 12px;
`;

const Footer = styled(Grid)`
  background-color: white;
  border-right: 1px solid #e4e3e2;
  border-bottom: 1px solid #e4e3e2;
  border-left: 1px solid #e4e3e2;
  display: flex;
  justify-content: end;
  padding: 10px;
`;
const SaveButton = styled(Button)`
  height: 44px;
  font-size: 14px;
  margin-left: 64px;
`;
const HelpButton = styled(Button)`
  padding: 0 64px;
  border-radius: 0;
  color: rgba(0, 0, 0, 0.5);
  border-right: 1px solid #ccc;
  p {
    margin: 0 0 0 8px;
    text-transform: capitalize;
  }
`;

class ClassGradesV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rawData: null,
      dataSource: [],
      columns: [],
      groups: [],
      courses: [],
      periods: [],
      testTypes: [],
      loadingFilters: false,
      loadingTable: false,
      loadingSaveTest: false,
      loadingSaveGrade: false,
      openTestDialog: false,
      openConfirmTest: false,
      openFormula: false,
      filter: {},
      values: {},
      processError: null
    };
  }

  componentDidMount() {
    this.getCourses();
  }

  getGradePeriods = async idTurmaDisc => {
    const { alert } = this.props;

    try {
      const periods = await ClassService.getGradePeriods(idTurmaDisc);
      if (!periods || !periods.length) {
        this.setState({
          periods: [],
          processError: new Error(
            "Não encontrados períodos para a disciplina selecionada"
          )
        });
        return;
      }

      let currentPeriod = periods.find(period => period.current);
      if (!currentPeriod) [currentPeriod] = periods;
      this.setState({ periods });
      this.setFilter("period", currentPeriod.id);

      this.getGrades(idTurmaDisc, currentPeriod.id);
    } catch (e) {
      console.error(e);
      alert.show("Ocorreu um erro ao buscar os períodos", { title: "Erro" });
    } finally {
      this.setState({ loadingFilters: false });
    }
  };

  getCourses = async () => {
    const { match, alert } = this.props;

    this.setState({ loadingFilters: true });

    const classId = match.params.id;
    try {
      const disciplines = await ClassService.getDisciplines(classId);
      this.setState({ courses: disciplines });
      if (disciplines && disciplines.length) {
        this.setFilter("course", disciplines[0]);
        await this.getGradePeriods(disciplines[0].idTurmaDisc);
      }
    } catch (e) {
      console.error(e);
      alert.show("Ocorreu um erro ao buscar as disciplinas", { title: "Erro" });
    }
  };

  getGrades = async (idTurmaDisc, period) => {
    this.setState({ loadingTable: true });

    const grades = await ClassService.getGradesGridData(idTurmaDisc, period);

    const groups = grades.groups.map(group => ({
      ...group,
      header: <HeaderDetails title={group.header} />
    }));

    const columns = grades.columns.map(column => ({
      name: column.id,
      header: column.header,
      group: column.group,
      editable: !!column.editable,
      minWidth: 185,
      render: ({ value }) => {
        return <CellText editable={column.editable}>{value}</CellText>;
      },
      renderHeader: ({ value }) => (
        <HeaderDetails
          title={value}
          color={column.color}
          popoverProps={{
            title: column.title,
            value: column.value
          }}
          hasDetails
        />
      )
    }));

    const tableEl = document.querySelector(".grades-table");
    columns.unshift({
      name: "studentId",
      header: "R.A.",
      editable: false,
      locked: true,
      group: "data",
      width: tableEl ? tableEl.offsetWidth * 0.15 : 150,
      render: ({ value }) => <CellText>{value}</CellText>,
      renderHeader: ({ value }) => <HeaderDetails title={value} />
    });
    columns.unshift({
      name: "name",
      header: "Aluno",
      editable: false,
      locked: true,
      group: "data",
      width: tableEl ? tableEl.offsetWidth * 0.35 : 350,
      render: ({ value, data }) => (
        <HoverPopover key={data.id} id={data.id} value={value}>
          <CellText>
            <RollNum>{data.rollCallNum}</RollNum>
            {value}
          </CellText>
        </HoverPopover>
      ),
      renderHeader: ({ value }) => <HeaderDetails title={value} />
    });

    this.setState({
      rawData: grades,
      columns,
      dataSource: grades.students,
      testTypes: grades.testTypes,
      groups,
      loadingTable: false
    });
  };

  setFilter(field, value) {
    const { filter } = this.state;
    this.setState({
      filter: {
        ...filter,
        [field]: value
      }
    });
  }

  onEditComplete = ({ value, columnId, rowIndex, data }) => {
    const {
      dataSource,
      values,
      filter,
      rawData: { columns }
    } = this.state;

    const columnData = columns.find(column => column.id === columnId);

    const storedData = [...dataSource];
    storedData[rowIndex] = { ...storedData[rowIndex], [columnId]: value };

    const gradeObj = {
      idExam: columnData.CODPROVA,
      idStep: columnData.CODETAPA,
      idTurmaDisc: filter.course.idTurmaDisc,
      studentId: data.studentId,
      value: value || ""
    };
    values[`${data.studentId}-${columnId}`] = gradeObj;

    this.setState({ dataSource: storedData, values });
  };

  changeCourse = e => {
    this.setState({ loadingFilters: true, processError: null });
    this.setFilter("course", e.target.value);
    this.getGradePeriods(e.target.value.idTurmaDisc);
  };

  changePeriod = e => {
    const { filter } = this.state;

    this.setFilter("period", e.target.value);
    this.getGrades(filter.course.idTurmaDisc, e.target.value);
  };

  saveGrades = async () => {
    const { alert } = this.props;
    const { values, filter } = this.state;

    this.setState({ loadingSaveGrade: true });
    try {
      await ClassService.saveGradesV2(Object.values(values));
      alert.show("Notas salvas com sucesso", { title: "Sucesso" });
      this.getGrades(filter.course.idTurmaDisc, filter.period);
    } catch (e) {
      console.error(e);
      alert.show("Ocorreu um erro ao salvar as notas", { title: "Erro" });
    } finally {
      this.setState({ loadingSaveGrade: false });
    }
  };

  saveTest = async data => {
    const { alert } = this.props;
    const { filter } = this.state;

    this.setState({
      loadingSaveTest: true,
      openConfirmTest: true,
      openTestDialog: false
    });

    try {
      await ClassService.saveTest({
        idTurmaDisc: filter.course.idTurmaDisc,
        period: filter.period,
        description: data.description || data.type?.name,
        value: data.value
      });
      this.setState({ loadingSaveTest: false });
      this.getGrades(filter.course.idTurmaDisc, filter.period);
    } catch (e) {
      console.error(e);
      this.setState({ loadingSaveTest: false, openConfirmTest: false });
      alert.show("Ocorreu um erro ao salvar as disciplinas", { title: "Erro" });
    }
  };

  render() {
    const { history } = this.props;
    const {
      columns,
      groups,
      dataSource,
      courses,
      testTypes,
      loadingFilters,
      loadingTable,
      loadingSaveTest,
      loadingSaveGrade,
      openTestDialog,
      openConfirmTest,
      openFormula,
      filter,
      periods,
      processError
    } = this.state;

    const formula = periods.find(period => period.id === filter.period)
      ?.formula;

    return (
      <>
        <Grid container spacing={2}>
          <BackButton onClick={history.goBack} />
          {!loadingFilters && !filter?.course?.mother && (
            <Grid item xs={12}>
              <BlockButton
                startIcon={<AddCircleOutline />}
                onClick={() => this.setState({ openTestDialog: true })}
              >
                ADICIONAR AVALIAÇÃO
              </BlockButton>
            </Grid>
          )}

          <Grid item xs={12}>
            <Group fullWidth>
              {loadingFilters ? (
                <LoadContainer>
                  <CircularProgress />
                </LoadContainer>
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel id="filter-course-label">
                        Disciplina
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="filter-course-label"
                        value={filter.course}
                        onChange={this.changeCourse}
                      >
                        {courses.map(course => (
                          <MenuItem value={course}>{course.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel id="filter-course-label">Período</InputLabel>
                      <Select
                        fullWidth
                        onChange={this.changePeriod}
                        value={filter.period}
                      >
                        {periods.map(period => (
                          <MenuItem value={period.id}>{period.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              )}
            </Group>
          </Grid>

          {!loadingFilters && processError && (
            <Grid item xs={12}>
              <Group fullWidth>{processError.message}</Group>
            </Grid>
          )}

          {!loadingFilters && !processError && (
            <Grid item xs={12}>
              {loadingTable ? (
                <LoadContainer>
                  <CircularProgress />
                </LoadContainer>
              ) : (
                <>
                  <ReactDataGrid
                    className="grades-table"
                    idProperty="id"
                    columns={columns}
                    groups={groups}
                    dataSource={dataSource}
                    onEditComplete={this.onEditComplete}
                    resizable
                    editable
                    sortable={false}
                    enableSelection={false}
                    rowHeight={50}
                    style={{ minHeight: 550 }}
                    i18n={translate.i18n}
                  />

                  <Footer item xs={12}>
                    {formula && (
                      <HelpButton
                        onClick={() => this.setState({ openFormula: true })}
                      >
                        <HelpIcon />
                        <p>Cálculo de média</p>
                      </HelpButton>
                    )}
                    <SaveButton
                      variant="contained"
                      color="primary"
                      onClick={this.saveGrades}
                    >
                      Salvar alterações
                    </SaveButton>
                  </Footer>
                </>
              )}
            </Grid>
          )}
        </Grid>

        <TestDialog
          open={openTestDialog}
          testTypes={testTypes}
          onSave={this.saveTest}
          onClose={() => this.setState({ openTestDialog: false })}
        />

        <Dialog
          open={openConfirmTest}
          size="sm"
          fullWidth
          onClose={() => this.setState({ openConfirmTest: false })}
        >
          <DialogTitle>
            {loadingSaveTest ? "Salvando Avaliações" : "Nova Avaliação"}
          </DialogTitle>
          {!loadingSaveTest ? (
            <>
              <DialogContent>
                <Heading>Avaliação criada com sucesso!</Heading>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.setState({ openConfirmTest: false })}
                >
                  Ok
                </Button>
              </DialogActions>
            </>
          ) : (
            <LoadContainer>
              <CircularProgress />
            </LoadContainer>
          )}
        </Dialog>

        <SimpleDialog
          open={loadingSaveGrade}
          onClose={() => this.setState({ loadingSaveGrade: false })}
          title="Salvando"
          loading
        />

        <SimpleDialog
          open={openFormula}
          closeBtn
          onClose={() => this.setState({ openFormula: false })}
          title="Fórmula de calculo"
          message={formula}
        />
      </>
    );
  }
}

ClassGradesV2.propTypes = {
  alert: PropTypes.shape({
    show: PropTypes.func
  }).isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.any
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.any
    })
  }).isRequired
};

export default withAlert()(withRouter(ClassGradesV2));
