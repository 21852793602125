import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { withAlert } from "react-alert";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  TextField,
  MenuItem
} from "@material-ui/core";
import SupportService from "../../../services/SupportService";
import WuiDialog from "../../../waybee-ui/Dialog";
import Button from "../../../waybee-ui/Button";

require("../../../utils/yup.locale.pt-br");

const validationSchema = Yup.object().shape({
  typeId: Yup.string().required(),
  reasonId: Yup.string().required(),
  location: Yup.string().required(),
  description: Yup.string()
});

class ModalSupport extends Component {
  constructor() {
    super();
    this.state = {
      supportReasons: [],
      supportTypes: [],
      loading: false
    };
  }

  componentDidMount() {
    SupportService.getSupportReasons().then(value => {
      const supportReasons = value;
      const removeDuplicateTypes = types => {
        return types.filter((typeObj, i, arr) => {
          return arr.map(e => e.id).indexOf(typeObj.id) === i;
        });
      };
      let supportTypes = supportReasons.map(e => e.SupportType);
      supportTypes = removeDuplicateTypes(supportTypes);
      this.setState({ supportReasons, supportTypes });
    });
  }

  createSupport = values => {
    const { alert, refreshList } = this.props;
    this.setState({ loading: true });
    return SupportService.createSupport(values)
      .then(() => {
        alert.show("Suporte enviado com sucesso.", { title: "Novo Suporte" });
      })
      .catch(e => {
        alert.show(e.message, {
          title: "Erro ao Enviar Suporte"
        });
      })
      .then(refreshList)
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  changeStatus = async statusId => {
    const { support, alert, updateSupportStatus, onClose } = this.props;
    this.setState({ loading: true });

    let alertTitle = "";
    let alertMessage = "";
    let alertError = "";

    switch (statusId) {
      case 3:
        alertTitle = "Atendimento iniciado";
        alertMessage = "Atendimento iniciado com sucesso";
        alertError = "Erro ao iniciar atendimento";
        break;
      case 4:
        alertTitle = "Atendimento concluído";
        alertMessage = "Atendimento concluído com sucesso";
        alertError = "Erro ao concluir atendimento";
        break;
      default:
        alertTitle = "Sucesso";
        alertMessage = "Ação executada com sucesso";
        alertError = "Ocorreu um erro ao executar a ação";
    }

    try {
      await SupportService.changeSupportStatus(support.id, statusId);
      updateSupportStatus(support.id, statusId);
      alert.show(alertMessage, {
        title: alertTitle
      });
    } catch (e) {
      alert.show(e.message, {
        title: alertError
      });
    } finally {
      this.setState({ loading: false });
      onClose();
    }
  };

  cancelSupport = () => {
    const { support, alert, updateSupportStatus } = this.props;
    this.setState({ loading: true });
    return SupportService.cancelSupport(support.id)
      .then(() => {
        alert.show("Suporte cancelado com sucesso.", {
          title: "Suporte Cancelado"
        });
      })
      .catch(e => {
        alert.show(e.message, {
          title: "Erro ao Cancelar Suporte"
        });
      })
      .then(() => {
        const canceledStatusId = 5;
        updateSupportStatus(support.id, canceledStatusId);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  actionButtons = ({ handleSubmit, handleReset }) => {
    const { loading } = this.state;
    const { onClose } = this.props;
    const ActionButton = props => (
      <Button variant="contained" disabled={loading} {...props} />
    );
    const buttons = {
      CancelSupport: () => (
        <ActionButton
          onClick={() => {
            this.cancelSupport().then(handleReset);
          }}
          color="error"
        >
          Cancelar
        </ActionButton>
      ),
      CreateSupport: () => (
        <ActionButton onClick={handleSubmit} color="primary">
          Enviar
        </ActionButton>
      ),
      BackToClose: () => (
        <ActionButton onClick={onClose} color="secondary">
          Voltar
        </ActionButton>
      ),
      ConfirmToClose: () => (
        <ActionButton onClick={onClose} color="primary">
          Ok
        </ActionButton>
      ),
      StartAttendance: () => (
        <ActionButton onClick={() => this.changeStatus(3)} color="primary">
          Iniciar Atendimento
        </ActionButton>
      ),
      ConcludeAttendance: () => (
        <ActionButton onClick={() => this.changeStatus(4)} color="primary">
          Concluir Atendimento
        </ActionButton>
      )
    };
    return buttons;
  };

  getDialogActionsComponent = ({ handleSubmit, handleReset }) => {
    const { support, open } = this.props;
    const {
      BackToClose,
      CreateSupport,
      CancelSupport,
      ConfirmToClose,
      StartAttendance,
      ConcludeAttendance
    } = this.actionButtons({ handleSubmit, handleReset });

    // 1- Aberto, 2 - Aguardando suporte, 3 - Aguardando avaliação, 4 - Concluído, 5 - Cancelado
    if (support.statusId) {
      switch (support.statusId) {
        case 1:
          this.submitAction = this.cancelSupport;
          return (
            <Fragment key="key">
              <CancelSupport />
              <StartAttendance />
            </Fragment>
          );
        case 2:
        case 3:
          this.submitAction = this.cancelSupport;
          return (
            <Fragment key="key">
              <CancelSupport />
              <ConcludeAttendance />
            </Fragment>
          );
        case 4:
        case 5:
          return <ConfirmToClose />;
        default:
          return <Fragment key="key" />;
      }
    }
    if (open) {
      return (
        <Fragment key="key">
          <BackToClose />
          <CreateSupport />
        </Fragment>
      );
    }
    return <Fragment key="key" />;
  };

  render() {
    const { support, open, onClose } = this.props;
    const { supportTypes, supportReasons, loading } = this.state;
    let textTitle;
    let textSubtitle;
    let fieldsDisabled = true;
    switch (support.statusId) {
      case 1:
        textTitle = "Suporte Pendente";
        textSubtitle =
          "Caso possua algum erro, exclua o suporte que está em aberto.";
        break;
      case 2:
      case 3:
        textTitle = "Suporte em Andamento";
        textSubtitle = `Suporte criado no
            dia ${moment(support.createdAt).format("DD/MM/YYYY")}
            por ${support.UserCreatedBy.name}`;
        break;
      case 4:
        textTitle = "Suporte Concluído";
        textSubtitle = `Suporte Concluído no
            dia ${moment(support.updatedAt).format("DD/MM/YYYY")}
            por ${support.UpdatedByUser.name}`;
        break;
      case 5:
        textTitle = "Suporte Cancelado";
        textSubtitle = `Suporte Cancelado no
            dia ${moment(support.updatedAt).format("DD/MM/YYYY")}
            por ${support.UpdatedByUser.name}`;
        break;
      default:
        textTitle = "Novo Suporte";
        textSubtitle =
          "Notifique a equipe de suporte sobre alguma ocorrência ou problema técnico na sala.";
        fieldsDisabled = false;
        break;
    }
    if (loading) {
      fieldsDisabled = true;
    }
    return (
      <WuiDialog
        onClose={onClose}
        open={open}
        onEnter={this.handleOpen}
        maxWidth="sm"
        fullWidth
        closeButton
        loading={loading}
        disableBackdropClick={loading}
      >
        <Formik
          validateOnBlur
          isInitialValid={false}
          validateOnChange={false}
          initialValues={
            !support.id
              ? {}
              : {
                  typeId: support.SupportReason.SupportType.id,
                  reasonId: support.SupportReason.id,
                  location: support.location,
                  description: support.description
                }
          }
          onSubmit={(values, actions) => {
            this.createSupport(values).then(actions.resetForm);
          }}
          validationSchema={validationSchema}
          onReset={onClose}
        >
          {({ values, errors, handleChange, handleSubmit, handleReset }) => (
            <Fragment key="key">
              <DialogTitle>{textTitle}</DialogTitle>
              <DialogContent dividers>
                <Typography variant="subtitle1" gutterBottom>
                  {textSubtitle}
                </Typography>
                <Grid container>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        select
                        label="Tipo"
                        name="typeId"
                        value={values.typeId || " "}
                        onChange={handleChange}
                        fullWidth
                        disabled={fieldsDisabled}
                        error={errors.typeId}
                        helperText={errors.typeId}
                      >
                        {supportTypes.map(type => (
                          <MenuItem key={type.id} value={type.id}>
                            {type.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        select
                        name="reasonId"
                        label="Motivo"
                        value={values.reasonId || " "}
                        onChange={handleChange}
                        fullWidth
                        disabled={fieldsDisabled || !values.typeId}
                        error={errors.reasonId}
                        helperText={errors.reasonId}
                      >
                        {supportReasons
                          .filter(e => e.SupportType.id === values.typeId)
                          .map(reason => (
                            <MenuItem key={reason.id} value={reason.id}>
                              {reason.name}
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        select
                        name="location"
                        label="Sala"
                        value={values.location || " "}
                        onChange={handleChange}
                        fullWidth
                        disabled={fieldsDisabled}
                        error={errors.location}
                        helperText={errors.location}
                      >
                        <MenuItem value="A">2 - Prédio A</MenuItem>
                        <MenuItem value="A12">A12 - Bloco A sala 12</MenuItem>
                        <MenuItem value="C6">C6 - Bloco C sala 6</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        gutterBottom
                      >
                        Observações (Opcional):
                      </Typography>
                      <TextField
                        multiline
                        rows="4"
                        variant="outlined"
                        placeholder="Observação..."
                        onChange={handleChange}
                        fullWidth
                        name="description"
                        value={values.description || ""}
                        disabled={fieldsDisabled}
                        error={errors.typeIdescription}
                        helperText={errors.typeIdescription}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions style={{ padding: "30px 55px" }}>
                {this.getDialogActionsComponent({ handleSubmit, handleReset })}
              </DialogActions>
            </Fragment>
          )}
        </Formik>
      </WuiDialog>
    );
  }
}

ModalSupport.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  support: PropTypes.shape({
    statusId: PropTypes.number,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    id: PropTypes.number,
    SupportReason: PropTypes.shape({
      id: PropTypes.number.isRequired,
      SupportType: PropTypes.shape({
        id: PropTypes.number.isRequired
      })
    }),
    UserCreatedBy: PropTypes.shape({
      name: PropTypes.string.isRequired
    }),
    UpdatedByUser: PropTypes.shape({
      name: PropTypes.string.isRequired
    }),
    location: PropTypes.string,
    description: PropTypes.string
  }),
  alert: PropTypes.shape({
    show: PropTypes.func
  }).isRequired,
  refreshList: PropTypes.func.isRequired,
  updateSupportStatus: PropTypes.func.isRequired
};

ModalSupport.defaultProps = {
  support: {}
};

export default withAlert()(ModalSupport);
