import PropTypes from "prop-types";
import React, { Component } from "react";
import styled from "styled-components";
import {
  ExpansionPanelDetails as MuiExpansionPanelDetails,
  Typography
} from "@material-ui/core";

const ExpansionPanelDetails = styled(MuiExpansionPanelDetails)`
  padding: 20px 30px;
`;

// Usando um state Component pois foi preciso obter o nome desse componente no ExpansionRow
// eslint-disable-next-line react/prefer-stateless-function
class ExpansionRowDetails extends Component {
  render() {
    const { children } = this.props;
    return (
      <ExpansionPanelDetails>
        <Typography>{children}</Typography>
      </ExpansionPanelDetails>
    );
  }
}

ExpansionRowDetails.propTypes = {
  children: PropTypes.string
};

ExpansionRowDetails.defaultProps = {
  children: ""
};

export default ExpansionRowDetails;
