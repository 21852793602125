import styled from "styled-components";
import withTheme from "@material-ui/core/styles/withTheme";
import MuiDialogTitle from "@material-ui/core/DialogTitle";

const DialogTitle = styled(MuiDialogTitle)`
  h2 {
    font-size: 28px;
    color: ${props => props.theme.palette.secondary.main};
  }
`;

export default withTheme(DialogTitle);
