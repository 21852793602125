import React from "react";
import PropTypes from "prop-types";
import { Box, Icon, withTheme } from "@material-ui/core";
import styled from "styled-components";
import WuiGroup from "../Group";

const Group = styled(WuiGroup)`
  padding: 20px;
  cursor: ${({ hasClick }) => (hasClick ? "pointer" : "normal")};
`;

const GroupTitle = styled.p`
  font-family: Roboto;
  font-size: 19px;
  margin: 0;
  color: #7f7f7f;
  line-height: normal;
`;

const SubGroup = ({
  groupProps,
  children,
  title,
  icon,
  onClick,
  className
}) => {
  return (
    <div className={className}>
      <Group
        {...groupProps}
        hasClick={!!onClick}
        onClick={() => onClick && onClick()}
      >
        <Box display="flex" justifyContent="space-between">
          <GroupTitle>{title}</GroupTitle>
          {icon && <Icon>{icon}</Icon>}
        </Box>
        {children}
      </Group>
    </div>
  );
};

SubGroup.defaultProps = {
  groupProps: undefined,
  className: "",
  icon: undefined,
  onClick: undefined
};

SubGroup.propTypes = {
  groupProps: PropTypes.shape({
    ...Group.propTypes
  }),
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object.isRequired // Injected by withTheme()
};

export default withTheme(SubGroup);
