import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import MuiDialog from "@material-ui/core/Dialog";
import { Fab, CircularProgress } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import omit from "omit";

const CloseButton = styled(Fab)`
  background-color: #ec0000;
  color: #fff;
  right: -10px;
  top: -28px;
  position: absolute;
  :hover {
    background-color: #bc0000;
  }
`;

const Progress = styled(CircularProgress)`
  position: absolute;
  bottom: 34px;
  right: 24px;
`;

const Dialog = props => {
  const { children, onClose, closeButton, loading } = props;
  return (
    <MuiDialog {...omit(["closeButton", "loading"], props)}>
      {closeButton && (
        <CloseButton onClick={onClose} size="medium" disabled={loading}>
          <CloseIcon />
        </CloseButton>
      )}
      {children}
      {loading && <Progress size={34} />}
    </MuiDialog>
  );
};

Dialog.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  closeButton: PropTypes.bool,
  loading: PropTypes.bool
};

Dialog.defaultProps = {
  closeButton: false,
  loading: false
};

export default Dialog;
