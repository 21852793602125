const getUserName = () => {
  return localStorage.getItem("userName");
};

const setUserName = name => {
  return localStorage.setItem("userName", name);
};

const getUserPhoto = () => {
  return localStorage.getItem("userPhoto");
};

const setUserPhoto = photo => {
  return localStorage.setItem("userPhoto", photo);
};

const getSchoolName = () => {
  return "Colégio Waybee";
};

const setSchoolName = () => {
  return localStorage.getItem("userSchoolName");
};

const getToken = () => {
  return localStorage.getItem("token");
};

const setToken = value => {
  localStorage.setItem("token", value);
};

const setAvailableCampus = campus => {
  localStorage.setItem("availableCampuses", JSON.stringify(campus));
};

const getAvailableCampus = () => {
  return JSON.parse(localStorage.getItem("availableCampuses"));
};

const setCurrentCampus = val => {
  localStorage.setItem("currentCampus", val);
};

const getCurrentCampus = () => {
  return localStorage.getItem("currentCampus");
};

const getUserExternalId = () => {
  return localStorage.getItem("externalId");
};

const setUserExternalId = id => {
  return localStorage.setItem("externalId", id);
};

const getUserExternalInfo = () => {
  return JSON.parse(localStorage.getItem("userExternalInfo"));
};

const setUserExternalInfo = data => {
  return localStorage.setItem("userExternalInfo", JSON.stringify(data));
};

const getCodFilial = () => {
  return 1;
};

const getCodColigada = () => {
  const { hostname } = window.location;

  if (hostname === "diariodeclasse.vitalbrazilsp.com.br") return 3;

  if (hostname === "diariodeclasse.absabin.com.br") return 2;

  if (hostname === "diariodeclasse.albertsabin.com.br") return 1;

  return 1;
};

const getAnoLetivo = () => {
  return 2022;
};

const clear = () => {
  localStorage.clear();
};

const UserStore = {
  getUserName,
  setUserName,
  getUserPhoto,
  setUserPhoto,
  getSchoolName,
  setSchoolName,
  getToken,
  setToken,
  getAvailableCampus,
  setAvailableCampus,
  getCurrentCampus,
  setCurrentCampus,
  getUserExternalId,
  setUserExternalId,
  getUserExternalInfo,
  setUserExternalInfo,
  getCodColigada,
  getCodFilial,
  getAnoLetivo,
  clear
};

export default UserStore;
