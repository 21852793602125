export const defaultTheme = {
  color: {
    primary: {
      light: "#e0f6f2",
      main: "#00b297",
      dark: "#018675"
    },
    secondary: {
      light: "#5c8398",
      main: "#34657f",
      dark: "#00303b"
    },
    error: {
      light: "#ef3333",
      main: "#ec0000",
      dark: "#a50000"
    },
    default: {
      light: "#989898",
      main: "#7f7f7f",
      dark: "#585858",
      contrastText: "#fff"
    },
    background: {
      login: "rgba(52, 101, 127, 0.6)"
    }
  },
  images: {
    loginBackground:
      "https://storage.googleapis.com/waybee-production/39c8790b-8aef-4f54-948a-7ffb6ddd68f2/settings/class_diary/bg-login.jpg",
    logo:
      "https://storage.googleapis.com/waybee-production/39c8790b-8aef-4f54-948a-7ffb6ddd68f2/settings/class_diary/logo-waybee.svg",
    homeAsset:
      "https://storage.googleapis.com/waybee-production/39c8790b-8aef-4f54-948a-7ffb6ddd68f2/settings/class_diary/arte-notebook.png",
    profileHeaderAsset:
      "https://storage.googleapis.com/waybee-production/39c8790b-8aef-4f54-948a-7ffb6ddd68f2/settings/class_diary/cover-waybee.jpg"
  }
};

export const theme = defaultTheme;

export const updateTheme = newTheme => {
  theme.color = newTheme.color;
  theme.images = newTheme.images;
};
