import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import dot from "dot-object";
import globalResources from "../locale/default/global.json";

const prepareResource = resource => {
  const res = dot.dot(resource);
  const preparedResource = {};
  preparedResource.translation = res;
  return preparedResource;
};

const resources = {};
resources.global = prepareResource(globalResources);

i18n.use(initReactI18next).init({
  resources,
  debug: true,
  lng: "global",
  fallbackLng: "global",
  keySeparator: false,
  interpolation: {
    escapeValue: false // not needed for react as it escapes by default
  }
});

export default i18n;
