import React from "react";
import styled from "styled-components";
import { Box, Grid as MuiGrid } from "@material-ui/core";
import Row from "./components/Row";
import Title from "./components/Title";
import Cell from "./components/Cell";
import Text from "./components/Text";
import Button from "./components/Button";
import Checkbox from "./components/Checkbox";
import TextField from "./components/TextField";
import MultiSelect from "./components/MultiSelect";
import Status from "./components/Status";
import Group from "./components/Group";
import ExpansionRow from "./components/ExpansionRow";
import ExpansionRowDetails from "./components/ExpansionRowDetails";
import ExpansionRowActions from "./components/ExpansionRowActions";

const GridStyle = styled(MuiGrid)`
  width: calc(100% + 60px);
  min-height: calc(100vh - 600px);
  align-content: start;
  margin: 0 -30px -20px;
  border: solid #f2f2f2;
  border-width: 2px 0 2px 0;
`;

const TableGrid = props => {
  return (
    <Box>
      <GridStyle container {...props} />
    </Box>
  );
};

TableGrid.Row = Row;
TableGrid.Cell = Cell;
TableGrid.Group = Group;
TableGrid.Title = Title;
TableGrid.Text = Text;
TableGrid.Button = Button;
TableGrid.Checkbox = Checkbox;
TableGrid.TextField = TextField;
TableGrid.MultiSelect = MultiSelect;
TableGrid.Status = Status;
TableGrid.ExpansionRow = ExpansionRow;
TableGrid.ExpansionRowDetails = ExpansionRowDetails;
TableGrid.ExpansionRowActions = ExpansionRowActions;
export default TableGrid;
