import axios from "axios";
import UserStore from "./UserStore";
import CurrentCampusService from "../services/CurrentCampusService";

const $apiUrl = process.env.REACT_APP_API_URL;

const urlApi = {
  value: `${$apiUrl}api/`
};

const api = axios.create({
  baseURL: urlApi.value,
  responseType: "json",
  headers: { "Content-Type": "application/json" },
  timeout: 40000
});

api.interceptors.request.use(config => {
  const newConfig = config;

  const accessToken = UserStore.getToken();

  if (accessToken) {
    newConfig.headers.Authorization = accessToken;
  }

  newConfig.headers["app-identifier"] = "com.waybee.apps.diary";

  const campus = CurrentCampusService.get();
  if (campus) newConfig.headers["campus-identifier"] = campus; // eslint-disable-line no-param-reassign

  const codColigada = UserStore.getCodColigada();
  if (codColigada) newConfig.headers["codigo-coligada"] = codColigada;

  const codFilial = UserStore.getCodFilial();
  if (codFilial) newConfig.headers["codigo-filial"] = codFilial;

  const anoLetivo = UserStore.getAnoLetivo();
  if (anoLetivo) newConfig.headers["ano-letivo"] = anoLetivo;

  return newConfig;
});

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    // Executa redirecionamento a não ser que o erro 401 venha de uma tentativa de login.
    if (error && error.response && error.response.status === 401) {
      const { responseURL } = error.response.request;
      if (!responseURL.includes("Authenticate")) {
        UserStore.clear();
        window.location.reload();
      }
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export { api };
export default urlApi;
