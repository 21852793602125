import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { TextField as MuiTextField } from "@material-ui/core";
import Cell from "./Cell";

const TextFieldCell = styled(Cell)`
  padding: 0 15px;
`;

const StyledTextField = styled(MuiTextField)`
  .MuiOutlinedInput-root {
    height: 50px;
    margin-bottom: 0;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.error === false ? '#00b297' : 'none'}
  }
`;

const TextField = props => {
  return (
    <TextFieldCell {...props}>
      <StyledTextField
        {...props}
        variant="outlined"
        fullWidth
        inputProps={props}
      />
    </TextFieldCell>
  );
};

TextField.propTypes = {
  label: PropTypes.string
};

TextField.defaultProps = {
  label: ""
};

export default TextField;
