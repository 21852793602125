import PropTypes from "prop-types";
import React from "react";
import { Grid, Box } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import HeaderInfo from "../../waybee-ui/HeaderInfo/HeaderInfo";
import Group from "../../waybee-ui/Group";
import Divider from "../../waybee-ui/Divider";
import BackButton from "../../components/BackButton";
import CollapseSubGroup from "../../waybee-ui/CollapseSubGroup";
import MoreInfo from "./components/MoreInfo";
import MedicalInfo from "./components/MedicalInfo";

import StudentService from "../../services/StudentService";

const HeaderGrid = styled(Grid)`
  margin-bottom: -20px;
`;

const HeaderGroup = styled(Group)`
  padding-bottom: 0;
`;

const StudentNotes = ({ match }) => {
  const { classId, studentId } = match.params;

  const student = StudentService.getStudentById(studentId);

  return (
    <Grid container spacing={2}>
      <BackButton to={`/classes/${classId}/student/${studentId}`} />
      <HeaderGrid item xs={12}>
        <HeaderGroup>
          <Box mb={2}>
            <HeaderInfo
              name={student.name}
              info={`R.A: ${student.ra}`}
              photo={student.photo}
            />
          </Box>
          <Divider />
        </HeaderGroup>
      </HeaderGrid>
      <Grid item xs={12}>
        <CollapseSubGroup title="Ficha Médica">
          <MedicalInfo />
        </CollapseSubGroup>
        <CollapseSubGroup title="Mais observações sobre o aluno">
          <MoreInfo />
        </CollapseSubGroup>
      </Grid>
    </Grid>
  );
};

StudentNotes.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.array
  }).isRequired
};
export default withRouter(StudentNotes);
