import { api } from "../utils/api";
import UserStore from "../utils/UserStore";

const getSchedule = async (start, end, classId) => {
  const res = await api.get(
    `Academics/class/schedule${classId ? `/${classId}` : ""}`,
    { params: { start, end } }
  );
  return res.data;
};

const getDisciplines = async classId => {
  const res = await api.get(`ClassDiaryV1/class/${classId}/discipline`, {
    params: {
      externalData: UserStore.getUserExternalInfo()
    }
  });
  return res.data;
};

const getExams = async idTurmaDisc => {
  const res = await api.get(`ClassDiaryV1/grades/exams`, {
    params: {
      idTurmaDisc
    }
  });
  return res.data;
};

const getGrades = async (idTurmaDisc, idStep, idExam) => {
  const res = await api.get(`ClassDiaryV1/grades`, {
    params: {
      idTurmaDisc,
      idStep,
      idExam
    }
  });
  return res.data;
};

const saveGrades = async data => {
  const res = await api.post(`ClassDiaryV1/grades`, data);
  return res.data;
};

const saveGradesV2 = async data => {
  const res = await api.post(`ClassDiaryV1/grades-v2`, data);
  return res.data;
};

const getGradeEvaluations = async () => {
  return [
    {
      name: "Contínua"
    },
    {
      name: "Acumulada"
    },
    {
      name: "Avaliações"
    }
  ];
};

const getGradePeriods = async idTurmaDisc => {
  const res = await api.get("ClassDiaryV1/periods", {
    params: { idTurmaDisc }
  });
  return res.data;
};

const getGradesGridData = async (idTurmaDisc, period) => {
  const res = await api.get("ClassDiaryV1/grades-v2", {
    params: { idTurmaDisc, period }
  });
  return res.data;
};

const saveTest = async data => {
  const res = await api.post("ClassDiaryV1/test", data);
  return res.data;
};

export default {
  getSchedule,
  getDisciplines,
  getExams,
  getGrades,
  saveGrades,
  saveGradesV2,
  getGradePeriods,
  getGradeEvaluations,
  getGradesGridData,
  saveTest
};
