import React, { Component } from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { communicationStateType } from "../../../types";
import TableGrid from "../../../waybee-ui/TableGrid";
import CommunicationService from "../../../services/CommunicationService";
import {
  loadCommunications,
  loadCommunicationsCount,
  setCommunicationsPage
} from "../../../redux/actions";

class CommunicationList extends Component {
  componentDidMount() {
    const { size } = this.props;

    this.getCommunications();
    if (size !== "compact") {
      window.onscroll = () => {
        const container = document.querySelector("html");
        if (
          container.scrollTop + window.innerHeight >=
          container.offsetHeight
        ) {
          this.getMoreCommunications();
        }
      };
    }
  }

  getCommunications = async () => {
    const {
      communicationState,
      loadCommunications: onLoadCommunications,
      loadCommunicationsCount: onLoadCommunicationsCount,
      setCommunicationsPage: setPage
    } = this.props;
    const { communications, page } = communicationState;

    if (communications.length === 0) {
      const newCommunications = await CommunicationService.getCommunications(
        page
      );
      onLoadCommunications(newCommunications);
      setPage(page + 1);

      const res = await CommunicationService.getCommunicationsCount();
      onLoadCommunicationsCount(res.data);
    }
  };

  getMoreCommunications = async () => {
    const {
      communicationState,
      loadCommunications: onLoadCommunications,
      setCommunicationsPage: setPage
    } = this.props;
    const { page } = communicationState;
    let { communications } = communicationState;

    if (communications.length < communicationState.count) {
      const newCommunications = await CommunicationService.getCommunications(
        page
      );
      communications = communications.concat(newCommunications);
      onLoadCommunications(communications);
      setPage(page + 1);
    }
  };

  getTypeLabel = type => {
    switch (type) {
      case 1:
        return "Notícia";
      case 2:
        return "Evento";
      case 3:
        return "Informe";
      case 4:
        return "Teste";
      case 5:
        return "Feed de Rotina";
      default:
        return "Tipoe Inválido";
    }
  };

  render() {
    const { className, size, onClick, communicationState } = this.props;
    const { communications } = communicationState;

    return (
      <TableGrid className={className}>
        {communications.map(communication => (
          <TableGrid.Row
            key={communication.id}
            button
            highlight={
              communication.interaction && !communication.interaction.openedAt
            }
            onClick={() => onClick(communication)}
          >
            <TableGrid.Title
              {...(size === "compact"
                ? {
                    xs: 12
                  }
                : {
                    xs: 12,
                    sm: 4
                  })}
            >
              <Box {...(size === "compact" ? { ml: 1, mt: 1 } : {})}>
                {communication.title}
              </Box>
            </TableGrid.Title>
            <TableGrid.Text
              {...(size === "compact"
                ? {
                    xs: 12
                  }
                : {
                    xs: 12,
                    sm: 4,
                    align: "right"
                  })}
            >
              <Box {...(size === "compact" ? { ml: 1 } : {})}>
                {moment(communication.date).format("DD/MM/YYYY HH:mm")}
              </Box>
            </TableGrid.Text>
            <TableGrid.Text
              {...(size === "compact"
                ? {
                    xs: 12
                  }
                : {
                    xs: 12,
                    sm: 4,
                    align: "right"
                  })}
            >
              <Box {...(size === "compact" ? { m: 1, pr: 1.5 } : {})}>
                {this.getTypeLabel(communication.type)}
              </Box>
            </TableGrid.Text>
          </TableGrid.Row>
        ))}
      </TableGrid>
    );
  }
}

CommunicationList.propTypes = {
  size: PropTypes.oneOf(["compact", "normal"]),
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  communicationState: communicationStateType.isRequired, // injected by react-redux mapStateToProps connect()
  loadCommunications: PropTypes.func.isRequired, // injected by react-redux mapDispatchToProps connect()
  loadCommunicationsCount: PropTypes.func.isRequired, // injected by react-redux mapDispatchToProps connect()
  setCommunicationsPage: PropTypes.func.isRequired // injected by react-redux mapDispatchToProps connect()
};

CommunicationList.defaultProps = {
  size: "normal",
  className: ""
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { loadCommunications, loadCommunicationsCount, setCommunicationsPage },
    dispatch
  );

const mapStateToProps = state => ({
  communicationState: state.communicationState
});

export default connect(mapStateToProps, mapDispatchToProps)(CommunicationList);
