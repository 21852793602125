import React, { Component } from "react";
import { Box, Grid } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { communicationStateType } from "../../types";
import Group from "../../waybee-ui/Group";
import Heading from "../../waybee-ui/Heading";
import CommunicationList from "./components/CommunicationList";
import CommunicationDialog from "./components/CommunicationDialog";
import CommunicationService from "../../services/CommunicationService";
import markAsRead from "../../utils/markAsRead";
import { loadCommunications } from "../../redux/actions";

class CommunicationDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      selectedCommunication: null
    };
  }

  componentDidMount() {
    const { match, communicationState } = this.props;
    const id = Number(match.params.id);
    if (id) {
      const selectedCommunication = communicationState.communications.find(
        comm => comm.id === id
      );
      if (selectedCommunication)
        this.setState({ openDialog: true, selectedCommunication });
      else this.getCommunication(id);
    }
  }

  getCommunication = async id => {
    const communication = await CommunicationService.getCommunication(id);
    this.setState({ openDialog: true, selectedCommunication: communication });
  };

  onClickCommunication = async communication => {
    this.setState({
      openDialog: true,
      selectedCommunication: communication
    });

    if (communication.interaction && !communication.interaction.openedAt) {
      const res = await CommunicationService.markAsRead(communication.id);
      markAsRead(res.data);
    }
  };

  updateCommunication = communication => {
    const {
      loadCommunications: setCommunications,
      communicationState
    } = this.props;

    const updatedCommunications = communicationState.communications.map(
      comm => {
        if (comm.id === communication.id) return communication;
        return comm;
      }
    );

    setCommunications(updatedCommunications);
    this.setState({ selectedCommunication: communication });
  };

  render() {
    const { openDialog, selectedCommunication } = this.state;
    const { communicationState } = this.props;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Group>
            <Box>
              <Heading level={1} icon="history" gutterBottom>
                Histórico de Comunicação (
                {communicationState.count}
                )
              </Heading>

              <CommunicationList onClick={this.onClickCommunication} />

              {selectedCommunication && (
                <CommunicationDialog
                  open={openDialog}
                  communication={selectedCommunication}
                  onClose={() => this.setState({ openDialog: false })}
                  updateCommunication={this.updateCommunication}
                />
              )}
            </Box>
          </Group>
        </Grid>
      </Grid>
    );
  }
}

CommunicationDashboard.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.any
    })
  }).isRequired,
  communicationState: communicationStateType.isRequired,
  loadCommunications: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ loadCommunications }, dispatch);

const mapStateToProps = state => ({
  communicationState: state.communicationState
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CommunicationDashboard));
