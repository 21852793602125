import React from "react";
import styled from "styled-components";
import { Grid } from "@material-ui/core";

const GroupStyle = styled(Grid)`
  display: flex;
  align-items: center;
  align-content: center;
  min-height: 96px;
`;

const Group = props => {
  return <GroupStyle container item {...props} />;
};

export default Group;
