import React, { Component } from "react";
import PropTypes from "prop-types";
import Styled from "styled-components";
import { Container as MuiContainer } from "@material-ui/core";
import { withRouter } from "react-router-dom";

import FormLogin from "./FormLogin";
import ForgotPassword from "./ForgotPassword";

import UserService from "../../services/UserService";

const Background = Styled.div`
  min-height: 100vh;
  width: 100%;
  background-color: #ddd;
  background-image: url(${({ theme }) => theme.images.loginBackground});
  background-size: cover;
  background-position: center;
`;

const Fade = Styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.color.background.login};
`;

const Logo = Styled.div`
  background-image: url(${({ theme }) => theme.images.logo});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 27vh;
  max-width: 290px;
  margin: 0 auto;
  position: sticky;
  z-index: 10;
`;

const Container = Styled(MuiContainer)`
  box-sizing: content-box;
  max-width: 640px;
  padding-bottom: 20px;
`;

const Paper = Styled.div`
  position: relative;
  background-color: #fff;
  border-radius: 8px;
  margin-top: 20px;
  padding: 44px 74px 40px;
  height: 426px;
  box-sizing: border-box;

  .main-button {
    max-width: 300px;
    margin: 0 auto;
    display: block;
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
  }

`;

const AppVersion = Styled.span`
  font-size: 16px;
  font-weight: 300;
  color: rgba(0,0,0,.5);
  position: absolute;
  bottom: 12px;
  right: 12px;
`;

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      loading: false,
      forgotPassword: false,
      errors: {
        email: "",
        password: ""
      }
    };
  }

  validateForm = async () => {
    const { email } = this.state;
    if (!(await this.validateEmail(email))) {
      throw new Error("Email inválido");
    }
  };

  validateEmail = async email => {
    const cleanEmail = email && email.replace("#backdoor", "");
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(cleanEmail).toLowerCase());
  };

  openForgotPassword = () => {
    this.setState({ forgotPassword: true });
  };

  closeForgotPassword = () => {
    this.setState({ forgotPassword: false });
  };

  handleFieldChange = prop => event => {
    this.setState({ [prop]: event.target.value });
  };

  handleSubmit = async () => {
    const { email, password } = this.state;
    const { getCampuses } = this.props;
    this.setState({ loading: true });

    try {
      await this.validateForm();
      await UserService.login(email, password);
      const { history } = this.props;
      history.push("");
      getCampuses();
    } catch (err) {
      console.log("### erro");
      console.log(err);
      const { message } = err;
      if (message === "Senha incorreta") {
        this.setState({ errors: { password: "Senha incorreta", email: "" } });
      } else {
        this.setState({ errors: { password: "", email: message } });
      }
      this.setState({ loading: false });
    }
  };

  render() {
    const { email, password, forgotPassword, loading, errors } = this.state;

    return (
      <Background>
        <Fade>
          <Logo />
          <Container component="main">
            <Paper>
              {!forgotPassword ? (
                <FormLogin
                  onSubmit={this.handleSubmit}
                  handleChange={this.handleFieldChange}
                  email={email}
                  password={password}
                  openForgotPassword={this.openForgotPassword}
                  loading={loading}
                  errors={errors}
                />
              ) : (
                <ForgotPassword
                  closeForgotPassword={this.closeForgotPassword}
                />
              )}
              <AppVersion>{process.env.REACT_APP_VERSION}</AppVersion>
            </Paper>
          </Container>
        </Fade>
      </Background>
    );
  }
}

Login.propTypes = {
  getCampuses: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withRouter(Login);
