import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Formik, FastField } from "formik";
import * as Yup from "yup";
import DateFnsUtils from "@date-io/date-fns";
import ptBrLocale from "date-fns/locale/pt-BR";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  MenuItem,
  Typography
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker
} from "@material-ui/pickers";
import Dialog from "../../../waybee-ui/Dialog/Dialog";
import Heading from "../../../waybee-ui/Heading/Heading";
import Button from "../../../waybee-ui/Button";

const getCourses = () => {
  return [
    { id: 1, name: "Geografia 1" },
    { id: 2, name: "Geografia 2" },
    { id: 3, name: "Geografia 3" }
  ];
};

const NoteDialog = ({
  selectedNote,
  open,
  handleClose,
  handleCreate,
  handleUpdate
}) => {
  const courses = getCourses();

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(),
    course: Yup.mixed().required(),
    date: Yup.mixed().required(),
    time: Yup.mixed().required(),
    description: Yup.string().required()
  });

  const isNewNote = !selectedNote;

  let initialValues;
  if (isNewNote) {
    initialValues = {
      title: "",
      course: "",
      date: null,
      time: null,
      description: ""
    };
  } else {
    initialValues = {
      title: selectedNote.title,
      course: selectedNote.course.id,
      date: moment(selectedNote.date).toDate(),
      time: moment(selectedNote.date).toDate(),
      description: selectedNote.description
    };
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale}>
      <Formik
        isInitialValid={false}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={values => {
          if (isNewNote) {
            handleCreate(values);
          } else {
            handleUpdate(values);
          }
          handleClose();
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue
        }) => (
          <Dialog open={open} onClose={handleClose} fullWidth>
            <DialogTitle>
              {isNewNote ? "Nova Anotação" : "Editar Anotação"}
            </DialogTitle>
            <DialogContent>
              <Heading>Anotação referente à aula.</Heading>
              <Grid container>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FastField
                      component={TextField}
                      name="title"
                      id="title"
                      label="Título da Anotação"
                      value={values.title || " "}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      error={errors.title}
                      helperText={errors.title}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      select
                      label="Disciplina"
                      name="course"
                      id="course"
                      value={values.course}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      error={errors.course}
                      helperText={errors.course}
                    >
                      {courses.map(course => (
                        <MenuItem key={course.id} value={course.id}>
                          {course.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={6}>
                    <FastField
                      component={DatePicker}
                      label="Data da Anotação"
                      name="date"
                      id="date"
                      format="dd/MM/yyyy"
                      autoOk
                      fullWidth
                      disablePast
                      value={values.date}
                      onChange={value => {
                        setFieldValue("date", value);
                      }}
                      onBlur={handleBlur}
                      error={errors.date}
                      helperText={errors.date}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <FastField
                      component={TimePicker}
                      label="Horário da Anotação"
                      id="time"
                      name="time"
                      format="HH:mm"
                      autoOk
                      fullWidth
                      ampm={false}
                      disablePast
                      value={values.time}
                      onChange={value => {
                        setFieldValue("time", value);
                      }}
                      onBlur={handleBlur}
                      error={errors.time}
                      helperText={errors.time}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      gutterBottom
                    >
                      Anotação:
                    </Typography>
                    <FastField
                      component={TextField}
                      multiline
                      rows="5"
                      variant="outlined"
                      placeholder="Anotação..."
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      name="description"
                      id="description"
                      value={values.description}
                      error={errors.typeIdescription}
                      helperText={errors.typeIdescription}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClose}
                type="reset"
              >
                Voltar
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={handleSubmit}
              >
                {isNewNote ? "Enviar" : "Concluir"}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
    </MuiPickersUtilsProvider>
  );
};

NoteDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleCreate: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedNote: PropTypes.shape({
    course: PropTypes.shape({
      id: PropTypes.any
    }),
    date: PropTypes.any,
    description: PropTypes.any,
    title: PropTypes.any
  })
};

NoteDialog.defaultProps = {
  selectedNote: null
};

export default NoteDialog;
