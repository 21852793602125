import {
  LOAD_NOTIFICATIONS,
  LOAD_NOTIFICATIONS_COUNT,
  SET_NOTIFICATIONS_PAGE,
  SET_NOTIFICATIONS_UNREAD
} from "../actions/actionTypes";

const INITIAL_STATE = {
  notifications: [],
  count: 0,
  page: 0,
  unread: 0
};

export const notificationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload
      };
    case LOAD_NOTIFICATIONS_COUNT:
      return {
        ...state,
        count: action.payload
      };
    case SET_NOTIFICATIONS_PAGE:
      return {
        ...state,
        page: action.payload
      };
    case SET_NOTIFICATIONS_UNREAD:
      return {
        ...state,
        unread: action.payload
      };
    default:
      return state;
  }
};
