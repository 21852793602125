import React from "react";
import PropTypes from "prop-types";
import {
  ListItem as MuiListItem,
  ListItemIcon as MuiListItemIcon
} from "@material-ui/core";
import styled from "styled-components";
import MuiBadge from "@material-ui/core/Badge";

const Container = styled(MuiListItem).withConfig({
  shouldForwardProp: prop => !["badgeNumber"].includes(prop)
})`
  position: relative;
  height: 70px;
  box-sizing: border-box;
  background-color: #fff;
  border-bottom: solid 1px #f2f2f2;
`;

const ListItemIcon = styled(MuiListItemIcon)`
  color: ${({ theme }) => theme.color.primary.dark};
  min-width: 52px;
  svg {
    width: 1.2em;
    height: 1.2em;
  }
`;

const ListItemText = styled.span`
  color: #7e7e7e;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.25;
  letter-spacing: normal;
`;

const Badge = styled(MuiBadge)`
  position: absolute;
  right: 22px;
  top: 22px;
`;

const ListItem = props => {
  const { button, icon, children, badgeNumber } = props;

  return (
    <Container button={button} {...props}>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText>{children}</ListItemText>
      {!!badgeNumber && <Badge badgeContent={badgeNumber} color="error" />}
    </Container>
  );
};

ListItem.propTypes = {
  ...MuiListItem.propTypes,
  icon: PropTypes.node,
  button: PropTypes.bool,
  badgeNumber: PropTypes.number
};

ListItem.defaultProps = {
  icon: null,
  button: true,
  badgeNumber: 0
};

export default ListItem;
