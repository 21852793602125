import PropTypes from "prop-types";
import React, { Component } from "react";
import { Grid, Box } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { notificationStateType } from "../../types";
import Heading from "../../waybee-ui/Heading/Heading";
import Group from "../../waybee-ui/Group";
import TableGrid from "../../waybee-ui/TableGrid";
import NotificationService from "../../services/NotificationService";
import {
  loadNotifications,
  loadNotificationsCount,
  setNotificationsPage
} from "../../redux/actions";
import markAsRead from "../../utils/markAsRead";

class Notifications extends Component {
  componentDidMount() {
    this.getNotifications();

    window.onscroll = () => {
      const container = document.querySelector("html");
      if (container.scrollTop + window.innerHeight >= container.offsetHeight) {
        this.getMoreNotifications();
      }
    };
  }

  getNotifications = async () => {
    const {
      notificationState,
      loadNotifications: onLoadNotifications,
      loadNotificationsCount: onLoadNotificationsCount,
      setNotificationsPage: setPage
    } = this.props;
    const { notifications, page } = notificationState;

    if (notifications.length === 0) {
      const newNotifications = await NotificationService.getNotifications(page);
      onLoadNotifications(newNotifications);
      setPage(page + 1);

      const res = await NotificationService.getNotificationCount();
      onLoadNotificationsCount(res.data);
    }
  };

  getMoreNotifications = async () => {
    const {
      notificationState,
      loadNotifications: onLoadNotifications,
      setNotificationsPage: setPage
    } = this.props;
    const { page, count } = notificationState;
    let { notifications } = notificationState;

    if (notifications.length < count) {
      const newNotifications = await NotificationService.getNotifications(page);
      notifications = notifications.concat(newNotifications);
      onLoadNotifications(notifications);
      setPage(page + 1);
    }
  };

  getIconByType = type => {
    switch (type) {
      case "Class":
        return "group_work";
      case "Support":
        return "error_outline";
      case "Communication":
        return "mail_outline";
      default:
        return "";
    }
  };

  async handleNotificationClick(notification) {
    const { history } = this.props;

    if (!notification.opened) {
      const res = await NotificationService.markAsRead(notification.id);
      markAsRead(res.data);
    }

    if (notification.type === "Communication") {
      history.push(`/communication/${notification.modelId}`);
    }
  }

  render() {
    const { notificationState } = this.props;
    const { notifications, count } = notificationState;

    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Group>
              <Box>
                <Heading level={1} icon="history" gutterBottom>
                  {`Histórico de Notificações (${count})`}
                </Heading>
                <TableGrid>
                  {notifications.map(notification => (
                    <TableGrid.Row
                      photo="#"
                      size="small"
                      key={notification.id}
                      icon={this.getIconByType(notification.type)}
                      button
                      highlight={!notification.opened}
                      onClick={() => this.handleNotificationClick(notification)}
                    >
                      <TableGrid.Title xs={7}>
                        {notification.title}
                      </TableGrid.Title>
                      <TableGrid.Text xs={5} align="right">
                        Recebida em
                        {" "}
                        {moment(notification.createdAt).format(
                          "DD/MM/YYYY [às] hh:mm"
                        )}
                      </TableGrid.Text>
                    </TableGrid.Row>
                  ))}
                </TableGrid>
              </Box>
            </Group>
          </Grid>
        </Grid>
      </div>
    );
  }
}

Notifications.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  notificationState: notificationStateType.isRequired,
  loadNotifications: PropTypes.func.isRequired,
  loadNotificationsCount: PropTypes.func.isRequired,
  setNotificationsPage: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadNotifications,
      loadNotificationsCount,
      setNotificationsPage
    },
    dispatch
  );

const mapStateToProps = state => ({
  notificationState: state.notificationState
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Notifications));
