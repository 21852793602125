import React from "react";
import PropTypes from "prop-types";
import Styled from "styled-components";
import { Typography } from "@material-ui/core";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";

import Button from "../../waybee-ui/Button";

const Title = Styled.h3`
  font-size: 28px;
  font-weight: 300;
  color: #00303b;
  margin: 16px 0 20px;
  line-height: 1.43;
`;

function ForgotPassword(props) {
  const { closeForgotPassword } = props;

  return (
    <>
      <Button
        color="secondary"
        startIcon={<ArrowBackIcon />}
        size="large"
        to="/"
        onClick={closeForgotPassword}
      />
      <Title level={6} gutterBottom>
        Esqueci a senha
      </Title>
      <Typography variant="subtitle1">
        Por motivos de segurança, para lembrar ou alterar a senha de acesso
        entre em contato com o número (11) 3136-0552 ou envie um e-mail para
        suporte@waybee.com.br.
      </Typography>
      <Button
        className="main-button"
        onClick={closeForgotPassword}
        type="submit"
        fullWidth
        variant="contained"
        color="secondary"
      >
        Voltar
      </Button>
    </>
  );
}

ForgotPassword.propTypes = {
  closeForgotPassword: PropTypes.func.isRequired
};

export default ForgotPassword;
