import styled from "styled-components";
import AppHeader from "../AppHeader";

const ToolBarAppHeader = styled(AppHeader)`
  @media (min-width: 960px) {
    margin-left: ${props => props.sidebarSize}px;
    width: calc(100% - ${props => props.sidebarSize}px);
  }
`;

ToolBarAppHeader.defaultProps = {
  sidebarSize: 0
};

export default ToolBarAppHeader;
