import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";
import { withAlert } from "react-alert";
import _ from "lodash";
import DialogTitle from "../../waybee-ui/Dialog/DialogTitle";
import Dialog from "../../waybee-ui/Dialog";
import ClassService from "../../services/ClassService";
import GradesList from "./GradesList";

const TitleDetails = styled.p`
  font-size: 16px;
  margin: 0;
  color: rgba(0, 0, 0, 0.5);
`;

const LoadContainer = styled.div`
  width: 100%;
  text-align: center;
  padding: 32px 8px;
`;

class GradesDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      students: [],
      loadingStudents: false,
      loadingSave: false
    };
  }

  componentDidMount() {
    const { selectedExam } = this.props;
    if (selectedExam) this.getGrades();
  }

  componentDidUpdate(prevProps) {
    const { selectedExam } = this.props;

    if (selectedExam && selectedExam !== prevProps.selectedExam)
      this.getGrades();
  }

  getGrades = async () => {
    const { alert, selectedExam } = this.props;

    this.setState({ loadingStudents: true });

    try {
      const studentGrades = await ClassService.getGrades(
        selectedExam.idTurmaDisc,
        selectedExam.idStep,
        selectedExam.idExam
      );
      this.setState({ students: studentGrades });
    } catch (e) {
      console.error(e);
      alert.show("Ocorreu um erro ao buscar as notas", { title: "Erro" });
    } finally {
      this.setState({ loadingStudents: false });
    }
  };

  getInitialValues = () => {
    const { students } = this.state;
    const initialState = {
      grades: {},
      comments: {}
    };
    students.forEach(student => {
      initialState.grades[student.ra] = student.grade;
      initialState.comments[student.ra] = student.comment;
    });
    return initialState;
  };

  saveGrades = async values => {
    const { students } = this.state;
    const { alert, selectedExam } = this.props;

    this.setState({ loadingSave: true });

    const oldGrades = students
      .filter(student => student.grade)
      .map(student => ({
        studentId: student.ra,
        value: student.grade,
        comment: student.comment
      }));

    const formattedValues = Object.keys(values.grades)
      .map(studentId => ({
        studentId,
        value: _.isNil(values.grades[studentId])
          ? undefined
          : Number(values.grades[studentId]),
        comment: values.comments[studentId] || undefined
      }))
      .filter(student => !_.isNil(student.value));

    const deletedGrades = _.differenceWith(
      oldGrades,
      formattedValues,
      (a, b) => a.studentId === b.studentId
    ).map(grades => ({
      ...grades,
      value: "",
      comment: ""
    }));

    const newValues = _.differenceWith(formattedValues, oldGrades, _.isEqual);
    const grades = newValues.concat(deletedGrades);

    const data = {
      idExam: selectedExam.idExam,
      idStep: selectedExam.idStep,
      typeStep: selectedExam.typeStep,
      idTurmaDisc: selectedExam.idTurmaDisc,
      grades
    };

    try {
      await ClassService.saveGrades(data);
      this.getGrades(selectedExam);
    } catch (e) {
      console.error(e);
      alert.show("Ocorreu um erro ao salvar as notas", { title: "Erro" });
    } finally {
      this.setState({ loadingSave: false });
    }
  };

  render() {
    const { onClose, selectedExam } = this.props;
    const { students, loadingStudents, loadingSave } = this.state;

    if (!selectedExam) return null;
    return (
      <>
        <Dialog open onClose={onClose} maxWidth="md" fullWidth closeButton>
          <DialogTitle>
            {selectedExam.name}
            <TitleDetails>
              Valor: 
              {' '}
              {selectedExam.value}
              {' '}
              - Média: 
              {' '}
              {selectedExam.average}
            </TitleDetails>
          </DialogTitle>

          {loadingStudents ? (
            <LoadContainer>
              <CircularProgress />
            </LoadContainer>
          ) : (
            <GradesList
              students={students}
              onSubmit={this.saveGrades}
              maxValue={selectedExam.value}
              average={selectedExam.average}
              defaultValues={this.getInitialValues()}
            />
          )}
        </Dialog>
        <Dialog
          onClose={() => this.setState({ loadingSave: false })}
          open={loadingSave}
        >
          <DialogTitle>Salvando</DialogTitle>
          <LoadContainer>
            <CircularProgress />
          </LoadContainer>
        </Dialog>
      </>
    );
  }
}

GradesDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  selectedExam: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.number,
    average: PropTypes.number,
    idTurmaDisc: PropTypes.number,
    idStep: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
    idExam: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
    typeStep: PropTypes.string
  }).isRequired,
  alert: PropTypes.shape({
    show: PropTypes.func
  }).isRequired
};

GradesDialog.defaultProps = {};

export default withAlert()(GradesDialog);
