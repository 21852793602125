import styled from "styled-components";
import withTheme from "@material-ui/core/styles/withTheme";
import MuiDialogContent from "@material-ui/core/DialogContent";

const DialogContent = styled(MuiDialogContent)`
  font-size: 18px;
  color: ${props => props.theme.palette.secondary.dark};
`;

export default withTheme(DialogContent);
