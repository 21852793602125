import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import Calendar from "../../waybee-ui/Calendar/Calendar";
import BackButton from "../../components/BackButton";
import ClassService from "../../services/ClassService";

function ClassSchedule(props) {
  const { match } = props;

  const classId = match.params.id;

  const options = {
    views: {
      timeGridWeek: {
        titleFormat: { month: "long", day: "numeric" },
        slotLabelFormat: { hour: "numeric", minute: "2-digit" },
        columnHeaderFormat: { weekday: "short", day: "numeric" }
      },
      timeGridDay: {
        titleFormat: { month: "long", day: "numeric", weekday: "short" }
      }
    },
    allDaySlot: false,
    events: async (info, successCallback, failureCallback) => {
      try {
        const mock = await ClassService.getSchedule(
          info.startStr,
          info.endStr,
          classId
        );
        successCallback(mock);
      } catch (e) {
        failureCallback(e);
      }
    },
    defaultView: "timeGridWeek"
  };

  return (
    <Grid container spacing={2}>
      <BackButton to={`/classes/${classId}`} />
      <Calendar options={options} />
    </Grid>
  );
}

ClassSchedule.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.any
    })
  }).isRequired
};

export default withRouter(ClassSchedule);
