import _ from "lodash";
import { api } from "../utils/api";
import UserStore from "../utils/UserStore";

const isAuthenticated = () => {
  return !!UserStore.getToken();
};

const authenticate = (email, password) => {
  const params = { email, password };
  return api
    .post("WaybeeUsers/Authenticate", params)
    .then(response => response.data.response);
};

const logout = () => {
  const token = UserStore.getToken();
  if (!token) {
    return true;
  }
  const params = { access_token: token };
  return api
    .post("WaybeeUsers/logout", { params })
    .finally(() => UserStore.clear());
};

const getSchoolUser = userId => {
  const filter = {
    where: {
      and: [{ id: userId }]
    },
    include: [
      {
        relation: "userCampuses",
        limit: 1,
        where: { isDeleted: false, active: true },
        scope: {
          include: {
            relation: "Campus",
            scope: {
              include: {
                relation: "Schools"
              }
            }
          }
        }
      }
    ]
  };
  return api
    .get("WaybeeUsers", { params: { filter } })
    .then(response => response.data[0]);
};

const getLoginInfo = async userExternalId => {
  const res = await api.get("ClassDiaryV1/user/getLoginInfo", {
    params: { id: userExternalId }
  });
  return res.data;
};

const login = async (email, password) => {
  let userId = 0;
  try {
    const tokenData = await authenticate(email, password);
    UserStore.setToken(tokenData.id);
    userId = tokenData.userId;
  } catch (err) {
    if (!err.response) {
      throw new Error(`Falha ao conectar no servidor: ${err.message}` || "");
    }
    const { error } = err.response.data;

    throw new Error(error.message || "Erro ao realizar o login");
  }

  try {
    const user = await getSchoolUser(userId);
    UserStore.setUserName(user.name);
    UserStore.setAvailableCampus(user.userCampuses);
    const userCampus = user.userCampuses[0];
    if (userCampus) {
      UserStore.setCurrentCampus(userCampus.Campus.id);
      UserStore.setUserExternalId(userCampus.userExternalId);
      const externalUserInfo = await getLoginInfo(userCampus.userExternalId);
      UserStore.setUserExternalInfo(externalUserInfo.externalData);
      UserStore.setUserPhoto(externalUserInfo.photo);
    }
  } catch (err) {
    logout();
    const message = _.get(
      err,
      "response.data.error.message",
      "Usuário não cadastrado no sistema"
    );
    throw new Error(message);
  }
};

const getUserCampus = async () => {
  const res = await UserStore.getAvailableCampus();
  return res.map(userCampus => ({
    id: userCampus.Campus.id,
    name: userCampus.Campus.name
  }));
};

const getMySessionInfos = async () => {
  return api.get("WaybeeUsers/mySessionInfo");
};

const UserService = {
  isAuthenticated,
  authenticate,
  login,
  logout,
  getUserCampus,
  getMySessionInfos
};

export default UserService;
