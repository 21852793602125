import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

const ConceptContainer = styled.div`
  width: 234px;
  padding: 15px;
  display: flex;
  align-items: center;

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${({ theme }) => theme.color.primary.light};
    `}

  ${({ hasClick }) =>
    hasClick &&
    css`
      &:hover {
        background-color: #c8c8c8;
        cursor: pointer;
      }
    `}
`;
const ConceptColor = styled.div`
  border: 1px solid #c8c8c8;
  flex-shrink: 0;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  background-color: ${({ color }) => color};
`;
const ConceptLabel = styled.div`
  margin-left: 8px;
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
`;

const Concept = ({ color, label, onClick, selected }) => {
  const handleClick = () => {
    if (onClick) onClick();
  };

  return (
    <ConceptContainer
      selected={selected}
      onClick={handleClick}
      hasClick={!!onClick}
    >
      <ConceptColor color={color} />
      <ConceptLabel>{label}</ConceptLabel>
    </ConceptContainer>
  );
};

Concept.defaultProps = {
  onClick: undefined,
  selected: false
};

Concept.propTypes = {
  color: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  selected: PropTypes.bool
};

export default Concept;
