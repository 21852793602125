import { Store } from "../redux/store";
import {
  loadNotifications,
  setNotificationsUnread,
  loadCommunications,
  setCommunicationsUnread
} from "../redux/actions";

const markAsRead = readItems => {
  const state = Store.getState();

  if (readItems.communications.length) {
    const { communicationState } = state;

    // Ajusta badge de comunicações
    Store.dispatch(
      setCommunicationsUnread(
        communicationState.unread - readItems.communications.length
      )
    );

    // Atualiza lista de comunicados com infomação de lido
    const newCommunications = communicationState.communications.map(
      stateCommunication => {
        const communicationToRead = readItems.communications.find(
          readCommunication =>
            readCommunication.communicationSubscriberId ===
              stateCommunication.subscriberId &&
            readCommunication.userId === stateCommunication.userId
        );
        if (communicationToRead)
          return {
            ...stateCommunication,
            interaction: {
              ...stateCommunication.interaction,
              openedAt: new Date().toISOString()
            }
          };

        return stateCommunication;
      }
    );

    Store.dispatch(loadCommunications(newCommunications));
  }
  if (readItems.notifications.length) {
    const { notificationState } = state;

    // Ajusta badge de notificações
    Store.dispatch(
      setNotificationsUnread(
        notificationState.unread - readItems.notifications.length
      )
    );

    // Atualiza lista de notificações com informação de lido
    const newNotifications = notificationState.notifications.map(
      stateNotification => {
        const notificationToRead = readItems.notifications.find(
          readNotifications =>
            stateNotification.subscriber.id === readNotifications.id
        );

        if (notificationToRead)
          return {
            ...stateNotification,
            opened: true
          };
        return stateNotification;
      }
    );
    Store.dispatch(loadNotifications(newNotifications));
  }
};

export default markAsRead;
