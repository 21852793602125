import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import {
  FormControl as MuiFormControl,
  InputLabel,
  Select,
  Chip as MuiChip,
  Input,
  MenuItem as MuiMenuItem,
  useTheme
} from "@material-ui/core";
import Cell from "./Cell";

const MultiSelectCell = styled(Cell)`
  padding: 7px 15px;
`;

const FormControl = styled(MuiFormControl)`
  max-width: 180px;
`;

const MenuItem = styled(MuiMenuItem)`
  z-index: 10;
  color: #6a6a6a;
  height: 54px;
  padding-left: 10px;
  margin: 0;
  svg {
    margin-right: 10px;
    font-size: 21px;
  }
  &.Mui-selected {
    background-color: ${() => {
      const theme = useTheme();
      return theme.palette.primary.light;
    }};
    :hover {
      opacity: 0.8;
      background-color: ${() => {
        const theme = useTheme();
        return theme.palette.primary.light;
      }};
    }
  }
`;

const Chip = styled(MuiChip)`
  display: flex;
  width: calc(100% - 5px);
  margin-top: 6px;
  color: #6a6a6a;
  background-color: #e8e8e8;
  justify-content: flex-start;

  svg {
    font-size: 20px;
  }
  .MuiChip-label {
    width: 100%;
  }
  .MuiChip-deleteIcon {
    height: 20px;
    color: #6a6a6a;
  }
`;

const MultiSelect = props => {
  const { children, id, name, label, value: values, onChange } = props;
  return (
    <MultiSelectCell {...props}>
      <FormControl fullWidth>
        <InputLabel id={`label-${id}`}>{label}</InputLabel>
        <Select
          {...props}
          multiple
          value={values}
          onChange={onChange}
          input={<Input />}
          renderValue={selected => (
            <div>
              {selected.map(selectedValue => (
                <Chip
                  onDelete={() => {
                    const value = values.filter(e => e.id !== selectedValue.id);
                    const returnEvent = { target: {} };
                    returnEvent.target.value = value;
                    returnEvent.target.name = name;
                    returnEvent.target.id = id;
                    onChange(returnEvent);
                  }}
                  key={selectedValue.id}
                  label={selectedValue.name}
                  icon={selectedValue.icon}
                  onMouseDown={e => e.stopPropagation()}
                />
              ))}
            </div>
          )}
        >
          {children.map(e => (
            <MenuItem {...e.props} />
          ))}
        </Select>
      </FormControl>
    </MultiSelectCell>
  );
};

MultiSelect.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      filter: PropTypes.func
    }).isRequired
  ).isRequired
};

MultiSelect.defaultProps = {
  label: "",
  id: "",
  name: ""
};

export default MultiSelect;
