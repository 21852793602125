import React from "react";
import { List as MuiList } from "@material-ui/core";

const List = (props) => <MuiList {...props} />;

List.propTypes = {
  ...MuiList.propTypes
};

export default List;
