import React from "react";
import PropTypes from "prop-types";
import AlertDialog from "../waybee-ui/Dialog/AlertDialog";

const AlertTemplate = ({ options, message, close }) => (
  <AlertDialog title={options.title} message={message} onClose={close} open />
);

AlertTemplate.propTypes = {
  options: PropTypes.shape({
    title: PropTypes.string.isRequired
  }).isRequired,
  message: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired
};

export default AlertTemplate;
