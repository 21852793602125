import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import withTheme from "@material-ui/core/styles/withTheme";
import Grid from "@material-ui/core/Grid";

const Container = styled.div`
  background-color: white;
  padding: 40px;
`;

const HelloText = styled.p`
  font-size: 48px;
  font-weight: 300;
  color: ${({ color }) => color};
  line-height: normal;
  @media (max-width: 1440px) {
    font-size: 36px;
  }
`;

// const SupportText = styled.p`
//   font-size: 24px;
//   color: rgba(0, 0, 0, 0.5);
//   line-height: 1.46;
//   @media (max-width: 1440px) {
//     font-size: 18px;
//   }
// `;

// const HighlightText = styled.span`
//   color: ${({ color }) => color};
// `;

const BannerImage = styled.img`
  width: 100%;
`;

const Banner = ({ theme, userName }) => {
  return (
    <Container>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={7}>
          <HelloText color={theme.palette.primary.main}>
            Olá,
            <br />
            <strong>
              {userName}
              .
            </strong>
          </HelloText>
          {/* <SupportText> */}
          {/*  Não esqueça de visualizar as */}
          {/*  {" "} */}
          {/*  <HighlightText color={theme.palette.primary.main}> */}
          {/*    Comunicações Recentes */}
          {/*  </HighlightText> */}
          {/*  {" "} */}
          {/*  para manter-se atualizado! */}
          {/* </SupportText> */}
        </Grid>
        <Grid item xs={5}>
          <BannerImage
            src={theme.assets.home}
            alt="Arte de um notebook e alguns livros"
          />
        </Grid>
      </Grid>
    </Container>
  );
};

Banner.propTypes = {
  userName: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object.isRequired // Inject by withTheme
};

export default withTheme(Banner);
