import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import DialogButton from "./DialogButton";
import SimpleDialog from "./SimpleDialog";

const AlertDialog = props => {
  const { onClose, open, title, message, btnText } = props;
  return (
    <SimpleDialog
      title={title}
      message={message}
      onClose={onClose}
      open={open}
      actions={(
        <Grid container justify="center">
          <Grid item xs={6}>
            <DialogButton
              fullWidth
              variant="contained"
              color="primary"
              onClick={onClose}
            >
              {btnText}
            </DialogButton>
          </Grid>
        </Grid>
      )}
    />
  );
};

AlertDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  btnText: PropTypes.string
};

AlertDialog.defaultProps = {
  open: false,
  btnText: "Ok"
};

export default AlertDialog;
