import {
  LOAD_COMMUNICATIONS,
  LOAD_COMMUNICATIONS_COUNT,
  SET_COMMUNICATIONS_PAGE,
  SET_COMMUNICATIONS_UNREAD
} from "../actions/actionTypes";

const INITIAL_STATE = {
  communications: [],
  count: 0,
  page: 0,
  unread: 0
};

export const communicationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_COMMUNICATIONS:
      return {
        ...state,
        communications: action.payload
      };
    case LOAD_COMMUNICATIONS_COUNT:
      return {
        ...state,
        count: action.payload
      };
    case SET_COMMUNICATIONS_PAGE:
      return {
        ...state,
        page: action.payload
      };
    case SET_COMMUNICATIONS_UNREAD:
      return {
        ...state,
        unread: action.payload
      };
    default:
      return state;
  }
};
