import { api } from "../utils/api";
import UserStore from "../utils/UserStore";

const formatCommunication = communicationSubscriber => {
  const { communication, interaction } = communicationSubscriber;
  const { creator, attachments } = communication;
  return {
    id: communication.id,
    subscriberId: communicationSubscriber.id,
    userId: communicationSubscriber.userId,
    title: communication.title,
    date: communication.updatedAt,
    type: communication.type,
    photo: creator
      ? creator.photoProfile
      : "/static/media/avatar-demo.4116d605.png",
    cover: communication.photo,
    bodyMessage: communication.bodyMessage,
    event: {
      date: communication.eventStart,
      location: UserStore.getSchoolName()
    },
    button: {
      text: communication.buttonText,
      link: communication.linkAddress
    },
    attachments: attachments.map(attachment => ({
      id: attachment.id,
      fileName: attachment.customName,
      path: attachment.url
    })),
    interaction
  };
};

const getCommunications = async page => {
  const params = {
    params: { page }
  };
  const response = await api.get(
    "CommunicationSubscribers/myCommunications",
    params
  );
  const { data } = response;

  const communications = data.map(communicationSubscriber => {
    return formatCommunication(communicationSubscriber);
  });
  return communications;
};

const getCommunicationsCount = async () => {
  return api.get("CommunicationSubscribers/myCommunications/count");
};

const getCommunication = async id => {
  const res = await api.get(`CommunicationSubscribers/myCommunications/${id}`);
  return formatCommunication(res.data);
};

const updateInteractions = async (data, id) => {
  return api.put(`/Communications/${id}/interaction`, data);
};

const markAsRead = async id => {
  return api.put(`Communications/${id}/read`);
};

export default {
  getCommunication,
  getCommunications,
  getCommunicationsCount,
  updateInteractions,
  markAsRead
};
