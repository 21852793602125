import PropTypes from "prop-types";
import React from "react";
import { Box, Typography, FormControlLabel, Radio } from "@material-ui/core";
import styled from "styled-components";

const DisplayCheck = ({ label, children, fullWidth, disabled }) => {
  const StyledBox = styled(Box)`
    margin-top: 16px;
  `;
  const Label = styled(Typography)`
    margin-right: 30px;
    margin-bottom: 0;
  `;
  return (
    <StyledBox
      display="flex"
      alignItems="center"
      justifyContent={fullWidth ? "space-between" : "normal"}
    >
      <Label variant="body1" color="textSecondary" disabled={disabled}>
        {label}
      </Label>
      <FormControlLabel
        disabled
        label={children}
        control={<Radio checked color="primary" />}
      />
    </StyledBox>
  );
};

DisplayCheck.propTypes = {
  children: PropTypes.element.isRequired,
  fullWidth: PropTypes.bool,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

DisplayCheck.defaultProps = {
  fullWidth: false,
  disabled: false
};

export default DisplayCheck;
