import React from "react";
import PropTypes from "prop-types";
import { Box, Grid, withTheme } from "@material-ui/core";
import styled from "styled-components";
import WuiRoundedAvatar from "../RoundedAvatar";
import BackgroundHeader from "./components/BackgroundHeader";

const Container = styled(Box)`
  * {
    z-index: 1;
  }
`;

const GridInfo = styled(Grid)`
  height: 100%;
  padding-top: 15px;
`;

const RoundedAvatar = styled(WuiRoundedAvatar)`
  margin: -120px 20px 0;
  border: solid 4.8px #ffffff;
`;

const ProfileName = styled.h2`
  font-family: Roboto;
  font-size: 30px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.secondary.dark};
  margin: 0 0 30px;
`;

const ProfileTitle = styled.p`
  opacity: 0.5;
  font-family: Roboto;
  font-size: 21px;
  color: #000000;
  margin: 0 0 13.5px;
`;

const HeaderProfile = ({ photo, name, info, coverImage, theme }) => {
  const headerImage = coverImage || theme.assets.profileHeader;

  return (
    <>
      <BackgroundHeader photo={headerImage} />
      <Container display="flex">
        <RoundedAvatar size={240} imageSrc={photo} icon="person" />
        <Box>
          <GridInfo container direction="column" justify="center">
            <Grid item>
              <ProfileName>{name}</ProfileName>
            </Grid>
            <Grid item>
              <ProfileTitle>{info}</ProfileTitle>
            </Grid>
          </GridInfo>
        </Box>
      </Container>
    </>
  );
};

HeaderProfile.propTypes = {
  coverImage: PropTypes.string,
  info: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  photo: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object.isRequired // Injected by withTheme
};

HeaderProfile.defaultProps = {
  coverImage: null
};

export default withTheme(HeaderProfile);
