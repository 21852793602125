const getNotes = async () => {
  return [
    {
      id: 1,
      title: "Título da anotação",
      date: "10/10/2019 16:45",
      course: { id: 2, name: "Geografia 2" },
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ullamcorper aliquet urna. Donec vitae dolor nulla. Proin sit amet porttitor felis. Praesent suscipit posuere nunc eget laoreet. Aenean eget vehicula nulla. Pellentesque sodales non magna quis finibus. Sed posuere risus at leo porta, sit amet ornare odio iaculis. Vestibulum sed tellus ex. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nulla facilisi. Nulla vel purus enim. Fusce eget venenatis urna. Vivamus vitae aliquet odio."
    },
    {
      id: 2,
      title: "Título da anotação",
      date: "10/10/2019 16:45",
      course: { id: 1, name: "Geografia 1" },
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ullamcorper aliquet urna. Donec vitae dolor nulla. Proin sit amet porttitor felis. Praesent suscipit posuere nunc eget laoreet. Aenean eget vehicula nulla. Pellentesque sodales non magna quis finibus. Sed posuere risus at leo porta, sit amet ornare odio iaculis. Vestibulum sed tellus ex. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nulla facilisi. Nulla vel purus enim. Fusce eget venenatis urna. Vivamus vitae aliquet odio."
    },
    {
      id: 3,
      title: "Título da anotação",
      date: "10/10/2019 16:45",
      course: { id: 1, name: "Geografia 1" },
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ullamcorper aliquet urna. Donec vitae dolor nulla. Proin sit amet porttitor felis. Praesent suscipit posuere nunc eget laoreet. Aenean eget vehicula nulla. Pellentesque sodales non magna quis finibus. Sed posuere risus at leo porta, sit amet ornare odio iaculis. Vestibulum sed tellus ex. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nulla facilisi. Nulla vel purus enim. Fusce eget venenatis urna. Vivamus vitae aliquet odio."
    }
  ];
};

const createNote = async note => {
  return note;
};

const updateNote = async note => {
  return note;
};

const deleteNote = async note => {
  return note;
};

export default {
  getNotes,
  createNote,
  updateNote,
  deleteNote
};
