import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Popover from "@material-ui/core/Popover";

const Container = styled.div`
  padding: 30px;
`;

const HeaderText = styled.div`
  font-size: 20px;
  color: ${({ color }) => color || "rgba(0,0,0,.5)"};
`;

const Title = styled.p`
  margin: 0;
  font-size: 20px;
`;
const Description = styled.p`
  margin: 16px 0 0 0;
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
`;
const Highlight = styled.span`
  color: ${({ theme }) => theme.color.primary.main};
`;

const HeaderDetails = ({ hasDetails, title, color, popoverProps }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    if (hasDetails) setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <HeaderText color={color} onClick={handleClick}>
        {title}
      </HeaderText>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
      >
        <Container>
          <Title>{popoverProps.title}</Title>

          <Description>
            <Highlight>Valor:</Highlight> 
            {' '}
            {popoverProps.value}
          </Description>
        </Container>
      </Popover>
    </div>
  );
};

HeaderDetails.defaultProps = {
  color: null,
  hasDetails: false,
  popoverProps: {}
};

HeaderDetails.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  hasDetails: PropTypes.bool,
  popoverProps: PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.number
  })
};

export default HeaderDetails;
