import React from "react";
import PropTypes from "prop-types";
import { CircularProgress } from "@material-ui/core";
import styled from "styled-components";
import Heading from "../Heading";
import Dialog from "./Dialog";
import DialogTitle from "./DialogTitle";
import DialogActions from "./DialogActions";
import DialogContent from "./DialogContent";

const LoadContainer = styled.div`
  width: 100%;
  text-align: center;
  padding: 32px 8px;
`;

const SimpleDialog = ({
  onClose,
  open,
  title,
  message,
  actions,
  loading,
  closeBtn
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth closeButton={closeBtn}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Heading>
          {loading ? (
            <LoadContainer>
              <CircularProgress />
            </LoadContainer>
          ) : (
            message
          )}
        </Heading>
      </DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
};

SimpleDialog.defaultProps = {
  open: false,
  actions: undefined,
  closeBtn: false,
  loading: false,
  message: ""
};

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  actions: PropTypes.node,
  loading: PropTypes.bool,
  closeBtn: PropTypes.bool
};

export default SimpleDialog;
