// React
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import _ from "lodash";
import RoundedAvatar from "../RoundedAvatar";

const Container = styled.div`
  display: inline-flex;
  flex-direction: row-reverse;
  justify-content: start;
`;

const PhotoItem = styled.div`
  margin-left: -20px;
  display: inline-block;
  &:last-of-type {
    margin-left: 0;
  }
`;

const ExcessBadge = styled.div`
  width: ${({ size }) => size || RoundedAvatar.defaultProps.size}px;
  height: ${({ size }) => size || RoundedAvatar.defaultProps.size}px;
  background-color: ${({ theme }) => theme.color.primary.light};
  border-radius: 50%;
  margin-left: -20px;
  font-size: 28px;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const MultiAvatar = ({ RoundedAvatarProps, photos, maxItems }) => {
  const reducedArray = _.slice(photos, 0, maxItems);

  return (
    <Container>
      {photos.length > maxItems && (
        <ExcessBadge size={RoundedAvatarProps.size}>
          +
          {photos.length - maxItems}
        </ExcessBadge>
      )}

      {_.reverse(reducedArray).map(photo => (
        <PhotoItem>
          <RoundedAvatar {...RoundedAvatarProps} imageSrc={photo.src}>
            {photo.name}
          </RoundedAvatar>
        </PhotoItem>
      ))}
    </Container>
  );
};

MultiAvatar.defaultProps = {
  RoundedAvatarProps: {},
  photos: [],
  maxItems: 2
};

MultiAvatar.propTypes = {
  RoundedAvatarProps: RoundedAvatar.defaultProps,
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      src: PropTypes.string
    })
  ),
  maxItems: PropTypes.number
};

export default MultiAvatar;
