import PropTypes from "prop-types";
import React from "react";
import MuiIconButton from "@material-ui/core/IconButton";
import styled from "styled-components";
import { Box, Typography } from "@material-ui/core";

const IconButton = styled(MuiIconButton)`
  border-radius: 0;
  width: 100%;
  height: 100%;

  svg {
    width: 2.2em;
    height: 2.2em;
    color: ${({ theme }) => theme.color.primary.dark};
  }
`;

const ContainerBox = styled(Box)`
  width: 150px;
  height: 200px;
`;

const ButtonBox = styled(Box)`
  width: 150px;
  height: 150px;
  margin: 0;
`;

const Label = styled(Typography)`
  font-size: 22px;
  color: #7f7f7f;
  text-align: center;
  margin-top: 10px;
`;

IconButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  ...MuiIconButton.propTypes
};

const SquareButton = props => {
  const { icon, children } = props;
  const Icon = icon;
  return (
    <ContainerBox>
      <ButtonBox borderRadius={13} borderColor="#BFBFBF" m={1} border={1}>
        <IconButton {...props}>
          <Icon />
        </IconButton>
      </ButtonBox>
      <Label>{children}</Label>
    </ContainerBox>
  );
};

SquareButton.propTypes = {
  children: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  icon: PropTypes.object.isRequired // Should be an @material-ui/icons
};

export default SquareButton;
