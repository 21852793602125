import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Route, Switch, withRouter, matchPath } from "react-router-dom";
import styled from "styled-components";
// Components
import {
  Home as HomeIcon,
  // Mail as MailIcon,
  GroupWork as GroupWorkIcon,
  DateRange as DateRangeIcon,
  People as PeopleIcon,
  Error as ErrorIcon,
  ExitToApp as ExitToAppIcon,
  School as SchoolIcon,
  NotificationImportant as NotificationImportantIcon
} from "@material-ui/icons";
import { MenuItem, Select as MuiSelect } from "@material-ui/core";
import { bindActionCreators } from "redux";
import {
  setCommunicationsUnread,
  setNotificationsUnread
} from "./redux/actions";
import UserStore from "./utils/UserStore";
import Toolbar from "./waybee-ui/Toolbar";
import List from "./waybee-ui/List";
import ListItem from "./waybee-ui/List/ListItem";
import UserService from "./services/UserService";
import routes from "./routes";
import Login from "./views/Login/Login";
import Home from "./views/Home/Home";
import CommunicationDashboard from "./views/CommunicationDashboard/CommunicationDashboard";
import Notifications from "./views/Notifications/Notifications";
import Classes from "./views/Classes/Classes";
import ClassDetails from "./views/ClassDetails/ClassDetails";
import Ocurrencces from "./views/Occurrences/Occurrences";
import ReleaseGrades from "./views/ReleaseGrades/ReleaseGrades";
import ClassSchedule from "./views/ClassSchedule/ClassSchedule";
import ClassNotes from "./views/ClassNotes/ClassNotes";
import Schedule from "./views/Schedule/Schedule";
import Teachers from "./views/Teachers/Teachers";
import TeacherDetail from "./views/TeacherDetails/TeacherDetails";
import Support from "./views/Support/Support";
import StudentProfile from "./views/StudentProfile/StudentProfile";
import StudentNotes from "./views/StudentNotes/StudentNotes";
import StudentStatistics from "./views/StudentStatistics/StudentStatistics";
import StudentSchedule from "./views/StudentSchedule/StudentSchedule";
import StudentOccurrences from "./views/StudentOccurrences/StudentOccurrences";
import StudentContact from "./views/StudentContact/StudentContact";
import ClassGradesV2 from "./views/ClassGradesV2/ClassGradesV2";
import IndividualReport from "./views/IndividualReport/IndividualReport";

const Select = styled(MuiSelect)`
  margin: 0;
  color: #7a7a7a;
  .MuiSelect-root {
    width: 172px;
  }

  .MuiSelect-icon {
    color: #7a7a7a;
  }
`;

class App extends Component {
  constructor() {
    super();
    this.state = {
      title: "",
      campuses: [],
      selectedCampus: ""
    };
  }

  componentDidMount() {
    if (!UserService.isAuthenticated()) return;

    const { location, history } = this.props;

    history.listen(historyLocation => {
      this.setTitle(historyLocation.pathname);
    });

    this.setTitle(location.pathname);
    this.getUserCampus();

    this.getUnreadItems();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { location: prevLocation } = prevProps;
    if (location.pathname !== prevLocation.pathname) {
      this.setTitle(location.pathname);
    }
  }

  setTitle = pathname => {
    const route = routes.find(e => {
      const match = matchPath(pathname, {
        path: e.path,
        exact: true
      });
      return match && match.isExact;
    });
    if (route) {
      const { title } = route;
      this.setState({ title });
    }
  };

  getUserCampus = async () => {
    const res = await UserService.getUserCampus();
    this.setState({
      campuses: res,
      selectedCampus: UserStore.getCurrentCampus()
    });
  };

  changeCampus = e => {
    this.setState({ selectedCampus: e.target.value });
    UserStore.setCurrentCampus(e.target.value);
  };

  getUnreadItems = async () => {
    const {
      setCommunicationsUnread: setCommUnread,
      setNotificationsUnread: setNotUnread
    } = this.props;

    const res = await UserService.getMySessionInfos();
    const unreadItems = res.data;
    setCommUnread(unreadItems.communicationsUnread);
    setNotUnread(unreadItems.notificationsUnread);
  };

  handleLogout = () => {
    UserService.logout();
  };

  render() {
    const { title, campuses, selectedCampus } = this.state;
    const { /* communicationState, */ notificationState } = this.props;

    if (!UserService.isAuthenticated())
      return <Login getCampuses={this.getUserCampus} />;

    return (
      <div>
        <Toolbar
          activeTitle={title}
          userPhoto={UserStore.getUserPhoto()}
          userName={UserStore.getUserName()}
          listMenu={(
            <List>
              <ListItem icon={<SchoolIcon />} button={false}>
                <Select
                  value={selectedCampus}
                  onChange={this.changeCampus}
                  disableUnderline
                >
                  {campuses.map(campus => (
                    <MenuItem key={campus.id} value={campus.id}>
                      {campus.name}
                    </MenuItem>
                  ))}
                </Select>
              </ListItem>
              <ListItem icon={<HomeIcon />} component={Link} to="/">
                Home
              </ListItem>
              {/* <ListItem */}
              {/*  icon={<MailIcon />} */}
              {/*  component={Link} */}
              {/*  badgeNumber={communicationState.unread} */}
              {/*  to="/communication" */}
              {/* > */}
              {/*  Comunicações */}
              {/* </ListItem> */}
              <ListItem icon={<GroupWorkIcon />} component={Link} to="/classes">
                Turmas
              </ListItem>
              <ListItem
                icon={<DateRangeIcon />}
                component={Link}
                to="/schedule"
              >
                Horários
              </ListItem>
              <ListItem icon={<PeopleIcon />} component={Link} to="/teachers">
                Professores
              </ListItem>
              <ListItem
                icon={<NotificationImportantIcon />}
                component={Link}
                to="/notifications"
                badgeNumber={notificationState.unread}
              >
                Notificações
              </ListItem>
              <ListItem icon={<ErrorIcon />} component={Link} to="/support">
                Suporte
              </ListItem>
              <ListItem
                onClick={() => UserStore.clear()}
                icon={<ExitToAppIcon />}
                component={Link}
                to="/login"
              >
                Sair
              </ListItem>
            </List>
          )}
        >
          <Switch>
            <Route exact path="/" component={Home} />
            <Route
              exact
              path="/communication/:id"
              component={CommunicationDashboard}
            />
            <Route
              exact
              path="/communication"
              component={CommunicationDashboard}
            />
            <Route exact path="/notifications" component={Notifications} />
            <Route exact path="/classes" component={Classes} />
            <Route exact path="/classes/:id" component={ClassDetails} />
            <Route
              exact
              path="/classes/:id/occurrences"
              component={Ocurrencces}
            />
            <Route exact path="/classes/:id/grades" component={ReleaseGrades} />
            <Route
              exact
              path="/classes/:id/schedule"
              component={ClassSchedule}
            />
            <Route exact path="/classes/:id/notes" component={ClassNotes} />
            <Route
              exact
              path="/classes/:id/gradesV2"
              component={ClassGradesV2}
            />
            <Route
              exact
              path="/classes/:id/individualReport"
              component={IndividualReport}
            />
            <Route exact path="/schedule" component={Schedule} />
            <Route exact path="/teachers" component={Teachers} />
            <Route exact path="/teachers/:id" component={TeacherDetail} />
            <Route exact path="/support" component={Support} />
            <Route
              exact
              path="/student/:studentId"
              component={StudentProfile}
            />
            <Route
              exact
              path="/student/:studentId/notes"
              component={StudentNotes}
            />
            <Route
              exact
              path="/student/:studentId/statistics"
              component={StudentStatistics}
            />
            <Route
              exact
              path="/student/:studentId/schedule"
              component={StudentSchedule}
            />
            <Route
              exact
              path="/student/:studentId/occurrences"
              component={StudentOccurrences}
            />
            <Route
              exact
              path="/student/:studentId/contact"
              component={StudentContact}
            />
          </Switch>
        </Toolbar>
      </div>
    );
  }
}

App.propTypes = {
  history: PropTypes.shape({
    listen: PropTypes.func
  }).isRequired, // injected by withRouter
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired, // injected by withRouter
  communicationState: PropTypes.shape({
    unread: PropTypes.number
  }).isRequired, // injected by react-redux mapStateToProps connect()
  notificationState: PropTypes.shape({
    unread: PropTypes.number
  }).isRequired, // injected by react-redux mapStateToProps connect()
  setCommunicationsUnread: PropTypes.func.isRequired, // injected by react-redux mapDispatchToProps connect()
  setNotificationsUnread: PropTypes.func.isRequired // injected by react-redux mapDispatchToProps connect()
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { setCommunicationsUnread, setNotificationsUnread },
    dispatch
  );

const mapStateToProps = state => ({
  communicationState: state.communicationState,
  notificationState: state.notificationState
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
