import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import TableGrid from "../waybee-ui/TableGrid";

const TableGridRowStyle = styled(TableGrid.Row)`
  border-left: solid 10px ${({ color }) => color};
`;

const TableGridRowColor = props => {
  const { color } = props;
  return <TableGridRowStyle color={color} {...props} />;
};

TableGridRowColor.propTypes = {
  color: PropTypes.string.isRequired
};

export default TableGridRowColor;
