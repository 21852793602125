import PropTypes from "prop-types";
import React, { Component } from "react";
import { Grid, Box, CircularProgress } from "@material-ui/core";
import { withRouter, Link as ReactRouterLink } from "react-router-dom";
import {
  // Details as DetailsIcon,
  // Description as DescriptionIcon,
  ErrorOutline as ErrorOutlineIcon,
  DateRange as DateRangeIcon,
  Phone as PhoneIcon
} from "@material-ui/icons";
import HeaderProfile from "../../waybee-ui/HeaderProfile";
import MenuButton from "../../waybee-ui/MenuButton";

import StudentService from "../../services/StudentService";

const Link = props => (
  <ReactRouterLink {...props} style={{ textDecoration: "none" }} />
);

class StudentProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      student: null
    };
  }

  componentDidMount() {
    this.getStudentProfile();
  }

  getStudentProfile = async () => {
    const { match } = this.props;
    const { studentId } = match.params;
    const student = await StudentService.getStudentById(studentId);
    this.setState({ student });
  };

  render() {
    const { student } = this.state;

    if (!student) {
      return (
        <Box p={6} textAlign="center">
          <CircularProgress />
        </Box>
      );
    }

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <HeaderProfile
            name={student.name}
            info={`R.A: ${student.ra}`}
            photo={student.photo}
          />
        </Grid>
        <Grid item xs={12}>
          {/* <Link to={`${student.id}/notes`}> */}
          {/*  <MenuButton icon={<DetailsIcon />}>Observações</MenuButton> */}
          {/* </Link> */}
          {/* <Link to={`${student.id}/statistics`}> */}
          {/*  <MenuButton icon={<DescriptionIcon />}>Desempenho</MenuButton> */}
          {/* </Link> */}
          <Link to={`${student.id}/occurrences`}>
            <MenuButton icon={<ErrorOutlineIcon />}>
              Histórico de Ocorrências
            </MenuButton>
          </Link>
          <Link to={`${student.id}/schedule`}>
            <MenuButton icon={<DateRangeIcon />}>Horários</MenuButton>
          </Link>
          <Link to={`${student.id}/contact`}>
            <MenuButton icon={<PhoneIcon />}>Contato</MenuButton>
          </Link>
        </Grid>
      </Grid>
    );
  }
}

StudentProfile.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.array
  }).isRequired
};
export default withRouter(StudentProfile);
