import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import HeaderInfo from "../../waybee-ui/HeaderInfo/HeaderInfo";
import Group from "../../waybee-ui/Group";
import CollapseGroup from "../../waybee-ui/CollapseGroup";
import Calendar from "../../waybee-ui/Calendar/Calendar";
import WuiClassesList from "../Classes/components/ClassesList";
import TeacherService from "../../services/TeacherService";

const ClassesList = styled(WuiClassesList)`
  margin: 0;
  width: 100%;
`;

// eslint-disable-next-line react/prefer-stateless-function
class TeacherDetail extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    const options = {
      views: {
        timeGridWeek: {
          titleFormat: { month: "long", day: "numeric" },
          slotLabelFormat: { hour: "numeric", minute: "2-digit" },
          columnHeaderFormat: { weekday: "short", day: "numeric" }
        },
        timeGridDay: {
          titleFormat: { month: "long", day: "numeric", weekday: "short" }
        }
      },
      allDaySlot: false,
      events: async (info, successCallback, failureCallback) => {
        const { match } = this.props;
        try {
          const mock = await TeacherService.getSchedule(
            info.startStr,
            info.endStr,
            match.params.id
          );
          successCallback(mock);
        } catch (e) {
          failureCallback(e);
        }
      },
      defaultView: "timeGridWeek"
    };

    return (
      <div>
        <Grid container>
          <Grid item xs={12}>
            <Group>
              <HeaderInfo
                name="Nome do professor"
                info="email@professor.com"
                tag="Disciplina"
                tagIcon="bookmark"
                photo="https://www.thispersondoesnotexist.com/image"
              />
            </Group>
          </Grid>

          <Grid item xs={12}>
            <CollapseGroup title="Grade de Horários">
              <Calendar options={options} />
            </CollapseGroup>
          </Grid>

          <Grid item xs={12}>
            <CollapseGroup title="Turmas do Professor">
              <ClassesList filter={{ teacher: 1000 }} />
            </CollapseGroup>
          </Grid>
        </Grid>
      </div>
    );
  }
}

TeacherDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.array
  }).isRequired // Injected by withRouter
};

export default withRouter(TeacherDetail);
