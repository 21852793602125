const setCurrentCampus = val => {
  localStorage.setItem("currentCampus", val);
};

const getCurrentCampus = () => {
  return localStorage.getItem("currentCampus");
};

const CurrentCampusService = {
  set: setCurrentCampus,
  get: getCurrentCampus
};

export default CurrentCampusService;
