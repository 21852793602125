import PropTypes from "prop-types";
import React, { Component } from "react";
import { Grid, Box, CircularProgress } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { useTheme } from "@material-ui/core/styles";
import HeaderInfo from "../../waybee-ui/HeaderInfo/HeaderInfo";
import Group from "../../waybee-ui/Group";
import Divider from "../../waybee-ui/Divider";
import Heading from "../../waybee-ui/Heading";
import CollapseGroup from "../../waybee-ui/CollapseGroup";
import CollapseSubGroup from "../../waybee-ui/CollapseSubGroup";
import TableGrid from "../../waybee-ui/TableGrid";
import BackButton from "../../components/BackButton";
import TableGridRowColor from "../../components/TableGridRowColor";
import AnnualStatisticsChart from "./components/AnnualStatisticsChart";

import StudentService from "../../services/StudentService";

const HeaderGrid = styled(Grid)`
  margin-bottom: -20px;
`;

const HeaderGroup = styled(Group)`
  padding-bottom: 0;
`;

const GradeText = styled.span`
  font-size: 28px;
  font-weight: 500;
  text-align: center;
  width: 100%;
  color: ${({ children }) => {
    const theme = useTheme();
    const gradeScore = +children;
    if (Number.isNaN(gradeScore) === false) {
      if (gradeScore < 6) {
        return theme.palette.error.main;
      }
      return theme.palette.primary.main;
    }
    return "#7F7F7F";
  }};
`;

class StudentStatistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classId: null,
      student: null,
      studentGrades: []
    };
  }

  async componentDidMount() {
    const { match } = this.props;
    const { classId, studentId } = match.params;
    const student = await StudentService.getStudentById(studentId);
    const studentGrades = await this.getStudentGrades(studentId);
    this.setState({ classId, student, studentGrades });
  }

  getStudentGrades = async studentId => {
    return StudentService.getGrades(studentId);
  };

  render() {
    const { classId, student, studentGrades } = this.state;

    if (!student || !studentGrades) {
      return (
        <Box p={6} textAlign="center">
          <CircularProgress />
        </Box>
      );
    }

    return (
      <Grid container spacing={2}>
        <BackButton to={`/classes/${classId}/student/${student.id}`} />
        <HeaderGrid item xs={12}>
          <HeaderGroup>
            <Box mb={2}>
              <HeaderInfo
                name={student.name}
                info={`R.A: ${student.ra}`}
                photo={student.photo}
              />
            </Box>
            <Divider />
          </HeaderGroup>
        </HeaderGrid>
        {studentGrades.map(yearDivision => (
          <Grid item xs={12} key={yearDivision.id}>
            <CollapseGroup title={yearDivision.name}>
              {yearDivision.schoolGrades.map(schoolGrade => (
                <CollapseSubGroup key={schoolGrade.id} title={schoolGrade.name}>
                  <Box>
                    <Box mx={3} mb={2}>
                      <TableGrid>
                        {schoolGrade.courses.map(course => (
                          <TableGridRowColor color={course.color}>
                            <TableGrid.Group xs={11}>
                              <TableGrid.Title xs={12}>
                                {course.name}
                              </TableGrid.Title>
                              <TableGrid.Text xs={12}>
                                {course.teacher.toUpperCase()}
                              </TableGrid.Text>
                            </TableGrid.Group>
                            <TableGrid.Cell xs={1}>
                              <GradeText>{course.gradeScore || "-"}</GradeText>
                            </TableGrid.Cell>
                          </TableGridRowColor>
                        ))}
                      </TableGrid>
                    </Box>
                  </Box>
                </CollapseSubGroup>
              ))}
            </CollapseGroup>
          </Grid>
        ))}
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <Group>
              <Heading level={1} icon="bar_chart">
                Gráficos
              </Heading>
            </Group>
          </Grid>
          <Grid item xs={12}>
            <CollapseSubGroup title="Gráfico de Desempenho">
              {}
            </CollapseSubGroup>
            <CollapseSubGroup title="Distribuição de Notas">
              {}
            </CollapseSubGroup>
            <CollapseSubGroup title="Desempenho nos Bimestres">
              <AnnualStatisticsChart />
            </CollapseSubGroup>
          </Grid>
        </Grid>
        {/* <Grid item xs={12}>
          <CollapseSubGroup>
            {}
          </CollapseSubGroup>
        </Grid> */}
      </Grid>
    );
  }
}

StudentStatistics.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.array
  }).isRequired
};
export default withRouter(StudentStatistics);
