import React from "react";
import PropTypes from "prop-types";
import { DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import Dialog from "../Dialog/Dialog";
import Heading from "../Heading/Heading";
import Button from "../Button";

const ConfirmAlert = ({
  title,
  text,
  open,
  onConfirm,
  onClose,
  confirmButtonText,
  cancelButtonText
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Heading>{text}</Heading>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={onClose}>
          {cancelButtonText}
        </Button>
        <Button variant="contained" color="error" onClick={onConfirm}>
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmAlert.propTypes = {
  cancelButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  text: PropTypes.string,
  title: PropTypes.string
};

ConfirmAlert.defaultProps = {
  text: "",
  title: "",
  cancelButtonText: "Voltar",
  confirmButtonText: "Confirmar"
};

export default ConfirmAlert;
