import React from "react";
import { Box, Typography, Grid, TextField } from "@material-ui/core";
import styled from "styled-components";

import DisplayCheck from "./DisplayCheck";

const MedicalInfo = () => {
  const FormGrid = styled(Grid)`
    .MuiGrid-item {
      padding-bottom: 0;
    }
    margin-bottom: 25px;
  `;

  return (
    <Box my={2} mx={3}>
      <FormGrid container spacing={5}>
        <Grid item xs={6} padd>
          <TextField
            disabled
            label="Altura do Aluno"
            value="1,59 m"
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          <TextField disabled label="Peso" value="49 Kg" fullWidth />
        </Grid>

        <Grid item xs={6}>
          <DisplayCheck disabled label="Tipo sanguíneo:">
            A
          </DisplayCheck>
        </Grid>

        <Grid item xs={6}>
          <DisplayCheck disabled label="Fator RH:">
            -
          </DisplayCheck>
        </Grid>

        <Grid item xs={12}>
          <DisplayCheck
            disabled
            label="Tem alergia a algum medicamento / produto?"
          >
            Sim
          </DisplayCheck>
        </Grid>

        <Grid item xs={6}>
          <TextField
            disabled
            label="Fator desencadeante"
            value={null}
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          <TextField disabled label="Especificação" value={null} fullWidth />
        </Grid>

        <Grid item xs={6}>
          <TextField disabled label="Procedimento" value={null} fullWidth />
        </Grid>

        <Grid item xs={6} />

        <Grid item xs={6}>
          <DisplayCheck
            fullWidth
            label="Alguma doença grave já contraída / Doenças de infância / Internações?"
          >
            Sim
          </DisplayCheck>
        </Grid>

        <Grid item xs={6}>
          <TextField disabled label="Qual?" value={null} fullWidth />
        </Grid>

        <Grid item xs={6}>
          <DisplayCheck
            disabled
            fullWidth
            label="Foi submetido(a) a alguma cirurgia?"
          >
            Não
          </DisplayCheck>
        </Grid>

        <Grid item xs={6} />

        <Grid item xs={6}>
          <DisplayCheck
            fullWidth
            label="Esta fazendo algum tratamento especial, com duração superior a um mês?"
          >
            Sim
          </DisplayCheck>
        </Grid>

        <Grid item xs={6}>
          <TextField disabled label="Qual?" value={null} fullWidth />
        </Grid>

        <Grid item xs={6}>
          <DisplayCheck
            disabled
            fullWidth
            label="Restrições à atividade física?"
          >
            Sim
          </DisplayCheck>
        </Grid>

        <Grid item xs={6}>
          <TextField disabled label="Motivo" value={null} fullWidth />
        </Grid>

        <Grid item xs={6}>
          <DisplayCheck disabled fullWidth label="Possui Plano de Saúde?">
            Sim
          </DisplayCheck>
        </Grid>

        <Grid item xs={6}>
          <TextField disabled label="Qual?" value={null} fullWidth />
        </Grid>

        <Grid item xs={12}>
          <Box mt={2}>
            <Typography variant="body1" color="textSecondary" margin>
              Hospital recomendado para eventual necessidade:
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <TextField disabled label="Nome do Hospital" value={null} fullWidth />
        </Grid>

        <Grid item xs={6}>
          <TextField disabled label="Endereço" value={null} fullWidth />
        </Grid>

        <Grid item xs={6}>
          <TextField disabled label="Bairro" value={null} fullWidth />
        </Grid>

        <Grid item xs={6}>
          <TextField disabled label="Telefone" value={null} fullWidth />
        </Grid>

        <Grid item xs={12}>
          <Box mt={2}>
            <Typography variant="h4">Caso o aluno tenha:</Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <TextField disabled label="Sintoma" value={null} fullWidth />
        </Grid>

        <Grid item xs={6} />

        <Grid item xs={6}>
          <TextField
            disabled
            label="Nome da Medicação"
            value={null}
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          <TextField disabled label="Dosagem" value={null} fullWidth />
        </Grid>
      </FormGrid>
    </Box>
  );
};

export default MedicalInfo;
