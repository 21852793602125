import { createMuiTheme } from "@material-ui/core/styles";

export default theme =>
  createMuiTheme({
    spacing: 10,
    palette: {
      background: {
        default: "#f2f2f2"
      },
      primary: {
        light: theme.color.primary.light,
        main: theme.color.primary.main,
        dark: theme.color.primary.dark,
        contrastText: "#fff"
      },
      secondary: {
        light: theme.color.secondary.light,
        main: theme.color.secondary.main,
        dark: theme.color.secondary.dark,
        contrastText: "#fff"
      },
      error: {
        light: theme.color.error.light,
        main: theme.color.error.main,
        dark: theme.color.error.dark,
        contrastText: "#fff"
      },
      default: {
        light: theme.color.default.light,
        main: theme.color.default.main,
        dark: theme.color.default.dark,
        contrastText: "#fff"
      }
    },
    typography: {
      allVariants: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
      },
      h1: {
        fontSize: 24,
        fontWeight: 500,
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
        color: theme.color.primary.dark,
        marginBottom: "5px"
      },
      h2: {
        fontSize: 30,
        fontWeight: 500,
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
        color: theme.color.secondary.dark,
        marginBottom: "5px"
      },
      h3: {
        fontSize: 24,
        fontWeight: 300,
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
        color: "#000000",
        marginBottom: "5px"
      },
      h4: {
        fontSize: 21,
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: "#7F7F7F",
        marginBottom: "5px"
      },
      h5: {
        fontSize: 18,
        fontWeight: 500,
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
        color: theme.color.secondary.dark,
        marginBottom: "5px"
      },
      h6: {
        fontSize: 16,
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
        color: "#7F7F7F",
        marginBottom: "5px"
      },
      body1: {
        marginBottom: "4px",
        fontSize: 16,
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.25em",
        letterSpacing: "normal",
        color: "#000000"
      },
      body2: {
        marginBottom: "4px",
        fontSize: 14,
        lineHeight: "1.33em",
        color: "#000000"
      },
      subtitle1: {
        marginBottom: "4px",
        fontSize: 18,
        fontWeight: 500,
        lineHeight: "1.56em",
        color: theme.color.secondary.dark
      },
      caption: {
        fontSize: 14,
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        marginBottom: "8px",
        display: "inline-block",
        lineHeight: 1.29,
        letterSpacing: "normal",
        color: "#727272"
      }
    },
    overrides: {
      MuiTypography: {
        gutterBottom: {
          marginBottom: "20px"
        }
      },
      MuiSvgIcon: {
        fontSizeLarge: {
          fontSize: "2.3875rem"
        }
      },
      MuiFormLabel: {
        root: {
          color: "#727272",
          borderBottomColor: "#727272",
          marginBottom: "20px"
        }
      },
      MuiInput: {
        underline: {
          "&::before:hover": {
            borderBottom: "solid 2px #727272"
          }
        }
      },
      MuiFormControl: {
        marginDense: {
          marginTop: "-30px"
        }
      },
      MuiFormControlLabel: {
        label: {
          color: "#727272",
          marginBottom: 0
        }
      },
      MuiFormHelperText: {
        root: {
          marginBottom: "-4px"
        }
      },
      MuiChip: {
        root: {
          margin: "3px"
        }
      },
      MuiDialog: {
        paper: {
          overflow: "unset !important"
        },
        paperWidthSm: {
          maxWidth: "709px"
        }
      },
      MuiDialogTitle: {
        root: {
          padding: "30px 30px 12px",
          "& h2": {
            fontFamily: "Roboto",
            fontSize: "25.6px",
            fontWeight: 300,
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: 1.43,
            letterSpacing: "normal",
            color: theme.color.secondary.dark
          }
        }
      },
      MuiDialogContent: {
        root: {
          padding: "8px 30px",
          // '& h1, & h2, & h2, & h2, & h2, & h2': {}
          "& h6": {
            marginBottom: "35px",
            fontSize: "16.5px",
            fontWeight: 500,
            lineHeight: "1.56em",
            color: theme.color.secondary.dark
          }
        }
      },
      MuiDialogContentText: {
        root: {
          padding: "4px 20px 35px",
          fontFamily: "Roboto",
          fontSize: "14.7px",
          fontWeight: "normal",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: 1.5,
          letterSpacing: "normal",
          color: "#000000"
        }
      },
      MuiDialogActions: {
        root: {
          justifyContent: "center",
          padding: "30px 25px",
          "& button": {
            margin: "0px 30px",
            width: "100%",
            maxWidth: "276px"
          }
        }
      },
      MuiOutlinedInput: {
        root: {
          borderRadius: "10px"
        }
      },
      MuiExpansionPanelActions: {
        root: {
          borderTop: "solid #f2f2f2 1px",
          justifyContent: "center",
          padding: 0,
          "& button": {
            width: "100%",
            textTransform: "uppercase",
            borderRadius: 0,
            height: 42,
            margin: "7px 0",
            fontWeight: "normal"
          },
          "& button:not(:last-child)": {
            borderRight: "solid #cccccc 1px"
          },
          "& button:not(:first-child)": {
            marginLeft: 0
          }
        }
      }
    },
    assets: {
      home: theme.images.homeAsset,
      profileHeader: theme.images.profileHeaderAsset
    }
  });
