import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import withTheme from "@material-ui/core/styles/withTheme";
import MuiButton from "@material-ui/core/Button";
import tinyColor from "tinycolor2";

const DialogButton = styled(MuiButton)`
  text-transform: none;
  color: ${props => {
    if (props.textColor) return props.textColor;
    if (
      props.color === "primary" ||
      props.color === "secondary" ||
      props.color === "error"
    ) {
      return props.theme.palette[props.color].contrastText;
    }
    return "black";
  }}
  ${props =>
    props.backgroundColor &&
    css`
      background-color: ${props.backgroundColor};
    `}
  &:hover {
    ${props =>
      props.backgroundColor &&
      css`
        background-color: ${tinyColor(props.backgroundColor)
          .darken()
          .toString()};
      `}
  }
`;

DialogButton.defaultProps = {
  textColor: undefined,
  backgroundColor: undefined
};

DialogButton.propTypes = {
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string
};

export default withTheme(DialogButton);
