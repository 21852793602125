import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Collapse as MuiCollapse,
  Divider,
  Icon,
  withTheme
} from "@material-ui/core";
import styled, { css } from "styled-components";
import WuiGroup from "../Group";

const Group = styled(WuiGroup)`
  padding: 30px 20px;
  cursor: pointer;
`;

const Collapse = styled(MuiCollapse)`
  background-color: white;
`;

const GroupTitle = styled.p`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  line-height: normal;

  ${({ theme }) => {
    return css`
      color: ${theme.palette.primary.main};
    `;
  }}
`;

const CollapseBox = styled.div`
  background-color: white;
`;

const CollapseGroup = ({ groupProps, children, title, className, theme }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className={className}>
      <Group {...groupProps} onClick={() => setOpen(!open)}>
        <Box display="flex" justifyContent="space-between">
          <GroupTitle theme={theme}>{title}</GroupTitle>
          <Icon>{open ? "expand_less" : "expand_more"}</Icon>
        </Box>
      </Group>
      <Divider />
      <Collapse in={open}>
        <CollapseBox>{children}</CollapseBox>
      </Collapse>
    </div>
  );
};

CollapseGroup.defaultProps = {
  groupProps: undefined,
  className: ""
};

CollapseGroup.propTypes = {
  groupProps: PropTypes.shape({
    ...Group.propTypes
  }),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object.isRequired // Injected by withTheme()
};

export default withTheme(CollapseGroup);
