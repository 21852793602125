import PropTypes from "prop-types";
import React, { Component, createRef } from "react";
import {
  Grid,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  TextField,
  Icon as MuiIcon,
  CircularProgress,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import styled from "styled-components";
import _ from "lodash";
import { Formik } from "formik";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { withAlert } from "react-alert";
import Group from "../../waybee-ui/Group";
import BlockButton from "../../waybee-ui/BlockButton";
import BackButton from "../../components/BackButton";
import TableGrid from "../../waybee-ui/TableGrid";
import TeacherService from "../../services/TeacherService";
import Button from "../../waybee-ui/Button";
import Heading from "../../waybee-ui/Heading";
import MultiAvatar from "../../waybee-ui/MultiAvatar";
import Dialog from "../../waybee-ui/Dialog";
import DialogTitle from "../../waybee-ui/Dialog/DialogTitle";
import DialogContent from "../../waybee-ui/Dialog/DialogContent";
import DialogActions from "../../waybee-ui/Dialog/DialogActions";
import MultiOccurrencesForm from "./components/MultiOccurrencesForm";

const LoadContainer = styled.div`
  width: 100%;
  text-align: center;
  padding: 32px 8px;
`;

const Icon = styled(MuiIcon)`
  margin-right: 8px;
`;

const SelectedInfo = styled.div`
  display: flex;
  margin: 0 -20px;
  padding: 20px 20px;
  justify-content: space-between;
  align-items: center;
  &:hover {
    background-color: #c8c8c8;
    cursor: pointer;
  }
`;
const SelectedAmount = styled.p`
  margin-right: auto;
  color: black;
  font-size: 22px;
  margin-left: 20px;
`;
const ChangeSelect = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.color.secondary.main};
  font-size: 16px;
  text-decoration: underline;
`;

class Occurrences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classId: null,
      filter: {
        lesson: ""
      },
      loadingSave: false,
      loadingSchedules: false,
      loadingOccurrences: false,
      schedules: [],
      types: [],
      students: [],
      selectedStudents: [],
      selectAll: false,
      multipleLaunch: false,
      openChangeSelectDialog: false,
      multiOccurrence: {}
    };
  }

  componentDidMount() {
    this.getSchedules();
    this.getOccurrenceTypes();
  }

  getOccurrenceTypes = async () => {
    const occurrenceTypes = await TeacherService.getOccurrenceTypes();
    this.setState({ types: occurrenceTypes });
  };

  getOccurrences = async (idTurmaDisc, date, scheduleId) => {
    this.setState({ loadingOccurrences: true });
    const studentsOccurrences = await TeacherService.getOccurrence(
      idTurmaDisc,
      date,
      scheduleId
    );
    const studentsForms = studentsOccurrences.map(occur => {
      const newOccur = occur;
      newOccur.formRef = createRef();
      return newOccur;
    });
    this.setState({
      students: studentsForms,
      selectedStudents: [],
      loadingOccurrences: false
    });
  };

  setFilter(field, value) {
    const { filter } = this.state;
    this.setState({
      filter: {
        ...filter,
        [field]: value
      }
    });
  }

  getSchedules = async () => {
    this.setState({ loadingSchedules: true });
    const { match, alert } = this.props;
    const classId = match.params.id;

    try {
      const schedules = await TeacherService.getScheduleForOccurrence(classId);
      if (schedules.length) {
        const lastSchedule = schedules[0];
        this.getOccurrences(
          lastSchedule.idTurmaDisc,
          this.formatSelectedDate(lastSchedule),
          lastSchedule.scheduleId
        );
        if (lastSchedule) this.setFilter("lesson", lastSchedule.id);
      }
      this.setState({ schedules, classId, loadingSchedules: false });
    } catch (e) {
      console.error(e);
      alert.show("Ocorreu um erro ao buscar os horários", { title: "Erro" });
    }
  };

  updateStudent = (studentId, prop, value) => {
    this.setState(prevState => {
      const student = prevState.students.find(e => {
        return e.id === studentId;
      });
      student[prop] = value;
      return student;
    });
  };

  getReasons = () => {
    const { types } = this.state;
    const reasons = types.map(type => type.reasons);
    return _.flatten(reasons);
  };

  getInsertData = (occurrences, absences, schedule) => {
    const { students } = this.state;

    let oldOccurrences = students.map(std =>
      std.occurrences.map(occur => ({
        studentId: std.id,
        ...occur
      }))
    );
    oldOccurrences = _.flatten(oldOccurrences);

    const oldAbsences = students.map(std => ({
      studentId: std.id,
      value: std.absence ? "A" : "P"
    }));

    const removedOccurrences = _.differenceWith(
      oldOccurrences,
      occurrences,
      (a, b) =>
        a.studentId === b.studentId &&
        a.idGroup === b.idGroup &&
        a.idType === b.idType &&
        a.description === b.description
    );
    const newOccurrences = _.differenceWith(
      occurrences,
      oldOccurrences,
      (a, b) =>
        a.studentId === b.studentId &&
        a.idGroup === b.idGroup &&
        a.idType === b.idType &&
        a.description === b.description
    );

    const newAbsences = _.differenceWith(
      absences,
      oldAbsences,
      (a, b) => a.value === b.value && a.studentId === b.studentId
    );

    const date = this.formatSelectedDate(schedule);

    return {
      idTurmaDisc: schedule.idTurmaDisc,
      idPerlet: schedule.idPerLet,
      date,
      scheduleId: schedule.scheduleId,
      occurrences: newOccurrences,
      removed: removedOccurrences,
      absences: newAbsences
    };
  };

  insertOccurrence = async (data, foundSchedule) => {
    const { alert } = this.props;

    try {
      const date = this.formatSelectedDate(foundSchedule);
      await TeacherService.saveOccurrence(data);

      this.getOccurrences(
        foundSchedule.idTurmaDisc,
        date,
        foundSchedule.scheduleId
      );
    } catch (e) {
      const message = _.get(
        e,
        "response.data.error.message",
        "Erro ao salvar ocorrências"
      );
      alert.show(message, { title: "Erro" });
    } finally {
      this.setState({ loadingSave: false });
    }
  };

  save = async () => {
    const { students, filter, schedules } = this.state;

    this.setState({ loadingSave: true });

    const reasons = this.getReasons();

    const formsValues = students.map(std => std.formRef.current.values);

    const occurrences = [];
    const absences = [];
    formsValues.forEach(val => {
      val.occurrences.forEach(occur => {
        if (occur.reasons && occur.reasons.length) return;
        occurrences.push({
          studentId: val.student,
          idGroup: occur.idGroup,
          idType: occur.idType
        });
      });

      const valKeys = Object.keys(val);
      valKeys.forEach(key => {
        if (key.includes("reason-")) {
          const foundReason = reasons.find(reason => reason.id === val[key]);
          if (!foundReason) return;
          const id = key.split("reason-")[1];
          occurrences.push({
            studentId: val.student,
            idGroup: foundReason.idGroup,
            idType: foundReason.idType,
            description: val[`description-${id}`]
          });
        }
      });

      absences.push({
        studentId: val.student,
        value: val.absence ? "A" : "P"
      });
    });

    const foundSchedule = schedules.find(
      schedule => schedule.id === filter.lesson
    );

    const data = this.getInsertData(occurrences, absences, foundSchedule);
    await this.insertOccurrence(data, foundSchedule);
  };

  saveMultiOccurrence = async () => {
    const { multiOccurrence, selectedStudents, filter, schedules } = this.state;

    this.setState({ loadingSave: true });

    const reasons = this.getReasons();

    const occurrences = [];
    const absences = [];

    selectedStudents.forEach(student => {
      multiOccurrence.occurrences.forEach(occur => {
        if (occur.reasons && occur.reasons.length) return;
        occurrences.push({
          studentId: student.id,
          idGroup: occur.idGroup,
          idType: occur.idType
        });
      });

      const valKeys = Object.keys(multiOccurrence);
      valKeys.forEach(key => {
        if (key.includes("reason-")) {
          const foundReason = reasons.find(
            reason => reason.id === multiOccurrence[key]
          );
          if (!foundReason) return;
          const id = key.split("reason-")[1];
          occurrences.push({
            studentId: student.id,
            idGroup: foundReason.idGroup,
            idType: foundReason.idType,
            description: multiOccurrence[`description-${id}`]
          });
        }
      });

      absences.push({
        studentId: student.id,
        value: multiOccurrence.absence ? "A" : "P"
      });
    });

    const foundSchedule = schedules.find(
      schedule => schedule.id === filter.lesson
    );

    const data = this.getInsertData(occurrences, absences, foundSchedule);
    await this.insertOccurrence(data, foundSchedule);
    this.setState({ multipleLaunch: false });
  };

  changeSchedule = scheduleId => {
    const { schedules } = this.state;
    const selectedSchedule = schedules.find(
      schedule => schedule.id === scheduleId
    );

    const date = this.formatSelectedDate(selectedSchedule);
    this.getOccurrences(
      selectedSchedule.idTurmaDisc,
      date,
      selectedSchedule.scheduleId
    );
  };

  formatSelectedDate = schedule => {
    const [hour, minute] = schedule.startTime.split(":");
    return moment(schedule.date)
      .set({ hour, minute })
      .format("YYYY-MM-DD HH:mm:SS");
  };

  getInitialState = student => {
    const { types } = this.state;

    const reasons = {};
    const formattedOccurrences = student.occurrences.map(occur => {
      return types.find(type => {
        if (occur.id === type.id) {
          return true;
        }
        const foundReason = type.reasons.find(reason => reason.id === occur.id);
        if (foundReason) {
          reasons[`reason-${type.id}`] = foundReason.id;
          reasons[`description-${type.id}`] = occur.description;
          return true;
        }
        return false;
      });
    });

    return {
      student: student.id,
      absence: student.absence,
      occurrences: formattedOccurrences || [],
      ...reasons
    };
  };

  onChangeOccurrences = (oldVal, newVal, setFieldValue) => {
    const removed = _.difference(oldVal, newVal);
    if (removed.length && removed[0].reasons.length) {
      setFieldValue(`reason-${removed[0].id}`, null);
      setFieldValue(`description-${removed[0].id}`, null);
    }
  };

  onSelectStudent = student => {
    const { selectedStudents } = this.state;

    const foundStudents = selectedStudents.find(
      selected => selected.id === student.id
    );

    const newSelectedStudents = selectedStudents;
    if (foundStudents) {
      _.remove(selectedStudents, select => select.id === foundStudents.id);
      // newSelectedStudents = selectedStudents;
    } else {
      newSelectedStudents.push(student);
    }

    this.setState({ selectedStudents: newSelectedStudents });
  };

  onSelectAll = value => {
    const { selectedStudents, students } = this.state;
    if (selectedStudents.length !== students.length) {
      this.setState({ selectedStudents: students, selectAll: value });
    } else {
      this.setState({ selectedStudents: [], selectAll: value });
    }
  };

  handleOpenClassNotes() {
    const { classId } = this.state;
    const { history } = this.props;
    history.push(`/classes/${classId}/notes`);
  }

  render() {
    const {
      filter,
      loadingSave,
      loadingSchedules,
      loadingOccurrences,
      students,
      schedules,
      types,
      classId,
      selectedStudents,
      selectAll,
      multipleLaunch,
      openChangeSelectDialog
    } = this.state;

    return (
      <div>
        <Grid container spacing={2}>
          {multipleLaunch ? (
            <BackButton
              to={`/classes/${classId}/occurrences`}
              onClick={() => this.setState({ multipleLaunch: false })}
            />
          ) : (
            <BackButton to={`/classes/${classId}`} />
          )}
          {!multipleLaunch && (
            <Grid item xs={12}>
              <Group p={0}>
                {/* <BlockButton onClick={() => this.handleOpenClassNotes()}> */}
                {/*  Anotações da Aula */}
                {/* </BlockButton> */}
                <BlockButton onClick={() => this.save()}>Salvar</BlockButton>
              </Group>
            </Grid>
          )}
          {!multipleLaunch ? (
            <Grid item xs={12}>
              <Group>
                <Box>
                  <Box pb={5} pt={1}>
                    <form noValidate autoComplete="off">
                      {loadingSchedules ? (
                        <LoadContainer>
                          <CircularProgress />
                        </LoadContainer>
                      ) : (
                        <Grid container spacing={4}>
                          <Grid item xs={6}>
                            <FormControl fullWidth>
                              <InputLabel id="filter-classes-label">
                                Aulas
                              </InputLabel>
                              <Select
                                fullWidth
                                labelId="filter-classes-label"
                                value={filter.lesson}
                                onChange={e => {
                                  this.changeSchedule(e.target.value);
                                  this.setFilter("lesson", e.target.value);
                                }}
                              >
                                {schedules.map(lesson => (
                                  <MenuItem key={lesson.id} value={lesson.id}>
                                    {lesson.name}
                                    {' '}
                                    -
                                    {" "}
                                    {moment(lesson.date).format("DD/MM")}
                                    {" "}
                                    {lesson.startTime}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          {!loadingOccurrences && (
                            <Grid container item xs={12} spacing={4}>
                              <Grid item xs={3}>
                                <FormControlLabel
                                  control={(
                                    <Checkbox
                                      checked={selectAll}
                                      onChange={e =>
                                        this.onSelectAll(e.target.checked)}
                                      color="primary"
                                    />
                                  )}
                                  label="Selecionar todos os alunos"
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <Button
                                  color="primary"
                                  variant="contained"
                                  disabled={selectedStudents.length < 2}
                                  onClick={() =>
                                    this.setState({ multipleLaunch: true })}
                                >
                                  Lançar múltiplas ocorrências
                                </Button>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      )}
                    </form>
                  </Box>
                </Box>
                <Box>
                  <TableGrid>
                    {loadingOccurrences ? (
                      <LoadContainer>
                        <CircularProgress />
                      </LoadContainer>
                    ) : (
                      students.map(student => {
                        return (
                          <Formik
                            key={student.id}
                            innerRef={student.formRef}
                            initialValues={this.getInitialState(student)}
                          >
                            {({ values, handleChange, setFieldValue }) => (
                              <TableGrid.Row
                                key={student.id}
                                id={student.rollCallNum}
                                photo={student.photo}
                                onSelect={() => this.onSelectStudent(student)}
                                checked={selectedStudents.find(
                                  selected => selected.id === student.id
                                )}
                              >
                                <TableGrid.Group xs={6}>
                                  <TableGrid.Title xs={12}>
                                    {student.name}
                                  </TableGrid.Title>
                                  <TableGrid.Text xs={12}>
                                    {`R.A.: ${student.ra}`}
                                  </TableGrid.Text>
                                </TableGrid.Group>

                                <TableGrid.MultiSelect
                                  name="occurrences"
                                  xs={4}
                                  label="Lançar Ocorrência"
                                  value={values.occurrences}
                                  disabled={values.absence}
                                  onChange={e => {
                                    this.onChangeOccurrences(
                                      values.occurrences,
                                      e.target.value,
                                      setFieldValue
                                    );
                                    handleChange(e);
                                  }}
                                >
                                  {types.map(occur => (
                                    <MenuItem key={occur.id} value={occur}>
                                      <Icon>{occur.icon}</Icon>
                                      {occur.name}
                                    </MenuItem>
                                  ))}
                                </TableGrid.MultiSelect>

                                <TableGrid.Checkbox
                                  xs={2}
                                  color="primary"
                                  label="Faltou"
                                  checked={values.absence}
                                  name="absence"
                                  onChange={e => {
                                    setFieldValue("occurrences", []);
                                    handleChange(e);
                                  }}
                                />
                                {values.occurrences
                                  .filter(occur => occur.reasons.length)
                                  .map(occur => (
                                    <TableGrid.Cell key={occur.id} xs={12}>
                                      <Grid container spacing={4}>
                                        <Grid item xs={12}>
                                          <Box mt={3}>
                                            <Typography
                                              variant="body1"
                                              color="textSecondary"
                                            >
                                              {`Detalhes sobre ${occur.name}`}
                                            </Typography>
                                          </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                          <TextField
                                            select
                                            label="Motivo"
                                            name={`reason-${occur.id}`}
                                            value={
                                              values[`reason-${occur.id}`] || ""
                                            }
                                            onChange={handleChange}
                                            fullWidth
                                          >
                                            {occur.reasons.map(reason => (
                                              <MenuItem
                                                key={reason.id}
                                                value={reason.id}
                                              >
                                                {reason.name}
                                              </MenuItem>
                                            ))}
                                          </TextField>
                                        </Grid>
                                        <Grid item xs={6}>
                                          {values[`reason-${occur.id}`] && (
                                            <TextField
                                              placeholder="Observações (opcional)"
                                              onChange={handleChange}
                                              fullWidth
                                              name={`description-${occur.id}`}
                                              value={
                                                values[
                                                  `description-${occur.id}`
                                                ] || ""
                                              }
                                              margin="normal"
                                            />
                                          )}
                                        </Grid>
                                      </Grid>
                                    </TableGrid.Cell>
                                  ))}
                              </TableGrid.Row>
                            )}
                          </Formik>
                        );
                      })
                    )}
                  </TableGrid>
                </Box>
              </Group>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Group>
                <Box>
                  <Heading level={1} icon="change_history" gutterBottom>
                    Detalhes do lançamento de múltiplas ocorrências
                  </Heading>
                  <SelectedInfo
                    onClick={() =>
                      this.setState({ openChangeSelectDialog: true })}
                  >
                    <MultiAvatar
                      photos={selectedStudents.map(selected => ({
                        name: selected.name,
                        src: selected.photo
                      }))}
                      RoundedAvatarProps={{
                        size: 70
                      }}
                      maxItems={4}
                    />
                    <SelectedAmount>
                      {selectedStudents.length}
                      {' '}
                      alunos selecionados
                    </SelectedAmount>

                    <ChangeSelect>Editar seleção</ChangeSelect>
                  </SelectedInfo>

                  <MultiOccurrencesForm
                    types={types}
                    onChange={val => this.setState({ multiOccurrence: val })}
                  />
                </Box>
              </Group>

              <Grid container item xs={12} justify="center">
                <Box mt={1.6}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={this.saveMultiOccurrence}
                  >
                    Confirmar
                  </Button>
                </Box>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Dialog
          onClose={() => this.setState({ loadingSave: false })}
          open={loadingSave}
        >
          <DialogTitle>Salvando</DialogTitle>
          <LoadContainer>
            <CircularProgress />
          </LoadContainer>
        </Dialog>

        <Dialog
          open={openChangeSelectDialog}
          onClose={() => this.setState({ openChangeSelectDialog: false })}
          maxWidth="md"
          fullWidth
          closeButton
        >
          <DialogTitle>Gerenciar alunos selecionados</DialogTitle>

          <DialogContent>
            <Box mb={4}>
              Marque ou desmarque alunos de que estarão no lançamento de
              múltiplas ocorrências.
            </Box>

            <TableGrid>
              {students.map(student => (
                <TableGrid.Row
                  key={student.ra}
                  id={student.rollCallNum}
                  photo={student.photo}
                >
                  <TableGrid.Title xs={8}>{student.name}</TableGrid.Title>
                  <TableGrid.Text xs={3}>
                    {`R.A.: ${student.ra}`}
                  </TableGrid.Text>
                  <TableGrid.Cell xs={1}>
                    <Checkbox
                      checked={selectedStudents.find(
                        selected => selected.id === student.id
                      )}
                      onChange={() => this.onSelectStudent(student)}
                      color="primary"
                    />
                  </TableGrid.Cell>
                </TableGrid.Row>
              ))}
            </TableGrid>
          </DialogContent>

          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.setState({ openChangeSelectDialog: false })}
            >
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

Occurrences.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.any
    })
  }).isRequired,
  alert: PropTypes.shape({
    show: PropTypes.func
  }).isRequired
};

export default withAlert()(withRouter(Occurrences));
