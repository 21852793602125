import { combineReducers } from "redux";
import { userReducer } from "./userReducer";
import { themeReducer } from "./themeReducer";
import { communicationReducer } from "./communicationReducer";
import { notificationReducer } from "./notificationReducer";

export const Reducers = combineReducers({
  user: userReducer,
  communicationState: communicationReducer,
  notificationState: notificationReducer,
  theme: themeReducer
});
