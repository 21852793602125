import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Badge,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField as MuiTextField
} from "@material-ui/core";
import {
  AccountCircle,
  Comment as CommentIcon,
  SystemUpdateAlt as SystemUpdateAltIcon
} from "@material-ui/icons";
import { useForm, Controller } from "react-hook-form";
import _ from "lodash";
import styled from "styled-components";
import { DebounceInput } from "react-debounce-input";
import BlockButton from "../../waybee-ui/BlockButton/BlockButton";
import TableGrid from "../../waybee-ui/TableGrid";
import DialogContent from "../../waybee-ui/Dialog/DialogContent";

const TextField = styled(MuiTextField)`
  .MuiOutlinedInput-root {
    height: 50px;
    margin-bottom: 0;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${props => (props.error === false ? "#00b297" : "none")};
  }
`;

const GradesList = ({
  onSubmit,
  students,
  maxValue,
  average,
  defaultValues
}) => {
  const { handleSubmit, control, watch } = useForm({
    defaultValues
  });
  const [openComment, setOpenComment] = useState({});
  const [filter, setFilter] = useState({});
  const [filteredStudets, setFilteredStudets] = useState(students);

  return (
    <>
      <DialogContent>
        <Box mt={1} mb={2}>
          <form noValidate autoComplete="off">
            <Grid container spacing={8}>
              <Grid item xs={6}>
                <DebounceInput
                  minLength={3}
                  debounceTimeout={400}
                  element={TextField}
                  value={filter.name}
                  onChange={e => {
                    setFilter({ name: e.target.value });
                    setFilteredStudets(
                      _.filter(students, student =>
                        _.includes(
                          student.name.toLowerCase(),
                          e.target.value.toLowerCase()
                        )
                      )
                    );
                  }}
                  label="Buscar Aluno"
                  fullWidth
                  startAdornment={(
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  )}
                />
              </Grid>
            </Grid>
          </form>
        </Box>

        <Box>
          <Box mb={2}>
            <TableGrid>
              {filteredStudets.map(student => (
                <TableGrid.Row
                  key={student.id}
                  id={student.rollCallNum}
                  photo={student.photo}
                  icon="person"
                >
                  <TableGrid.Group xs={9}>
                    <TableGrid.Title xs={12}>{student.name}</TableGrid.Title>
                    <TableGrid.Text xs={12}>
                      {`R.A.: ${student.ra}`}
                    </TableGrid.Text>
                  </TableGrid.Group>
                  <TableGrid.Cell xs={2}>
                    <Controller
                      name={`grades.${student.ra}`}
                      control={control}
                      rules={{ max: maxValue, min: 0 }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          fullWidth
                          type="number"
                          step={0.1}
                          {...field}
                          onChange={e => {
                            if (e.target.value > maxValue) return;
                            field.onChange(e);
                          }}
                          error={
                            watch("grades")[student.ra] &&
                            watch("grades")[student.ra] < average
                          }
                        />
                      )}
                    />
                  </TableGrid.Cell>
                  <TableGrid.Cell xs={1}>
                    <IconButton
                      onClick={() =>
                        setOpenComment({
                          ...openComment,
                          [student.ra]: !openComment[student.ra]
                        })}
                    >
                      <Badge
                        color="error"
                        variant="dot"
                        invisible={!student.comment}
                      >
                        <CommentIcon />
                      </Badge>
                    </IconButton>
                  </TableGrid.Cell>

                  {openComment[student.ra] && (
                    <TableGrid.Group xs={12}>
                      <TableGrid.Text xs={12}>Comentário:</TableGrid.Text>

                      <TableGrid.Cell xs={6}>
                        <Controller
                          name={`comments.${student.ra}`}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              variant="outlined"
                              fullWidth
                              type="text"
                              disabled={_.isNil(watch("grades")[student.ra])}
                              {...field}
                              onChange={e => {
                                if (e.target.value > maxValue) return;
                                field.onChange(e);
                              }}
                            />
                          )}
                        />
                      </TableGrid.Cell>
                    </TableGrid.Group>
                  )}
                </TableGrid.Row>
              ))}
            </TableGrid>
          </Box>
        </Box>
      </DialogContent>
      <Box py={1}>
        <BlockButton
          onClick={handleSubmit(onSubmit)}
          startIcon={<SystemUpdateAltIcon />}
        >
          Lançar Notas
        </BlockButton>
      </Box>
    </>
  );
};

GradesList.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  students: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  maxValue: PropTypes.number.isRequired,
  average: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  defaultValues: PropTypes.object.isRequired
};

export default GradesList;
