import PropTypes from "prop-types";
import React, { Component } from "react";
import { withAlert } from "react-alert";
import {
  FormControl,
  Grid,
  InputLabel,
  Button,
  Select,
  CircularProgress,
  MenuItem
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-community/index.css";
import Box from "@material-ui/core/Box";
import BackButton from "../../components/BackButton";
import translate from "../../components/DataGrid/translate.json";
import HoverPopover from "../../components/HoverPopover";
import RoundedAvatar from "../../waybee-ui/RoundedAvatar";
import Concept from "./components/Concept";
import Group from "../../waybee-ui/Group";
import ConceptsDialog from "./components/ConceptsDialog";
import IndividualReportService from "../../services/IndividualReportService";
import ClassService from "../../services/ClassService";

const Container = styled.div`
  .selected-header {
    background-color: ${({ theme }) => theme.color.primary.light};
  }
  .InovuaReactDataGrid__column-header:hover {
    cursor: pointer;
  }
`;

const LoadContainer = styled.div`
  width: 100%;
  text-align: center;
  padding: 32px 8px;
`;

const Header = styled(Grid)`
  background-color: white;
  border-right: 1px solid #e4e3e2;
  border-top: 1px solid #e4e3e2;
  border-left: 1px solid #e4e3e2;
`;

const ConceptContainer = styled(Grid)`
  border-right: 1px solid #e4e3e2;
`;
const ConceptTitle = styled.p`
  font-size: 18px;
  color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  margin: 0;
  font-weight: 500;
`;
const Concepts = styled.div`
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
`;

const EmptyStudent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  p {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 500;
  }
`;

const PhotoContainer = styled.div`
  padding: 20px;
  display: inline-block;
  border-right: 1px solid #e4e3e2;
`;
const StudentInfo = styled.div`
  display: inline-block;
  margin-left: 12px;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.5);
`;
const StudentInfoHeader = styled.p`
  font-size: 18px;
  margin: 20px 0 0;
  color: ${({ theme }) => theme.color.primary.main};
`;
const StudentInfoContainer = styled.div`
  display: flex;
`;
const StudentName = styled.p`
  border-top: 1px solid #e4e3e2;
  padding: 12px;
  margin: 0;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
`;
const HighlightBlack = styled.span`
  color: black;
`;
const HighlightSecondary = styled.span`
  color: ${({ theme }) => theme.color.secondary.dark};
`;

const Footer = styled(Grid)`
  background-color: white;
  border-right: 1px solid #e4e3e2;
  border-bottom: 1px solid #e4e3e2;
  border-left: 1px solid #e4e3e2;
  display: flex;
  justify-content: end;
  padding: 8px;
`;
// const HelpButton = styled(Button)`
//   color: rgba(0, 0, 0, 0.5);
//   font-size: 16px;
//   text-transform: capitalize;
//   padding: 0 50px;
//   border-right: 1px solid #e4e3e2;
//   height: 44px;
//   p {
//     margin: 0 0 0 12px;
//   }
// `;
const SaveButton = styled(Button)`
  margin: 0 50px;
  height: 44px;
  font-size: 14px;
  text-transform: capitalize;
`;

class IndividualReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rawData: null,
      filter: {},
      courses: [],
      periods: [],
      dataSource: [],
      columns: [],
      footerColumns: [],
      concepts: [],
      selectedStudent: null,
      selectedConcept: undefined,
      openConceptDialog: true,
      untouched: true,
      hasValue: true,
      loadingFilters: false,
      loadingReport: false,
      loadingConcepts: false,
      changedValues: {}
    };
  }

  componentDidMount() {
    this.getConcepts();
  }

  getGradePeriods = async idTurmaDisc => {
    const { alert } = this.props;

    try {
      const periods = await IndividualReportService.getPeriods(idTurmaDisc);
      let currentPeriod = periods.find(period => period.current);
      if (!currentPeriod) [currentPeriod] = periods;
      this.setState({ periods });
      this.setFilter("period", currentPeriod.id);

      this.getData();
    } catch (e) {
      console.error(e);
      alert.show("Ocorreu um erro ao buscar os períodos", { title: "Erro" });
    } finally {
      this.setState({ loadingFilters: false });
    }
  };

  getCourses = async () => {
    const { match, alert } = this.props;

    this.setState({ loadingFilters: true });

    const classId = match.params.id;
    try {
      const disciplines = await ClassService.getDisciplines(classId);
      this.setState({ courses: disciplines });
      if (disciplines && disciplines.length) {
        this.setFilter("course", disciplines[0]);
        await this.getGradePeriods(disciplines[0].idTurmaDisc);
      }
    } catch (e) {
      console.error(e);
      alert.show("Ocorreu um erro ao buscar as disciplinas", { title: "Erro" });
    }
  };

  getConcepts = async () => {
    const { alert } = this.props;
    this.setState({ loadingConcepts: true });
    try {
      const concepts = await IndividualReportService.getConcepts();
      this.setState({ concepts }, this.getCourses);
    } catch (e) {
      console.error(e);
      alert.show("Ocorreu um erro ao buscar os conceitos", { title: "Erro" });
    } finally {
      this.setState({ loadingConcepts: false });
    }
  };

  getData = async () => {
    const { filter } = this.state;
    const { alert } = this.props;

    this.setState({ loadingReport: true });

    try {
      const rawData = await IndividualReportService.getReportData(
        filter.course.idTurmaDisc,
        filter.period
      );
      this.setState({ rawData });
      this.setupTableProps(rawData);
    } catch (e) {
      console.error(e);
      alert.show("Ocorreu um erro ao buscar os dados do relatório", {
        title: "Erro"
      });
    } finally {
      this.setState({ loadingReport: false });
    }
  };

  setupTableProps = tableData => {
    const { concepts } = this.state;

    const headerSelect = document.getElementById("concepts-select");
    const columns = [
      {
        name: "axis",
        header: "Eixo",
        width: headerSelect ? headerSelect.offsetWidth * 0.3 : 300,
        draggable: false,
        locked: true
      },
      {
        name: "index",
        header: "Indicadores",
        width: headerSelect ? headerSelect.offsetWidth * 0.7 : 600,
        draggable: false,
        locked: true,
        render: ({ value, data }) => {
          return (
            <HoverPopover key={data.id} id={data.id} value={value}>
              {value}
            </HoverPopover>
          );
        }
      }
    ];

    tableData.students.forEach(student => {
      columns.push({
        name: `student${student.rollCallNum}`,
        header: student.rollCallNum,
        maxWidth: 50,
        draggable: false,
        headerAlign: "center",
        headerDOMProps: {
          onClick: () => this.selectColumn(`student${student.rollCallNum}`)
        },
        onRender: (cellProps, { data }) => {
          let background = "none";

          concepts.forEach(concept => {
            if (
              data[`student${student.rollCallNum}`]?.toString() ===
              concept.id?.toString()
            )
              background = concept.color;
          });

          // eslint-disable-next-line no-param-reassign
          cellProps.style.background = background;
        },
        render: () => {
          return null;
        }
      });
    });

    const footerColumns = [
      {
        colspan: {
          axis: 2
        },
        render: {
          axis: (
            <Box textAlign="right" width="100%">
              Faltas
            </Box>
          )
        }
      }
    ];

    tableData.students.forEach(student => {
      footerColumns[0].render[`student${student.rollCallNum}`] = (
        <Box textAlign="center" width="100%">
          {student.absense}
        </Box>
      );
    });

    const dataSource = [];
    let hasValue = false;
    tableData.axis.forEach(ax => {
      ax.indexes.forEach(index => {
        const students = tableData.students.map(student => ({
          rollCallNum: student.rollCallNum,
          concept: student.concepts.find(concept => concept.id === index.id)
        }));

        const row = {
          id: index.id,
          axis: ax.name,
          index: index.name
        };

        students.forEach(student => {
          const val = student.concept?.value;
          row[`student${student.rollCallNum}`] = val;
          if (val) hasValue = true;
        });
        dataSource.push(row);
      });
    });

    this.setState({ columns, footerColumns, hasValue, dataSource });
  };

  setFilter(field, value, callback) {
    const { filter } = this.state;
    this.setState(
      {
        filter: {
          ...filter,
          [field]: value
        }
      },
      callback
    );
  }

  changeCourse = async value => {
    this.setState({ loadingFilters: true, openConceptDialog: true });
    this.setFilter("course", value, () =>
      this.getGradePeriods(value.idTurmaDisc)
    );
  };

  changePeriod = async value => {
    this.setState({ loadingReport: true, openConceptDialog: true });
    this.setFilter("period", value, this.getData);
  };

  onChangeSelect = selected => {
    const { dataSource, selectedConcept, changedValues } = this.state;

    const keys = Object.keys(selected);
    if (!keys.length) return;
    const [id, column] = keys[0].split(",");

    this.selectColumn(column);

    if (["axis", "index"].includes(column)) return;

    const newDataSource = dataSource.map(data => {
      const newData = data;

      if (data.id.toString() === id) {
        newData[column] = selectedConcept;
        changedValues[column] = {
          ...changedValues[column],
          [id]: selectedConcept
        };
      }

      return newData;
    });

    this.setState({
      dataSource: newDataSource,
      untouched: false,
      changedValues
    });
  };

  selectAll = () => {
    const { columns, dataSource, selectedConcept, changedValues } = this.state;

    const newDataSource = dataSource.map(data => {
      const newData = data;

      columns.forEach(column => {
        if (["axis", "index"].includes(column.name)) return;
        newData[column.name] = selectedConcept;
        changedValues[column.name] = {
          ...changedValues[column.name],
          [data.id]: selectedConcept
        };
      });

      return newData;
    });

    this.setState({
      dataSource: newDataSource,
      openConceptDialog: false,
      untouched: false,
      changedValues
    });
  };

  selectColumn = selectedColumn => {
    const { columns, rawData } = this.state;
    if (!rawData) return;

    const newColumns = columns.map(column => {
      const newColumn = column;

      if (selectedColumn === column.name) {
        newColumn.headerDOMProps = {
          ...column.headerDOMProps,
          className: "selected-header"
        };
      } else if (newColumn.headerDOMProps) {
        newColumn.headerDOMProps.className = "";
      }

      newColumn.selected = selectedColumn === column.name;
      return newColumn;
    });

    const selectedRollCallNum = parseInt(
      selectedColumn.replace("student", ""),
      10
    );
    const foundStudent = rawData.students.find(
      student => student.rollCallNum === selectedRollCallNum
    );
    this.setState({ columns: newColumns, selectedStudent: foundStudent });
  };

  save = async () => {
    const { changedValues, concepts, rawData, filter } = this.state;
    const { alert } = this.props;

    this.setState({ loadingReport: true });

    try {
      const insertValues = [];
      const students = Object.keys(changedValues);
      students.forEach(stdKey => {
        const id = stdKey.split("student")[1];
        const foundStudent = rawData.students.find(
          student => student.rollCallNum.toString() === id
        );
        if (!foundStudent) return;

        const valuesKeys = Object.keys(changedValues[stdKey]);
        valuesKeys.forEach(valKey => {
          const foundConcept = concepts.find(
            concept => concept.id === changedValues[stdKey][valKey]
          );
          if (!foundConcept) return;

          let foundIndex;
          rawData.axis.forEach(ax => {
            const index = ax.indexes.find(idx => idx.id === valKey);
            if (index) foundIndex = index;
          });
          if (!foundIndex) return;

          insertValues.push({
            student: foundStudent.ra,
            index: valKey,
            typeStep: foundIndex.TIPOETAPA,
            idStep: foundIndex.CODETAPA,
            idExam: foundIndex.CODPROVA,
            idConcept: foundConcept.idConcept,
            idGroup: foundConcept.idGroup
          });
        });
      });

      const data = {
        idTurmaDisc: filter.course.idTurmaDisc,
        conceptGrades: insertValues
      };

      await IndividualReportService.save(data);
      this.setState({ changedValues: {} });
      await this.getData();
    } catch (e) {
      console.error(e);
      alert.show("Ocorreu um erro ao salvar", { title: "Erro" });
      this.setState({ loadingReport: false });
    }
  };

  render() {
    const { history } = this.props;
    const {
      filter,
      courses,
      periods,
      dataSource,
      columns,
      footerColumns,
      concepts,
      selectedStudent,
      selectedConcept,
      openConceptDialog,
      untouched,
      hasValue,
      loadingFilters,
      loadingReport,
      loadingConcepts
    } = this.state;

    return (
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <BackButton onClick={history.goBack} />
          </Grid>
          <Grid item xs={12}>
            <Group fullWidth>
              {loadingFilters ? (
                <LoadContainer>
                  <CircularProgress />
                </LoadContainer>
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel>Disciplina</InputLabel>
                      <Select
                        fullWidth
                        value={filter.course}
                        onChange={e => this.changeCourse(e.target.value)}
                      >
                        {courses.map(course => (
                          <MenuItem value={course}>{course.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel>Período</InputLabel>
                      <Select
                        fullWidth
                        onChange={e => this.changePeriod(e.target.value)}
                        value={filter.period}
                      >
                        {periods.map(period => (
                          <MenuItem value={period.id}>{period.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              )}
            </Group>
          </Grid>
          <Grid item xs={12}>
            <Header container>
              <ConceptContainer item xs={8} id="concepts-select">
                {loadingConcepts ? (
                  <LoadContainer>
                    <CircularProgress />
                  </LoadContainer>
                ) : (
                  <>
                    <ConceptTitle>Selecione o conceito desejado</ConceptTitle>
                    <Concepts>
                      {concepts.map(concept => (
                        <Concept
                          label={concept.label}
                          color={concept.color}
                          selected={concept.id === selectedConcept}
                          onClick={() =>
                            this.setState({ selectedConcept: concept.id })}
                        />
                      ))}
                    </Concepts>
                  </>
                )}
              </ConceptContainer>
              <Grid item xs={4}>
                {selectedStudent ? (
                  <div>
                    <StudentInfoContainer>
                      <PhotoContainer>
                        <RoundedAvatar
                          imageSrc={selectedStudent.photo}
                          size={94}
                          imageAlt="Foto de perfil do aluno"
                        >
                          {selectedStudent.name}
                        </RoundedAvatar>
                      </PhotoContainer>
                      <StudentInfo>
                        <StudentInfoHeader>
                          Informações sobre aluno:
                        </StudentInfoHeader>
                        <p>
                          Número de chamada:
                          {" "}
                          <HighlightSecondary>
                            {selectedStudent.rollCallNum}
                          </HighlightSecondary>
                        </p>
                        <p>
                          R.A.:
                          {" "}
                          <HighlightSecondary>
                            {selectedStudent.ra}
                          </HighlightSecondary>
                        </p>
                      </StudentInfo>
                    </StudentInfoContainer>
                    <StudentName>
                      <HighlightBlack>Aluno: </HighlightBlack>
                      {selectedStudent.name}
                    </StudentName>
                  </div>
                ) : (
                  <EmptyStudent>
                    <p>Selecione o aluno</p>
                  </EmptyStudent>
                )}
              </Grid>
            </Header>

            {loadingReport || loadingFilters ? (
              <LoadContainer>
                <CircularProgress />
              </LoadContainer>
            ) : (
              <ReactDataGrid
                idProperty="id"
                columns={columns}
                dataSource={dataSource}
                footerRows={footerColumns}
                defaultCellSelection={[]}
                onCellSelectionChange={this.onChangeSelect}
                multiSelect={false}
                showColumnMenuTool={false}
                resizable={false}
                sortable={false}
                virtualizeColumns={false}
                rowHeight={50}
                headerHeight={50}
                footerRowStyle={{ height: 40 }}
                i18n={translate.i18n}
                style={{ minHeight: 500 }}
              />
            )}

            <Footer item xs={12}>
              {/* <HelpButton> */}
              {/*  <HelpIcon /> */}
              {/*  <p>Tirar dúvidas</p> */}
              {/* </HelpButton> */}
              {!untouched && (
                <SaveButton
                  variant="contained"
                  color="primary"
                  onClick={this.save}
                >
                  Salvar alterações
                </SaveButton>
              )}
            </Footer>
          </Grid>
        </Grid>

        {!hasValue && !loadingFilters && !loadingReport && (
          <ConceptsDialog
            open={openConceptDialog}
            concepts={concepts}
            onClose={() => this.setState({ openConceptDialog: false })}
            onSelect={this.selectAll}
            onChange={val => this.setState({ selectedConcept: val })}
            selected={selectedConcept}
          />
        )}
      </Container>
    );
  }
}

IndividualReport.propTypes = {
  alert: PropTypes.shape({
    show: PropTypes.func
  }).isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.any
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.any
    })
  }).isRequired
};

export default withAlert()(withRouter(IndividualReport));
