import styled from "styled-components";

import { IconButton as MuiIconButton } from "@material-ui/core";

const IconButton = styled(MuiIconButton)`
  color: inherit;
  padding: 20px;
  height: 90px;
  width: 76px;
  background-color: #00303b !important;
  color: #fff;
  border-radius: 0;
  & .MuiSvgIcon-root {
    font-size: 1.9em;
  }
`;

export default IconButton;
