import React from "react";
import { Radar } from "react-chartjs-2";
import { Box } from "@material-ui/core";

const data = {
  labels: [
    "CIÊNCIAS",
    "DES. GEOMÉTRICO",
    "MATEMÁTICA",
    "ARTES",
    "GEOGRAFIA",
    "HISTÓRIA",
    "LÍNG. ESPANHOLA",
    "LING. INGLESA",
    "LING. PORTUGUESA"
  ],
  datasets: [
    {
      label: "1º Bimestre",
      backgroundColor: "rgba(59, 227,146, 0)",
      borderColor: "rgba(59, 227,146, 1)",
      pointBackgroundColor: "rgba(59, 227,146, 1)",
      pointHoverBorderColor: "rgba(59, 227,146, 0.5)",
      hoverBorderWidth: 10,
      data: [6, 7, 9, 5.6, 9, 8, 10, 7, 10],
      pointStyle: "circle",
      pointRadius: 4.5,
      order: 3
    },
    {
      label: "2º Bimestre",
      backgroundColor: "rgba(242, 146,146, 0)",
      borderColor: "rgba(240, 139, 7, 1)",
      pointBackgroundColor: "rgba(240, 139, 7, 1)",
      pointHoverBorderColor: "rgba(240, 139, 7, 0.5)",
      hoverBorderWidth: 10,
      data: [10, 7, 9, 10, 5, 7, 6, 6, 7],
      pointStyle: "rectRot",
      pointRadius: 5,
      order: 2
    },
    {
      label: "3º Bimestre",
      backgroundColor: "rgba(59, 227,146, 0)",
      borderColor: "rgba(199, 120,232, 1)",
      pointBackgroundColor: "rgba(199, 120,232, 1)",
      pointHoverBorderColor: "rgba(199, 120,232, 0.5)",
      hoverBorderWidth: 10,
      data: [10, 9, 10, 6.6, 6, 5, 7, 7, 10],
      pointStyle: "rect",
      pointRadius: 6,
      lineDash: [1, 2],
      order: 1
    }
  ]
};

const options = {
  scale: {
    ticks: {
      beginAtZero: true,
      min: 0,
      max: 10,
      stepSize: 5
    },
    pointLabels: {
      fontSize: 12,
      fontColor: "#666",
      fontFamily: "'Arial', 'Helvetica', 'Helvetica Neue', sans-serif"
    }
  },
  legend: {
    display: true,
    position: "bottom",
    labels: {
      usePointStyle: true,
      fontColor: "#444",
      fontStyle: "bold",
      fontSize: 13,
      boxWidth: 6,
      fontFamily: "'Arial', 'Helvetica', 'Helvetica Neue', sans-serif",
      padding: 42
    }
  }
};

const AnnualStatisticsChart = () => {
  return (
    <Box mt={7} mb={10}>
      <Radar data={data} options={options} height={40} width={100} />
    </Box>
  );
};

export default AnnualStatisticsChart;
