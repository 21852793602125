import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
// Material-ui
import { Hidden as MuiHidden, Container } from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";

import Heading from "../Heading";
import AppHeader from "./AppHeader";
import Sidebar from "../Sidebar";

const sidebarSize = 280;

const Content = styled.main`
  flex-grow: 1;
  padding: 20px;
  @media (min-width: 960px) {
    margin-left: ${sidebarSize}px;
  }
`;
const Hidden = styled(MuiHidden)`
  width: 76px;
`;

const Title = styled.div`
  padding-left: 20px;
`;

class Toolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false
    };
  }

  handleSidebarToggle = () => {
    const { mobileOpen } = this.state;
    this.setState({ mobileOpen: !mobileOpen });
  };

  render() {
    const { mobileOpen } = this.state;
    const {
      activeTitle,
      listMenu,
      children: content,
      userPhoto,
      userName
    } = this.props;

    return (
      <div>
        <Sidebar
          size={sidebarSize}
          mobileOpen={mobileOpen}
          onClose={this.handleSidebarToggle}
          userPhoto={userPhoto}
          userName={userName}
        >
          {listMenu}
        </Sidebar>
        <AppHeader sidebarSize={sidebarSize}>
          <Hidden mdUp implementation="css">
            <AppHeader.IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={this.handleSidebarToggle}
            >
              <MenuIcon />
            </AppHeader.IconButton>
          </Hidden>
          <Title>
            <Heading level={1}>{activeTitle}</Heading>
          </Title>
        </AppHeader>
        <Content>
          <Container maxWidth="xl" style={{ padding: 0 }}>
            {content}
          </Container>
        </Content>
      </div>
    );
  }
}

Toolbar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  listMenu: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  userPhoto: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  activeTitle: PropTypes.string.isRequired
};

export default Toolbar;
