import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import RoundedAvatar from "../../RoundedAvatar";

const DefaultPhotoContainer = styled.div`
  padding: 15px 0;
  height: 100%;
  border-radius: 50%;
`;

const SmallPhotoContainer = styled.div`
  padding: 23px 15px;
  height: 100%;
  border-radius: 50%;
`;

const Photo = props => {
  const { src, size, icon } = props;

  let sizeInPixels = 90;
  let Container = DefaultPhotoContainer;
  if (size === "small") {
    sizeInPixels = 45;
    Container = SmallPhotoContainer;
  }

  return (
    <Container {...props}>
      <RoundedAvatar imageSrc={src} icon={icon} size={sizeInPixels} />
    </Container>
  );
};

Photo.propTypes = {
  src: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["small", "normal"]),
  icon: PropTypes.string
};

Photo.defaultProps = {
  size: "normal",
  icon: "photoCamera"
};

export default Photo;
