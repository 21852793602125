import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  Grid,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
  Typography,
  Box,
  Icon
} from "@material-ui/core";
import WuiRoundedAvatar from "../RoundedAvatar";

const RoundedAvatar = styled(WuiRoundedAvatar)`
  margin-right: 30px;
`;
const TableRow = styled(MuiTableRow)`
  cursor: pointer;
`;
const TableCell = styled(MuiTableCell)`
  padding: 20px 7px;
  border-bottom: solid #f2f2f2 2px;
  max-width: 280px;
  min-width: 110px;
  color: #727272;

  &.title {
    padding-left: 30px;
    h5 {
      margin: 0;
      font-size: 22px;
      font-weight: 300;
      color: #000;
      line-height: 1.15em;
      margin-bottom: 4px;
    }
    span {
      font-size: 16px;
    }
  }
  &.class {
    * {
      color: #727272;
    }
    font-size: 18px;
  }
  &.status {
    svg {
      color: ${props => props.color};
      font-size: 28px;
    }
    p {
      font-size: 16px;
      font-weight: 500;
      padding-right: 30px;
      color: ${props => props.color};
    }
  }
  .table-list-info-icon {
    margin-right: 14px;
  }
  .table-list-info {
    margin: 0;
    line-height: unset;
  }
`;

const TableListItem = ({
  item,
  image,
  infoIcon,
  statusIcon,
  statusColor,
  statusText,
  onSelectitem
}) => {
  return (
    <TableRow hover onClick={() => onSelectitem(item)}>
      <TableCell className="title" align="left">
        <Box display="flex" alignItems="center">
          {image && <RoundedAvatar imageSrc={image} icon="person" size={90} />}

          <Box display="inline-block">
            <h5>{item.title}</h5>
            <span>{item.description}</span>
          </Box>
        </Box>
      </TableCell>
      <TableCell className="class" align="center">
        <Box display="flex" alignitems="center">
          {infoIcon && <Icon className="table-list-info-icon">{infoIcon}</Icon>}
          <Typography
            display="inline"
            variant="body1"
            className="table-list-info"
          >
            {item.info}
          </Typography>
        </Box>
      </TableCell>
      <TableCell className="status" align="right" color={statusColor}>
        <Grid container direction="row-reverse" alignItems="center" spacing={1}>
          <Grid item>
            <Typography variant="body1">{statusText}</Typography>
          </Grid>
          <Grid item>
            <Icon color="primary">{statusIcon}</Icon>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

TableListItem.defaultProps = {
  image: "",
  infoIcon: ""
};

TableListItem.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    info: PropTypes.string.isRequired
  }).isRequired,
  image: PropTypes.string,
  infoIcon: PropTypes.string,
  statusColor: PropTypes.string.isRequired,
  statusText: PropTypes.string.isRequired,
  statusIcon: PropTypes.node.isRequired,
  onSelectitem: PropTypes.func.isRequired
};

export default TableListItem;
