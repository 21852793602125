import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { Grid, Checkbox } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MuiTouchRipple from "@material-ui/core/ButtonBase";
import Photo from "./Photo";

const FixedBlock = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  max-height: 130px;
  top: 0;
  left: 0;
  padding: 0 15px;
`;

const IdContainer = styled.div`
  font-size: 21px;
  font-weight: 500;
  color: #00303b;
  min-width: 50px;
  margin-right: 10px;
  text-align: center;
`;

const RowStyle = styled(Grid).withConfig({
  shouldForwardProp: prop => !["button", "margin", "highlight"].includes(prop)
})`
  min-height: ${props => {
    const none = "68px";
    const dense = "76px";
    const normal = "88px";
    if (props.margin) {
      return props.margin === "dense" ? dense : normal;
    }
    return none;
  }};
  align-items: center;
  border-bottom: solid #f2f2f2 2px;
  position: relative;
  height: fit-content;
  padding-left: 15px;
  cursor: ${props => (props.button ? "pointer" : "default")};

  ${({ highlight }) => {
    if (highlight)
      return css`
        background-color: ${({ theme }) => {
          return theme.color.primary.light;
        }};
      `;
    return "";
  }}

  &:hover {
    background-color: ${props => (props.button ? "rgba(0, 0, 0, 0.04)" : "")};
  }
`;

RowStyle.propTypes = {
  button: PropTypes.bool,
  margin: PropTypes.oneOfType([
    PropTypes.oneOf(["none", "dense", "normal"]),
    PropTypes.bool
  ])
};

const RightIconContainer = styled.div`
  display: flex;
  width: ${props => props.width}px;
  justify-content: flex-end;
  align-items: center;
  color: #b2b2b2;
  padding: 0 15px;

  svg {
    font-size: 30px;
  }
`;

const GridContent = styled(Grid).withConfig({
  shouldForwardProp: prop =>
    ![
      "width",
      "photos",
      "id",
      "actionIcon",
      "button",
      "margin",
      "highlight"
    ].includes(prop)
})`
  height: fit-content;
  width: ${props => props.width};
  & > :first-child {
    padding-left: ${props => props.leftSpacing || ""}px;
    min-height: ${props => props.minheight || ""}px;
    > * {
      padding-left: ${props => (props.leftSpacing ? 0 : "")}px;
    }
  }
`;

const TouchRipple = styled(MuiTouchRipple)`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`;

const Row = props => {
  const {
    photo,
    size,
    actionIcon,
    id,
    button,
    icon,
    onClick,
    margin,
    highlight,
    onSelect,
    checked
  } = props;
  const actionIconWidth = `${actionIcon ? 50 : 15}`;
  const contentWidth = `calc(100% - ${actionIconWidth}px)`;

  let photoSpacing = 0;
  let minHeight = "auto";
  if (photo) {
    photoSpacing = size === "small" ? 75 : 110;
    minHeight = size === "small" ? 90 : 120;
  }
  const idSpacing = id ? 60 : 0;
  const checkSpacing = onSelect ? 42 : 0;
  const leftSpacing = photoSpacing + idSpacing + checkSpacing;

  return (
    <RowStyle
      container
      item
      xs={12}
      button={button}
      margin={margin}
      onClick={onClick}
      highlight={highlight}
    >
      {button && <TouchRipple />}

      <FixedBlock>
        {onSelect && (
          <Checkbox checked={checked} color="primary" onChange={onSelect} />
        )}
        {id && <IdContainer>{id}</IdContainer>}
        {photo && <Photo src={photo} size={size} icon={icon} />}
      </FixedBlock>

      <GridContent
        container
        width={contentWidth}
        minheight={minHeight}
        leftSpacing={leftSpacing}
        {...props}
      />
      {actionIcon && (
        <RightIconContainer width={actionIconWidth}>
          {actionIcon === true ? <ChevronRightIcon /> : actionIcon}
        </RightIconContainer>
      )}
    </RowStyle>
  );
};

Row.propTypes = {
  actionIcon: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.element // Material UI Icon
  ]),
  id: PropTypes.number,
  photo: PropTypes.string,
  size: PropTypes.oneOf(["small", "normal"]),
  icon: PropTypes.string,
  button: PropTypes.bool,
  onClick: PropTypes.func,
  margin: PropTypes.oneOfType([
    PropTypes.oneOf(["none", "dense", "normal"]),
    PropTypes.bool
  ]),
  highlight: PropTypes.bool,
  onSelect: PropTypes.func,
  checked: PropTypes.bool
};

Row.defaultProps = {
  actionIcon: null,
  id: null,
  photo: "",
  size: "normal",
  button: false,
  onClick: () => {},
  margin: "none",
  icon: null,
  highlight: false,
  onSelect: null,
  checked: false
};

export default React.memo(Row);
