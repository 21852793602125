import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Box,
  CircularProgress,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from "@material-ui/core";
import { DebounceInput } from "react-debounce-input";
import { withRouter } from "react-router-dom";
import TeacherService from "../../services/TeacherService";
import Group from "../../waybee-ui/Group";
import Heading from "../../waybee-ui/Heading";
import TeacherList from "./components/TeacherList";

class Teachers extends Component {
  constructor() {
    super();

    this.state = {
      teachers: [],
      filteredTeachers: [],
      courses: [],
      loading: false,
      hasAllTeachers: false,
      filter: {
        name: "",
        course: ""
      }
    };
  }

  componentDidMount() {
    this.setData();
    this.setScroll();
  }

  setScroll = () => {
    window.onscroll = () => {
      const container = document.querySelector("html");
      if (container.scrollTop + window.innerHeight >= container.offsetHeight) {
        this.getMoreTeachers();
      }
    };
  };

  setData = async () => {
    const { teachers } = this.state;
    if (!teachers.length) {
      const courses = await TeacherService.getCourses();
      const data = await this.getTeachers();
      this.setState(() => ({ teachers: data, courses: courses.data }));
    }
  };

  getTeachers = async (countToSkip = 0) => {
    const { filter } = this.state;

    this.setState({ loading: true });
    const res = await TeacherService.getTeachers(countToSkip, filter);
    this.setState({ loading: false });
    return res.data;
  };

  getMoreTeachers = async () => {
    const { teachers, filteredTeachers, hasAllTeachers, loading } = this.state;
    if (hasAllTeachers) return;
    if (loading) {
      setTimeout(() => {
        this.getMoreTeachers();
      }, 500);
      return;
    }

    const data = await this.getTeachers(
      this.hasAnyFilter() ? filteredTeachers.length : teachers.length
    );

    const typeData = this.hasAnyFilter() ? "filteredTeachers" : "teachers";
    this.setState(prevState => {
      return {
        hasAllTeachers: data.length === 0,
        [typeData]: prevState[typeData].concat(data)
      };
    });
  };

  setFilter = async (field, value) => {
    const { filter } = this.state;
    filter[field] = value;

    this.setState(
      { filter, filteredTeachers: [], hasAllTeachers: false },

      async () => {
        if (this.hasAnyFilter()) {
          const data = await this.getTeachers();
          this.setState(() => ({ filteredTeachers: data }));
        }
      }
    );
  };

  hasAnyFilter() {
    const { filter } = this.state;
    const { name, course } = filter;
    return name.trim() || course;
  }

  render() {
    const { loading, filteredTeachers, teachers, filter, courses } = this.state;
    const { history } = this.props;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Group>
            <Box>
              <Heading level={1} icon="search" gutterBottom>
                Buscar Professores
              </Heading>

              <Box pb={4} pt={2}>
                <form noValidate autoComplete="off">
                  <Grid container spacing={8}>
                    <Grid item md={6}>
                      <DebounceInput
                        minLength={3}
                        debounceTimeout={400}
                        element={TextField}
                        value={filter.name}
                        onChange={e => this.setFilter("name", e.target.value)}
                        label="Buscar por Nome"
                        fullWidth
                      />
                    </Grid>

                    <Grid item md={6}>
                      <FormControl fullWidth>
                        <InputLabel id="filter-course-label">
                          Disciplina
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="filter-course-label"
                          value={filter.course}
                          onChange={e =>
                            this.setFilter("course", e.target.value)}
                        >
                          {courses.map(course => (
                            <MenuItem key={course.id} value={course.id}>
                              {course.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </form>
              </Box>

              <TeacherList
                onSelectItem={teacher => {
                  history.push(`/teachers/${teacher.id}`);
                }}
                teachers={this.hasAnyFilter() ? filteredTeachers : teachers}
              />

              {loading && (
                <Box p={6} textAlign="center">
                  <CircularProgress />
                </Box>
              )}
            </Box>
          </Group>
        </Grid>
      </Grid>
    );
  }
}

Teachers.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired // Auto injected by react router
};

export default withRouter(Teachers);
