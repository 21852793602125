import MuiButton from "@material-ui/core/Button";
import styled from "styled-components";

const Button = styled(MuiButton)`
  background-color: white;
  height: 80px;
  width: 100%;
  border-radius: 0;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.primary.main};
  box-shadow: #dadada 1px 1px 1px;

  svg {
    margin-right: 10px;
    width: 1.6em;
    height: 1.6em;
  }
`;

Button.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  ...MuiButton.propTypes
};

export default Button;
