import React from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  Grid,
  Box
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import Dialog from "../../../waybee-ui/Dialog";
import DialogTitle from "../../../waybee-ui/Dialog/DialogTitle";
import DialogContent from "../../../waybee-ui/Dialog/DialogContent";
import DialogActions from "../../../waybee-ui/Dialog/DialogActions";
import Button from "../../../waybee-ui/Button";

const TestDialog = ({ open, onClose, onSave, testTypes }) => {
  const { register, watch, getValues } = useForm();
  const onSubmit = () => onSave(getValues());

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth closeButton>
      <DialogTitle>Nova Avaliação</DialogTitle>

      <DialogContent>
        Para adicionar uma nova avaliação, preencha os campos abaixo e clique em
        Adicionar.
        <Box mt={3}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel>Tema da Avaliação</InputLabel>
                <Select
                  {...register("type")}
                  // value={age}
                  // onChange={handleChange}
                  label="Tema da Avaliação"
                >
                  {testTypes.map(type => (
                    <MenuItem value={type}>{type.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Valor"
                {...register("value")}
                type="number"
                fullWidth
              />
            </Grid>
            {watch("type")?.editable && (
              <Grid item xs={12}>
                <TextField
                  label="Descrição"
                  multiline
                  maxRows={2}
                  fullWidth
                  {...register("description")}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button variant="contained" color="secondary" onClick={onClose}>
          Voltar
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onSubmit}
          type="submit"
        >
          Adicionar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

TestDialog.defaultProps = {
  open: false,
  testTypes: []
};

TestDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  testTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      editable: PropTypes.bool
    })
  )
};

export default TestDialog;
