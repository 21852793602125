import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import Cell from "./Cell";

const TitleCell = styled(Cell)`
  align-items: center;
  h5 {
    margin: 0;
    font-size: 22px;
    font-weight: 300;
    color: #000;
    line-height: 1.15em;
  }
`;

const Title = props => {
  const { children } = props;
  return (
    <TitleCell {...props}>
      <h5>{children}</h5>
    </TitleCell>
  );
};

Title.propTypes = {
  children: PropTypes.node.isRequired
};

export default Title;
