import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { Button as MuiButton } from "@material-ui/core";

const Button = styled(MuiButton)`
  background-color: #fff;
  color: #00303b;
  font-size: 25px;
  font-weight: 500;
  text-transform: none;
  height: 90px;
  border-radius: 6px;
  padding: 5px 23px;
  justify-content: flex-start;
  border: none;
  margin: 3px 0;
  svg {
    color: ${({ theme }) => theme.color.primary.dark};
    margin-right: 10px;
    font-size: 32px !important;
  }
  :hover {
    border: none;
  }
  * {
    text-decoration: none;
  }
`;

const MenuButton = props => {
  const { children, icon } = props;
  return (
    <Button variant="outlined" color="secondary" startIcon={icon} fullWidth>
      {children}
    </Button>
  );
};

MenuButton.propTypes = {
  children: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired
};

export default MenuButton;
