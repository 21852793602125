import React, { Component } from "react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import FullCalendar from "@fullcalendar/react";
import ptBrLocale from "@fullcalendar/core/locales/pt-br";
import styled, {
  ThemeProvider as StyledThemeProvider
} from "styled-components";
import ReactDOM from "react-dom";
import moment from "moment";
import PropTypes from "prop-types";
import { StylesProvider, jssPreset } from "@material-ui/core/styles";
import { create } from "jss";
import MultipleMonthCalendar from "./MultipleMonthCalendar";
import { theme as StyledTheme } from "../../assets/theme/StyledTheme";

import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";

const CalendarStyle = styled.span`
  .fc-toolbar,
  .fc-view-container,
  .month-calendar-container {
    background-color: white;
  }
  #month-calendar-container {
    height: 0;
    overflow: hidden;
    width: 100%;
  }
  .fc-view-container {
    margin-top: ${({ today }) => (today ? 0 : 22)}px;
    &.today-container {
      margin-top: 0;
    }
  }
  .fc-toolbar {
    margin: 0;
    padding: 25px 40px;
    height: 96px;
    border-bottom: 1px solid #f2f2f2;

    h2 {
      display: flex;
      align-items: center;
      font-family: "Roboto", sans-serif;
      font-size: 26px;
      font-weight: 300;
      color: #000000;
    }
    .fc-left {
      display: flex;
      button {
        color: black;
        background-color: transparent;
        border: none;
        box-shadow: none;
        &.fc-prevButton-button,
        &.fc-nextButton-button {
          display: flex;
          align-items: center;
        }
      }
    }
  }
  .fc-time-grid {
    .fc-slats td {
      height: 60px;
    }
    .fc-time-grid-event {
      margin-bottom: 1px;
    }
  }
  .fc-event {
    border-radius: 0;
    border: none;
    left: -2% !important;
    right: 0 !important;

    &.min-10,
    &.min-20,
    &.min-30 {
      .fc-content {
        .fc-title {
          h1,
          h2 {
            font-size: 11px;
            margin: 0;
          }
        }
      }
    }

    &.min-10,
    &.min-20 {
      .fc-content {
        .fc-title {
          display: flex;
          align-items: center;
          overflow: hidden;
          white-space: nowrap;
          h1 {
            display: inline-block;
          }
          h2 {
            display: inline-block;
            margin-left: 6px;
          }
        }
      }
    }

    &.min-10 {
      .fc-content {
        padding: 0 12px;
        .fc-time {
          display: none;
        }
        .fc-title {
          height: 100%;
        }
      }
    }

    &.min-20,
    &.min-30 {
      .fc-content {
        padding: 0 14px;
        .fc-time span {
          font-size: 11px;
        }
      }
    }

    &.min-45 {
      .fc-content {
        padding: 6px 12px;
        h1 {
          font-size: 14px;
          margin-top: 5px;
        }
        h2 {
          margin-top: 5px;
        }
      }
    }
  }

  .fc-time {
    font-family: "Roboto", sans-serif;
    min-height: 20px;
    font-size: 16px;
    font-weight: 300;
    span {
      white-space: normal;
    }
    @media screen and (max-width: 1024px) {
      font-size: 13px;
    }
  }

  .fc-title h1 {
    opacity: 0.5;
    min-height: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    @media screen and (max-width: 1024px) {
      font-size: 13px;
    }
    margin: 5px 0;
  }
  .fc-title h2 {
    opacity: 0.5;
    min-height: 16px;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and (max-width: 1024px) {
      font-size: 13px;
    }
    font-weight: 300;
  }
  .fc-content {
    height: 100%;
    padding: 12px;
    @media screen and (max-width: 1024px) {
      padding: 10px;
    }
  }
  .fc-bg {
    opacity: 0.2;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .fc-border {
    opacity: 0.6;
    border-left: 2px solid;
  }
  .fc-axis.fc-time {
    opacity: 0.5;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: #000000;
    text-align: center;
  }
  .fc-time-grid .fc-slats .fc-minor td {
    border-top-style: dashed;
  }
  .fc-day-header {
    text-align: center;
    border-width: 0;
    padding: 18px 0;
    span {
      font-family: "Roboto", sans-serif;
      font-size: 21px;
      font-weight: 300;
      color: #00303b;
    }
    &.today-header {
      text-align: left;
      padding: 20px;
      span {
        display: block;
      }
      .today-span {
        font-size: 24px;
        font-weight: 500;
        color: #000000;
      }
      .date-span {
        margin-top: 14px;
        font-size: 19px;
      }
    }
  }
  .fc-head-container {
    border-top-width: 0;
  }
  th.fc-axis {
    border-width: 0;
  }
  .fc-monthView-button {
    border: 0;
    width: 45px;
    height: 45px;
    background-color: ${({ theme }) => theme.color.primary.dark};
    border-radius: 50%;
    .calendar-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }
`;

const MonthCalendar = props => {
  const jss = create({
    ...jssPreset(),
    insertionPoint: document.getElementById("jss-calendar-mui-style")
  });
  return (
    <StylesProvider jss={jss}>
      <StyledThemeProvider theme={StyledTheme}>
        <MultipleMonthCalendar {...props} />
      </StyledThemeProvider>
    </StylesProvider>
  );
};

class Calendar extends Component {
  static setIconButton() {
    const calendarIcon = document.createElement("span");
    calendarIcon.textContent = "date_range";
    calendarIcon.className = "material-icons";
    const calendarButton = document.querySelector(
      ".fc-monthView-button .material-icons"
    );
    if (calendarButton) {
      calendarButton.className = "calendar-icon";
      calendarButton.appendChild(calendarIcon);
    }

    const leftButtons = Array.from(
      document.querySelectorAll(
        ".fc-prev-button span, .fc-prevButton-button span"
      )
    );
    leftButtons.forEach(btn => {
      btn.textContent = "chevron_left"; // eslint-disable-line no-param-reassign
      btn.className = "material-icons"; // eslint-disable-line no-param-reassign
    });

    const rightButtons = Array.from(
      document.querySelectorAll(
        ".fc-next-button span, .fc-nextButton-button span"
      )
    );
    rightButtons.forEach(btn => {
      btn.textContent = "chevron_right"; // eslint-disable-line no-param-reassign
      btn.className = "material-icons"; // eslint-disable-line no-param-reassign
    });
  }

  selectedMonth = moment();

  calendarRef = React.createRef();

  view = "week";

  componentDidMount() {
    const { options, today } = this.props;

    const calendarApi = this.calendarRef.current.getApi();
    calendarApi.render();

    this.scrollHour();
    if (today) {
      // Format the column header for today
      this.formatTodayHeader();
    } else if (options.header !== false) {
      // Will render the header
      Calendar.setIconButton();

      const monthDiv = document.createElement("div");
      monthDiv.className = "month-calendar-container";
      monthDiv.id = "month-calendar-container";

      const containerEl = document.querySelector(".fc-view-container");
      containerEl.parentNode.insertBefore(monthDiv, containerEl);
      this.renderMonthView(monthDiv);
    }
  }

  componentDidUpdate() {
    this.scrollHour();
    Calendar.setIconButton();

    const monthDiv = document.querySelector(".month-calendar-container");
    if (monthDiv) {
      const containerEl = document.querySelector(".fc-view-container");
      containerEl.parentNode.insertBefore(monthDiv, containerEl);
      this.renderMonthView(monthDiv);
    }
  }

  onSelectDay = selectedDay => {
    const calendarApi = this.calendarRef.current.getApi();
    calendarApi.gotoDate(selectedDay.toDate());
    this.changeMonthView();
    Calendar.setIconButton();
  };

  getCustomButtonsOpt() {
    return {
      prevButton: {
        text: "",
        icon: "chevron-left",
        click: () => {
          if (this.view === "month") {
            this.selectedMonth = moment(this.selectedMonth).subtract(1, "M");
            this.reRenderMonthView();
          } else {
            this.selectedMonth = moment(this.selectedMonth).subtract(7, "d");
            const calendarApi = this.calendarRef.current.getApi();
            calendarApi.gotoDate(this.selectedMonth.toDate());
            calendarApi.render();
            Calendar.setIconButton();
          }
        }
      },
      nextButton: {
        text: "",
        icon: "chevron-right",
        click: () => {
          if (this.view === "month") {
            this.selectedMonth = moment(this.selectedMonth).add(1, "M");
            this.reRenderMonthView();
          } else {
            this.selectedMonth = moment(this.selectedMonth).add(7, "d");
            const calendarApi = this.calendarRef.current.getApi();
            calendarApi.gotoDate(this.selectedMonth.toDate());
            calendarApi.render();
            Calendar.setIconButton();
          }
        }
      },
      monthView: {
        text: "date_range",
        icon: " material-icons",
        click: () => this.changeMonthView()
      }
    };
  }

  formatTodayHeader = () => {
    const viewContainer = document.querySelector(".fc-view-container");
    viewContainer.classList.add("today-container");
    const spaceHeader = document.querySelector(".fc-axis.fc-widget-header");
    spaceHeader.remove();
    const todayHeader = document.querySelector(".fc-day-header.fc-today");
    todayHeader.classList.add("today-header");
    const dateSpan = todayHeader.querySelector("span");
    dateSpan.textContent =
      dateSpan.textContent.charAt(0).toUpperCase() +
      dateSpan.textContent.slice(1);
    dateSpan.classList.add("date-span");
    const todaySpan = document.createElement("span");
    todaySpan.classList.add("today-span");
    todaySpan.textContent = "Hoje";
    todayHeader.prepend(todaySpan);
  };

  scrollHour = () => {
    const time = moment();
    const roundedTime = time.startOf("hour");
    const scrollContainer = document.querySelector(
      ".fc-widget-content .fc-scroller"
    );
    const hourToScroll = document.querySelector(
      `tr[data-time="${roundedTime.format("HH:mm:ss")}"]`
    );
    scrollContainer.scrollTop = hourToScroll.offsetTop;
  };

  eventRender = infos => {
    // Tratando cor do card
    const eventCard = infos.el;
    const cardColor = eventCard.style.backgroundColor;
    eventCard.style.backgroundColor = "white";
    const coloredDiv = document.createElement("div");
    coloredDiv.style.backgroundColor = cardColor;
    coloredDiv.classList.add("fc-bg");

    const coloredBorder = document.createElement("div");
    coloredBorder.style.backgroundColor = "white";
    coloredBorder.style.borderColor = cardColor;
    coloredBorder.classList.add("fc-bg");
    coloredBorder.classList.add("fc-border");

    eventCard.prepend(coloredDiv);
    eventCard.prepend(coloredBorder);

    // Adicionando classes de tempo
    const end = moment(infos.event.end);
    const start = moment(infos.event.start);
    const duration = moment.duration(end.diff(start));
    const minutes = duration.asMinutes();

    let minuteClass;
    if (minutes <= 45) {
      minuteClass = "min-45";
    }
    if (minutes <= 30) {
      minuteClass = "min-30";
    }
    if (minutes <= 20) {
      minuteClass = "min-20";
    }
    if (minutes <= 10) {
      minuteClass = "min-10";
    }
    if (minuteClass) {
      eventCard.classList.add(minuteClass);
    }

    // Tratando conteudo do card
    const titleElement = infos.el.querySelector(".fc-title");
    while (titleElement.firstChild) {
      titleElement.removeChild(titleElement.firstChild);
    }

    // Create and append title node
    const titleNode = document.createElement("h1");
    const { title } = infos.event;
    const titleTextNode = document.createTextNode(title);
    titleNode.appendChild(titleTextNode);
    titleElement.appendChild(titleNode);

    // Create and append description node
    const descNode = document.createElement("h2");
    const { description } = infos.event.extendedProps;
    const descTextNode = document.createTextNode(description);
    descNode.appendChild(descTextNode);
    titleElement.appendChild(descNode);
  };

  changeMonthView() {
    const monthView = document.querySelector("#month-calendar-container");
    monthView.style.height = monthView.style.height === "auto" ? "0" : "auto";
    this.view = this.view === "week" ? "month" : "week";
  }

  reRenderMonthView() {
    const monthDiv = document.querySelector(".month-calendar-container");
    if (monthDiv) {
      const containerEl = document.querySelector(".fc-view-container");
      containerEl.parentNode.insertBefore(monthDiv, containerEl);
      this.renderMonthView(monthDiv);
    }
  }

  renderMonthView(container) {
    ReactDOM.render(
      <MonthCalendar
        selectedMonth={this.selectedMonth}
        onSelectDate={this.onSelectDay}
      />,
      container
    );
  }

  render() {
    const { options, today } = this.props;

    return (
      <CalendarStyle today={today}>
        <FullCalendar
          ref={this.calendarRef}
          timeZone="UTC"
          plugins={[timeGridPlugin, dayGridPlugin]}
          locale={ptBrLocale}
          eventRender={this.eventRender}
          customButtons={this.getCustomButtonsOpt()}
          scrollTime={moment()
            .startOf("hour")
            .format("HH:mm:ss")}
          header={
            today
              ? false
              : {
                  left: "title prevButton,nextButton",
                  right: "monthView"
                }
          }
          {...options}
        />
      </CalendarStyle>
    );
  }
}

Calendar.defaultProps = {
  today: false
};

Calendar.propTypes = {
  options: PropTypes.shape({
    ...FullCalendar.propTypes,
    header: PropTypes.oneOfType([PropTypes.bool, PropTypes.object])
  }).isRequired,
  today: PropTypes.bool
};

export default Calendar;
