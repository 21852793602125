import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Typography } from "@material-ui/core";

const Container = styled.div`
  background-color: white;
  max-height: 500px;
  overflow: auto;
`;

const Title = styled(Typography)`
  padding: 20px;
`;

const Widget = props => {
  const { title, children } = props;
  return (
    <Container {...props}>
      <Title variant="subtitle2">{title}</Title>
      {children}
    </Container>
  );
};

Widget.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

Widget.defaultProps = {};

export default Widget;
