// mocks
import studentsMock from "../mocks/studentsMock.json";
import StudentGradesMock from "../mocks/studentGradesMock.json";
import { api } from "../utils/api";

const getStudentById = async id => {
  const res = await api.get(`/ClassDiaryV1/student/${id}/profile`);
  return res.data;
};

const getStudents = async () => {
  return studentsMock;
};

const getGrades = async () => {
  return StudentGradesMock;
};

const getSchedule = async (start, end, studentId) => {
  const res = await api.get(`/ClassDiaryV1/student/${studentId}/schedule`, {
    params: { start, end }
  });
  return res.data;
};

const getOccurrences = async studentId => {
  const res = await api.get(`/ClassDiaryV1/student/${studentId}/occurrences`);
  return res.data;
};

export default {
  getStudentById,
  getStudents,
  getGrades,
  getSchedule,
  getOccurrences
};
