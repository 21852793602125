import React from "react";
import styled from "styled-components";
import MuiButton from "@material-ui/core/Button";

const DefaultButton = styled(MuiButton).withConfig({
  shouldForwardProp: prop => !["color"].includes(prop)
})`
  margin: ${props => (!props.variant ? "0 -8px" : "")};
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.11px;
  text-transform: none;
  height: 44px;
  line-height: 1em;
  box-shadow: none;
  border-radius: 4px;
  ${({ theme, color, variant }) => {
    const ThemeColor = theme.color[color].main;
    const ThemeDarkColor = theme.color[color].dark;
    const ThemeContrastColor = theme.color.default.contrastText;
    if (color !== "primary" || color !== "secondary") {
      if (variant === "contained") {
        return `
          background-color: ${ThemeColor};
          color: ${ThemeContrastColor};
          &:hover {
            background-color: ${ThemeDarkColor};
          }
        `;
      }
      return `
        background-color: "transparent";
        color: ${ThemeColor};
        border-color: ${ThemeColor};
      `;
    }
    return "";
  }}

  svg {
    width: 1em;
    height: 1em;
  }
`;

const LargeButton = styled(DefaultButton)`
  font-size: 20px;
  height: 48px;
  line-height: 35px;
  svg {
    width: 35px;
    height: 35px;
  }
`;

const SmallButton = styled(DefaultButton)`
  font-size: 16px;
  letter-spacing: normal;
  text-transform: none;
  svg {
    width: 1.35em;
    height: 1.35em;
    margin-right: 10px;
  }
`;

const Button = props => {
  const { size } = props;
  let ButtonComponent = DefaultButton;
  if (size === "small") {
    ButtonComponent = SmallButton;
  }
  if (size === "large") {
    ButtonComponent = LargeButton;
  }
  return <ButtonComponent {...props} />;
};

Button.propTypes = {
  ...MuiButton.propTypes
};

export default Button;
