import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormControl, InputLabel, Select } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import DialogTitle from "../../../waybee-ui/Dialog/DialogTitle";
import Dialog from "../../../waybee-ui/Dialog";
import DialogActions from "../../../waybee-ui/Dialog/DialogActions";
import Button from "../../../waybee-ui/Button";
import DialogContent from "../../../waybee-ui/Dialog/DialogContent";
import Concept from "./Concept";

const HelpText = styled.p`
  margin: 10px 0 0;
  color: rgba(0, 0, 0, 0.6);
`;

const ConceptsDialog = ({
  open,
  concepts,
  onClose,
  onSelect,
  onChange,
  selected
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth closeButton>
      <DialogTitle>Editar Relatório Individual</DialogTitle>

      <DialogContent>
        Selecione o conceito desejado para iniciar o preenchimento do relatório.
        <Box mt={3}>
          <FormControl fullWidth>
            <InputLabel>Selecione o conceito</InputLabel>
            <Select
              fullWidth
              value={selected}
              onChange={({ target: { value } }) => onChange(value)}
              renderValue={value => {
                const selectedConcept = concepts.find(
                  concept => concept.id === value
                );
                return (
                  <Concept
                    label={selectedConcept.label}
                    color={selectedConcept.color}
                  />
                );
              }}
            >
              {concepts.map(concept => (
                <MenuItem key={concept.id} value={concept.id}>
                  <Concept
                    label={concept.label}
                    color={concept.color}
                    selected={selected === concept.id}
                    fullWidth
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <HelpText>
          Atenção: O conceito selecionado será aplicado em todos os campos do
          relatório.
        </HelpText>
      </DialogContent>

      <DialogActions>
        <Button variant="contained" color="primary" onClick={onSelect}>
          Ok
        </Button>
        <Button variant="contained" color="error" onClick={onClose}>
          Dispensar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConceptsDialog.defaultProps = {
  open: false,
  concepts: [],
  selected: null
};

ConceptsDialog.propTypes = {
  open: PropTypes.bool,
  concepts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      color: PropTypes.string
    })
  ),
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.number
};

ConceptsDialog.defaultProps = {};

export default ConceptsDialog;
