import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { withAlert } from "react-alert";
import BackButton from "../../components/BackButton";
import CollapseGroup from "../../waybee-ui/CollapseGroup";
import SubGroup from "../../waybee-ui/SubGroup";
import ClassService from "../../services/ClassService";
import Group from "../../waybee-ui/Group";
import GradesDialog from "./GradesDialog";

const LoadContainer = styled.div`
  width: 100%;
  text-align: center;
  padding: 32px 8px;
`;

class ReleaseNotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classId: null,
      filter: {
        name: "",
        course: ""
      },
      loadingCourses: false,
      courses: [],
      loadingExams: false,
      exams: [],
      selectedExam: null
    };
  }

  componentDidMount() {
    this.getCourses();
  }

  setFilter(field, value) {
    const { filter } = this.state;
    this.setState({
      filter: {
        ...filter,
        [field]: value
      }
    });
  }

  getCourses = async () => {
    const { match, alert } = this.props;
    this.setState({ loadingCourses: true });

    const classId = match.params.id;
    try {
      const disciplines = await ClassService.getDisciplines(classId);
      this.setState({ classId, courses: disciplines });
      if (disciplines && disciplines.length) {
        this.setFilter("course", disciplines[0]);
        this.getExams();
      }
    } catch (e) {
      console.error(e);
      alert.show("Ocorreu um erro ao buscar as disciplinas", { title: "Erro" });
    } finally {
      this.setState({ loadingCourses: false });
    }
  };

  getExams = async () => {
    const { alert } = this.props;
    const { filter } = this.state;
    this.setState({ loadingExams: true });

    if (filter && filter.course) {
      try {
        const exams = await ClassService.getExams(filter.course.idTurmaDisc);
        this.setState({ exams });
      } catch (e) {
        console.error(e);
        alert.show("Ocorreu um erro ao buscar as provas", { title: "Erro" });
      } finally {
        this.setState({ loadingExams: false });
      }
    } else {
      this.setState({ loadingExams: false });
    }
  };

  changeCourse = e => {
    this.setFilter("course", e.target.value);
    this.getExams();
  };

  onClickExam = exam => {
    const { filter } = this.state;

    this.setState({
      selectedExam: {
        ...exam,
        idTurmaDisc: filter.course.idTurmaDisc
      }
    });
  };

  onCloseGradesModal = () => {
    this.setState({ selectedExam: false });
  };

  render() {
    const {
      filter,
      loadingCourses,
      courses,
      classId,
      loadingExams,
      exams,
      selectedExam
    } = this.state;

    return (
      <Grid container spacing={2}>
        <BackButton to={`/classes/${classId}`} />

        <Grid item xs={12}>
          <Group fullWidth>
            {loadingCourses ? (
              <LoadContainer>
                <CircularProgress />
              </LoadContainer>
            ) : (
              <Grid container item xs={4}>
                <FormControl fullWidth>
                  <InputLabel id="filter-course-label">Disciplina</InputLabel>
                  <Select
                    fullWidth
                    labelId="filter-course-label"
                    value={filter.course}
                    onChange={this.changeCourse}
                  >
                    {courses.map(course => (
                      <MenuItem value={course}>{course.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Group>
        </Grid>

        {loadingExams ? (
          <LoadContainer>
            <CircularProgress />
          </LoadContainer>
        ) : (
          filter.course &&
          exams.map(step => (
            <Grid item xs={12} key={step.id}>
              <CollapseGroup title={step.name}>
                {step.exams.map(exam => (
                  <SubGroup
                    key={exam.id}
                    title={exam.name}
                    icon="chevron_right"
                    onClick={() => this.onClickExam(exam)}
                  />
                ))}
              </CollapseGroup>
            </Grid>
          ))
        )}

        <GradesDialog
          selectedExam={selectedExam}
          onClose={this.onCloseGradesModal}
        />
      </Grid>
    );
  }
}

ReleaseNotes.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.any
    })
  }).isRequired,
  alert: PropTypes.shape({
    show: PropTypes.func
  }).isRequired
};

export default withAlert()(withRouter(ReleaseNotes));
