import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 216px;
`;

const Background = styled.div`
  position: absolute;
  left: -20px;
  right: -20px;
  height: 216px;
  background-color: #fff;
  background-image: url(${({ photo }) => photo});
  background-size: cover;
`;

const HeaderProfile = ({ photo }) => {
  return (
    <Container>
      <Background photo={photo} />
    </Container>
  );
};

HeaderProfile.propTypes = {
  photo: PropTypes.string.isRequired
};

export default HeaderProfile;
